@charset "UTF-8";
@import '_config.scss';
/**********************************************
jQuery PulginカスタムCSS
***********************************************/


/*============================================================
  slider-pro
============================================================*/
@media screen and (max-width: $breakpoint-sp) {
	.sp-horizontal .sp-previous-arrow {
		left: 5px;
	}
	.sp-horizontal .sp-next-arrow {
		right: 5px;
	}
}

/*============================================================
  colorbox
============================================================*/
#cboxOverlay {
    background: #000;
}
#cboxLoadedContent {
    background: #fff;
}
#cboxLoadedContent {
  padding: 0;
  overflow: auto;
    -moz-box-shadow: 0px 1px 10px #000000;
    -webkit-box-shadow: 0px 1px 10px #000000;
    box-shadow: 0px 1px 10px #000000;
}
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose , #cboxTitle {
  top: -30px;
}
#cboxClose {
  -webkit-border-radius: 10%;
          border-radius: 10%;
}
#colorbox, #cboxOverlay, #cboxWrapper {
  overflow: visible ;
}
#cboxTitle {
  color: #fff;
}
#inline-content {/* インラインを使用する時のみ */
    margin: 20px;
}
#ajax-wrap {/* ajaxを使用する時のみ */
  margin: 20px;
}

/* 動画ボックス（インライン時） */
.inlineMovieBox {
	&_inner {
		padding: 10px 0;
		background-color: #ddd;
	}
	.inlineIframe {
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.inlineMovieBox {
		&_inner {
			padding: 10px 0 0;
		}
		// iframe {
		// 	width: 100%;
		// 	height: 100%;
		// }
	}
}