@charset "UTF-8";
@import '_config.scss';
/*
便利クラス

マージン強制とかの便利クラス

Styleguide 1.
*/


/* 汎用

-------------------------------------------------------------*/
/* margin */
@for $i from 0 through 50 {
	.mlr#{$i} {
		margin-left: #{$i}px !important;
		margin-right: #{$i}px !important;
	}
}
@for $i from 0 through 100 {
	.mt#{$i} {
		margin-top: #{$i}px !important;
	}
}
@for $i from 0 through 100 {
	.mr#{$i} {
		margin-right: #{$i}px !important;
	}
}
@for $i from 0 through 100 {
	.mb#{$i} {
		margin-bottom: #{$i}px !important;
	}
}
@for $i from 0 through 100 {
	.ml#{$i} {
		margin-left: #{$i}px !important;
	}
}

/* padding */
@for $i from 0 through 100 {
	.pt#{$i} {
		padding-top: #{$i}px !important;
	}
}
@for $i from 0 through 100 {
	.pr#{$i} {
		padding-right: #{$i}px !important;
	}
}
@for $i from 0 through 100 {
	.pb#{$i} {
		padding-bottom: #{$i}px !important;
	}
}
@for $i from 0 through 100 {
	.pl#{$i} {
		padding-left: #{$i}px !important;
	}
}

/* width */
@for $i from 1 through 100 {
	.w#{$i}p {
		width: #{$i}% !important;
	}
	@media screen and (min-width: $breakpoint-sp +1 ){
		.pcW#{$i}p {
			width: #{$i}% !important;
		}
	}
	@media screen and (max-width: $breakpoint-sp){
		.spW#{$i}p {
			width: #{$i}% !important;
		}
	}
}
.wAuto {
	width: auto !important;
}

// img
// ---------------------------------------------
.img_pict {
	img {
		max-width: 100%;
		height: auto;
	}
}
@media screen and (max-width: $breakpoint-sp) {
}

.indent05e { text-indent: -.5em !important; padding-left: .5em !important;}
.indent10e { text-indent: -1em !important; padding-left: 1em !important;}
.indent15e { text-indent: -1.5em !important; padding-left: 1.5em !important;}
.indent20e { text-indent: -2em !important; padding-left: 2em !important;}
.indent25e { text-indent: -2.5em !important; padding-left: 2.5em !important;}
.indent30e { text-indent: -3em !important; padding-left: 3em !important;}
.indent35e { text-indent: -3.5em !important; padding-left: 3.5em !important;}
.indent40e { text-indent: -4em !important; padding-left: 4em !important;}
.indent45e { text-indent: -4.5em !important; padding-left: 4.5em !important;}
.indent50e { text-indent: -5em !important; padding-left: 5em !important;}
.indent55e { text-indent: -5.5em !important; padding-left: 5.5em !important;}

.fLeft { float: left !important; }
.fRight { float: right !important; }
.tLeft  { text-align: left !important; }
.tCenter { text-align: center !important; }
.tRight { text-align: right !important; }
.vat { vertical-align: top !important; }
.vam { vertical-align: middle !important; }
.vab { vertical-align: bottom !important; }
.clearfix:before,
.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	visibility: hidden;
}
.clearfix:after {clear: both;}
.clearfix {*zoom: 1;}
.noAround {
	overflow: hidden;
	*zoom: 1;
}
.clear, .clb { clear: both; }

.txtHide { @include textHidden; }
.blockHide { display: none; }

/*
レスポンシブ用　表示/非表示

.(sp/tab/pc)-onlyで.(sp/tab/pc)でのみ表示

.(sp/tab/pc)-hiddenで.(sp/tab/pc)でのみ非表示

つまり.sp-onlyでtab/pcは非表示に、.pc-onlyでtab/spは非表示に、

Markup:

Styleguide 1.1
============================================================*/
@media screen and (max-width: $breakpoint-sp) {
	.sp-hidden,
	.pc-only,
	.tab-only {
		display: none !important;
	}
}

@media screen and (min-width: $breakpoint-sp + 1) {
	.pc-hidden,
	.tab-only,
	.sp-only {
		display: none !important;
	}
}
