@charset "UTF-8";
@import '_config.scss';
/*
パーツ

見出しとかボタンとかアイコン付きの文字とか

Styleguide 3.
*/

/*
見出し1

headLine1

Markup:
<h2 class="headLine1">
	<span class="headLine1_sub">ページヘッダサブタイトル</span>
	<span class="headLine1_title">ページタイトル</span>
</h2>

Styleguide 3.1.1
============================================================*/
.headLine1 {
	display: block;
	margin: 0 0 27px;
	padding: 8px 0 6px 19px;
	font-weight: normal;
	border-left: 4px solid $mainColor03;
	&_sub ,
	&_title {
		line-height: 1.2;
	}
	&_sub {
		display: block;
		color: $mainColor03;
		font-size: 13px;
		margin-bottom: 6px;
	}
	&_title {
		font-size: 26px;
		color: #323232;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.headLine1 {
		padding: 6px 0 4px 9px;
		&_sub {
			font-size: 12px;
			margin-bottom: 4px;
		}
		&_title {
			font-size: 20px;
		}
	}
}

/*
見出し2

headLine2

Markup:
<h2 class="headLine2">ページタイトル</h2>

Styleguide 3.1.2
============================================================*/
.headLine2 {
	display: block;
	margin: 0 0 27px;
	padding: 7px 15px 5px;
	line-height: 1.2;
	color: #fff;
	font-size: 16px;
	font-weight: normal;
	background-color: $mainColor04;
}
@media screen and (max-width: $breakpoint-sp) {
	.headLine2 {
		padding: 7px 10px 5px;
	}
}

/*
見出し3

headLine3

Markup:
<h2 class="headLine3 {$modifiers}">ページタイトル</h2>

.headLine3-bld - bld

Styleguide 3.1.3
============================================================*/
.headLine3 {
	display: block;
	margin: 0 0 22px;
	padding: 3px 3px 6px;
	font-size: 18px;
	line-height: 1.4;
	color: #333;
	font-weight: normal;
	border-bottom: 1px dotted $mainColor09;
	&-bld {
		font-weight: bold;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.headLine3 {
		font-size: 16px;
	}
}

/*
見出し4

headLine4

Markup:
<h2 class="headLine4"><span>ページタイトル</span></h2>

Styleguide 3.1.4
============================================================*/
.headLine4 {
	display: block;
	margin: 0 0 22px;
	color: #333;
	padding-bottom: 3px;
	font-size: 16px;
	font-weight: bold;
	border-bottom: 1px dotted $mainColor09;
	span {
		position: relative;
		display: inline-block;
		padding: 0 10px 0 20px;
		&:before {
			display: inline-block;
			position: absolute;
			top: 0px;
			left: 2px;
			content: "\f138";
			font-family: FontAwesome;
			color: $mainColor04;
			font-size: inherit;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.headLine4 {
		font-size: 14px;
	}
}

/*
ボタン > ボタングループ

btnGroup

Markup:
<div class="btnGroup {$modifiers}">
	<ul class="btnGroup_list">
		<li class="btnGroup_item"><a href="#" class="btn btn-arrow1 btn-color2 btn-mid">戻る</a></li>
		<li class="btnGroup_item"><a href="#" class="btn btn-arrow1 btn-color1 btn-mid">申し込む</a></li>
	</ul><!-- /.btnGroup_list -->
</div><!-- /.btnGroup -->

.btnGroup-bottom - 下部配置

Styleguide 3.2
============================================================*/
.btnGroup {
$this: &;
	text-align: center;
	@include inline_block-p;
	> * {
		letter-spacing: normal;
	}
	&_item {
		@include inline_block;
		margin: 0 20px;
	}
	&-bottom {
		margin: 57px 0 0;
	}
	&-right {
		text-align: right;
		#{$this}_item {
			@include inline_block;
			margin: 0 0 0 20px;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.btnGroup {
		&_item {
			margin: 20px 0 0;
			padding: 0 20px;
			display: block;
			// width: 50%;
			&:first-child {
				margin-top: 0;
			}
			// &:nth-of-type(odd) {
			// 	background-color: #ddd;
			// }
			// &:nth-of-type(even) {
			// 	background-color: #ccd;
			// }
		}
		&-bottom {
			margin: 30px 0 0;
		}
	}
}

/*
ボタン

btn

Markup:
<input type="button" value="ボタン" class="btn {$modifiers}" />
<button class="btn {$modifiers}">ボタン</button>
<a href="#" class="btn {$modifiers}">ボタン</a>

.btn-min - min
.btn-mid - mid
.btn-long - long
.btn-max - max
.btn-bRRadius1 - bRRadius1
.btn-back - back
.btn-color1 - color1
.btn-color2 - color2
.btn-color3 - color3
.btn-color4 - color4
.btn-color5 - color5
.btn-arrow1 - 手前右向き二重arrow
.btn-arrow2 - 手前左向き二重arrow
.btn-arrow3 - 後ろ右向き二重arrow
.btn-arrow4 - 後ろ左向き二重arrow
.btn-success - success
.btn-cancel - cancel

Styleguide 3.3
============================================================*/
.btn {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	padding: 16px 30px;
	color: #fff;
	text-align: center;
	line-height: 1;
	font-size: 15px;
	font-family: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro','Hiragino Kaku Gothic Pro',Verdana,Arial,sans-serif,'ＭＳ Ｐゴシック';
	text-decoration: none;
	background-color: #333;
	cursor: pointer;
	&:before ,
	&:after {
		position: relative;
		top: 0px;
		color: inherit;
		font-size: inherit;
	}
	&-min {
		width: 70%;
	}
	&-mid {
		width: 394px;
	}
	&-long {
		width: 490px;
	}
	&-max {
		width: 100%;
	}
	&-bRRadius1 {
		-webkit-border-radius: 4px;
		        border-radius: 4px;
	}
	&-back {
		width: 160px;
	}
	&-color1 {
		background-color: $mainColor04;
	}
	&-color2 {
		background-color: $mainColor01;
	}
	&-color3 {
		color: $mainColor01;
		background-color: $mainColor05;
	}
	&-color4 {
		background-color: $mainColor09;
	}
	&-color5 {
		color: $mainColor01;
		background-color: $mainColor05;
		box-shadow: inset 0 -1px 0 darken($mainColor05, 15%);
	}
	&-arrow1 {
		&:before {
			position: relative;
			left: -5px;
			margin-left: 5px;
			content:"\f101";
			font-family: FontAwesome;
		}
	}
	&-arrow2 {
		&:before {
			position: relative;
			left: -5px;
			margin-left: 5px;
			content:"\f100";
			font-family: FontAwesome;
		}
	}
	&-arrow3 {
		&:after {
			position: relative;
			right: -5px;
			margin-right: 5px;
			content:"\f101";
			font-family: FontAwesome;
		}
	}
	&-arrow4 {
		&:after {
			position: relative;
			right: -5px;
			margin-right: 5px;
			content:"\f100";
			font-family: FontAwesome;
		}
	}

	// 専用ボタン

	// OK , Cancel btn
	&-success ,
	&-cancel {
		min-width: 120px;
	}
	&-success {
		color: #fff;
		background-color: lighten($red, 15%);
	}
	&-cancel {
		color: #fff;
		background-color: lighten(#333, 25%);
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.btn {
		padding: 16px 15px;
		font-size: 12px;
		&-type1 {
			&:after {
				right: 0px;
			}
		}
		&-type2 {
			&:before {
				// left: -5px;
			}
		}
		&-min {
		}
		&-mid {
			width: auto;
		}
		&-long {
		}
		&-max {
		}
	}
}

/*
タグ

tag

Markup:
<span class="tag {$modifiers}">タグ</span>

.tag-type1 - 深緑
.tag-type2 - 水色

Styleguide 3.4
============================================================*/
.tag {
	display: inline-block;
	padding: 5px 10px;
	line-height: 1;
	text-align: center;
	color: #fff;
	font-size: 12px;
	background-color: #333;
	&:first-child {
		margin-left: 0;
	}
	&-type1 {
		background-color: #26A9A0;
	}
	&-type2 {
		background-color: $mainColor04;
	}
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
注釈文

note

Markup:
<p class="note {$modifiers}">※注釈文注釈文注釈文注釈文注釈文</p>

.note-type1 - 赤
.note-type2 - 青
.note-ftS - フォントサイズ小

Styleguide 3.5
============================================================*/
.note {
	&-type1 {
		color: #f00;
	}
	&-type2 {
		color: #00f;
	}
	&-ftS {
		font-size: 10px;
	}
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
アイコン付きリンク

linkArrow, linkArrow2

Markup:
<a href="#" class="linkArrow" target="_blank">テキストリンクテキストリンク</a><br>
<a href="#" class="linkArrow2" target="_blank">テキストリンクテキストリンク</a><br>

Styleguide 3.6
============================================================*/
.link {
	&Arrow {
		position: relative;
		display: inline-block;
		padding: 0 0 0 1.2em;
		text-decoration: none;
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			content:"\f101";
			font-family: FontAwesome;
			color: inherit;
			font-size: inherit;
		}
	}
	&Arrow2 {
		position: relative;
		display: inline-block;
		padding: 0 0 0 1.2em;
		color: inherit;
		text-decoration: none;
		&:before {
			position: absolute;
			top: -1px;
			left: 0;
			display: inline-block;
			content:"\f138";
			font-family: FontAwesome;
			color: $mainColor04;
			font-size: inherit;
		}
	}

	&-left {
		margin-left: 10px;
	}

	&Arrow ,
	&Arrow2 {
		&-blank {
			&:after {
				content: "";
				display: inline-block;
				position: relative;
				margin-left: 5px;
				width: 14px;
				height: 12px;
				background: url('./../images/bullets/icon_blank.png') center no-repeat;
				z-index: 10;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
アイコン

icon

Markup:
<i class="icon {$modifiers}"></i>

.icon-language_en - English
.icon-language_ch - Chinese
.icon-pdf - PDF(サイズ：24)
.icon-pdf02 - PDF(サイズ：32)
.icon-pdf03 - PDF(サイズ：48)
.icon-mail - メール
.icon-search - search
.icon-movie - movie

Styleguide 3.7.1
============================================================*/
.icon {
	position: relative;
	&:before {
		content: "";
		position: relative;
		top: -1px;
		display: inline-block;
		margin-right: 5px;
		vertical-align: middle;
	}
	&-language_en {
		&:before {
			width: 29px;
			height: 19px;
			background: url('./../images/bullets/icon-language_en.png') no-repeat;
			-webkit-background-size: cover;
			background-size: cover;
		}
	}
	&-language_ch {
		&:before {
			width: 29px;
			height: 19px;
			background: url('./../images/bullets/icon-language_ch.png') no-repeat;
			-webkit-background-size: cover;
			background-size: cover;
		}
	}
	&-pdf {
		&:before {
			width: 24px;
			height: 24px;
			background: url('./../images/bullets/icon-pdf/icon-pdf_24.png') no-repeat;
			-webkit-background-size: cover;
			background-size: cover;
		}
	}
	&-pdf02 {
		&:before {
			width: 32px;
			height: 32px;
			background: url('./../images/bullets/icon-pdf/icon-pdf_32.png') no-repeat;
			-webkit-background-size: cover;
			background-size: cover;
		}
	}
	&-pdf03 {
		&:before {
			width: 48px;
			height: 48px;
			background: url('./../images/bullets/icon-pdf/icon-pdf_48.png') no-repeat;
			-webkit-background-size: cover;
			background-size: cover;
		}
	}
	&-mail {
		&:before {
			width: 22px;
			height: 16px;
			background: url('./../images/bullets/icon_mail.png') no-repeat;
			-webkit-background-size: cover;
			background-size: cover;
		}
	}
	&-search {
		&:before {
			width: 16px;
			height: 20px;
			margin-right: 8px;
			background: url('./../images/bullets/icon_search.png') no-repeat;
			-webkit-background-size: cover;
			background-size: cover;
		}
	}
	&-movie {
		&:before {
			width: 28px;
			height: 22px;
			margin-right: 8px;
			background: url('./../images/bullets/icon_movie.png') no-repeat;
			-webkit-background-size: cover;
			background-size: cover;
		}
	}
	// &-download {
	//	padding: 2px 7px;
	//	color: #fff;
	//	-webkit-border-radius: 5px;
	//	        border-radius: 5px;
	//	background-color: $mainColor03;
	//	&:before {
	//		content:"\f019";
	//		font-family: FontAwesome;
	//		margin-right: 5px;
	//		color: inherit;
	//		font-size: 14px;
	//	}
	//	&:after {
	//		content:"Download";
	//	}
	// }
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
アイコン

icon2

Markup:
<i class="icon2 {$modifiers}"></i>

.icon2-pdf02 - PDF(サイズ：32)
.icon2-pdf02 - PDF(サイズ：32)
.icon2-pdf03 - PDF(サイズ：48)

Styleguide 3.7.2
============================================================*/
.icon2 {
	position: relative;
	&:after {
		content: "";
		position: relative;
		top: -1px;
		display: inline-block;
		margin-left: 5px;
		vertical-align: middle;
	}
	&-pdf {
		&:after {
			width: 24px;
			height: 24px;
			background: url('./../images/bullets/icon-pdf/icon-pdf_24.png') no-repeat;
			-webkit-background-size: cover;
			background-size: cover;
		}
	}
	&-pdf02 {
		&:after {
			width: 32px;
			height: 32px;
			background: url('./../images/bullets/icon-pdf/icon-pdf_32.png') no-repeat;
			-webkit-background-size: cover;
			background-size: cover;
		}
	}
	&-pdf03 {
		&:after {
			width: 48px;
			height: 48px;
			background: url('./../images/bullets/icon-pdf/icon-pdf_48.png') no-repeat;
			-webkit-background-size: cover;
			background-size: cover;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
}


/*
リンクエリア拡大class

linkField

Markup:
{$modifiers}

Styleguide 3.8
============================================================*/
.linkField {
	cursor: pointer;
	-webkit-transition: all 0.3s ease;
	   -moz-transition: all 0.3s ease;
	    -ms-transition: all 0.3s ease;
	     -o-transition: all 0.3s ease;
	        transition: all 0.3s ease;
	&:hover {
		opacity: .8;
	}
}
@media screen and (max-width: $breakpoint-sp) {
}