@charset "UTF-8";
@import '_config.scss';
/*
モジュール

その他モジュール

Styleguide 4.
*/

/*
パンクズ

pankuzu

Markup:
<nav class="pankuzu">
	<ul class="pankuzu_list">
		<li class="pankuzu_item"><a href="/">HOME</a></li>
		<li class="pankuzu_item">パンクズ</li>
	</ul>
</nav><!-- /.pankuzu -->

Styleguide 4.1
============================================================*/
.pankuzu {
	margin: 15px 85px 0;
	&_list {
		width: auto;
		margin: 0 auto;
		overflow: hidden;
		list-style-type: none;
		> :first-child:before {
			content: none;
		}
	}
	&_item {
		float: left;
		color: inherit;
		color: #989898;
		font-size: 13px;
		&:before {
			content: '>';
			display: inline-block;
			margin: 0 1.5em;
		}
		a {
			color: inherit;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	// +.section {
	// 	margin-top: 32px;
	// }
}
@media screen and (max-width: $breakpoint-sp) {
	.pankuzu {
		margin: 10px 0;
		padding: 0 10px;
		&_list {
			width: auto;
		}
		&_item {
			&:before {
				margin: 0 .8em;
			}
		}
		+.section {
			margin-top: 22px;
		}
	}
}

/*
ページヘッダ

pageHeader

Markup:
<div class="pageHeader">
	<div class="pageHeader_inner">
		<h1 class="pageHeader_title">ページヘッダ</h1>
		<span class="pageHeader_sub">ページヘッダサブタイトル</span>
		<p class="pageHeader_lead">リード文リード文リード文リード文リード文リード文リード文リード文リード文リード文リード文</p>
	</div>
</div>

Styleguide 4.2
============================================================*/
.pageHeader {
// $this: &;
	position: relative;
	margin: 0 auto;
	width: 1330px;
	min-height: 146px;
	background: url('./../images/global/bg_pageheader.jpg') no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
	&_inner {
		display: block;
		position: absolute;
		padding: 0 130px;
		top: 50%;
		width: 100%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	&_title ,
	&_sub {
		display: inline-block;
		vertical-align: middle;
	}
	&_title {
		color: #000;
		font-size: 40px;
		font-weight: bold;
		font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
	}
	&_sub {
		margin-left: 24px;
		color: $mainColor02;
		font-size: 13px;
		font-weight: normal;
	}
	&_lead {
		margin-top: 5px;
		color: #666;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.pageHeader {
	// $this: &;
		margin: 0 auto;
		padding: 25px 10px;
		height: auto;
		min-height: 56px;
		line-height: 1;
		width: auto;
		&_inner {
			display: block;
			position: relative;
			padding: 0;
			top: auto;
			width: auto;
			-webkit-transform: translateY(0%);
			-ms-transform: translateY(0%);
			transform: translateY(0%);
		}
		&_title ,
		&_sub {
			display: block;
		}
		&_title {
			font-size: 22px;
		}
		&_sub {
			margin: 10px 0 0;
			font-size: 12px;
			font-weight: bold;
		}
		&_lead {
			margin-top: 10px;
			font-size: 12px;
			line-height: 1.4;
		}
	}
}

/*
画像とテキスト - （SP時float解除）

imgTxt

Markup:
<div class="imgTxt">
	<div class="imgTxt_left">
		<div class="imgTxt_img"><img src="http://placehold.it/200x150/888888/ffffff?text=!!!Dummy+Image!!!" width="100" height="80" alt="ダミーイメージ"></div>
	</div>
	<div class="imgTxt_body">
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
	</div>
</div><!-- /.imgTxt -->
<div class="imgTxt">
	<div class="imgTxt_right">
		<div class="imgTxt_img"><img src="http://placehold.it/150x200/888888/ffffff?text=!!!Dummy+Image!!!" width="100" height="80" alt="ダミーイメージ"></div>
	</div>
	<div class="imgTxt_body {$modifiers}">
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
	</div>
</div><!-- /.imgTxt -->

.imgTxt_body-around - 画像の下まで回り込み

Styleguide 4.3.1
============================================================*/
.imgTxt {
	margin: 20px 0 0;
	overflow: hidden;
	*:first-child {
		margin-top: 0;
	}
	&_left {
		float: left;
		margin: 0 20px 0 0;
	}
	&_right {
		float: right;
		margin: 0 0 0 20px;
	}
	&_body {
		overflow: hidden;
		&-around {
			overflow: visible;
		}
	}
	&_img {
		text-align: center;
	}
	&_caption {
		margin-top: 5px !important;
		text-align: center;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.imgTxt {
		margin: 20px 0 0;
		&_left ,
		&_right {
			float: none;
			margin: 0 0 20px 0;
		}
	}
}

/*
画像とテキスト - （SP時floatそのまま）

imgTxt02

Markup:
<div class="imgTxt02">
	<div class="imgTxt02_left">
		<div class="imgTxt02_img"><img src="http://placehold.it/200x150/888888/ffffff?text=!!!Dummy+Image!!!" width="100" height="80" alt="ダミーイメージ"></div>
	</div>
	<div class="imgTxt02_body">
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
	</div>
</div><!-- /.imgTxt02 -->
<div class="imgTxt02">
	<div class="imgTxt02_right">
		<div class="imgTxt02_img"><img src="http://placehold.it/150x200/888888/ffffff?text=!!!Dummy+Image!!!" width="100" height="80" alt="ダミーイメージ"></div>
	</div>
	<div class="imgTxt02_body {$modifiers}">
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
	</div>
</div><!-- /.imgTxt02 -->

.imgTxt02_body-around - 画像の下まで回り込み

Styleguide 4.3.2
============================================================*/
.imgTxt02 {
	margin: 20px 0 0;
	overflow: hidden;
	*:first-child {
		margin-top: 0;
	}
	&_left {
		float: left;
		margin: 0 20px 0 0;
	}
	&_right {
		float: right;
		margin: 0 0 0 20px;
	}
	&_body {
		overflow: hidden;
		&-around {
			overflow: visible;
		}
	}
	&_img {
		text-align: center;
	}
	&_caption {
		margin-top: 5px !important;
		text-align: center;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.imgTxt02 {
		margin: 20px 0 0;
		&_left {
			margin: 0 10px 0 0;
		}
		&_right {
			margin: 0 0 0 10px;
		}
	}
}

/*
テーブル

table

Markup:
<table class="table">
	<caption class="caption">表のタイトル</caption>
	<thead>
		<tr>
			<th>ヘッダ</th>
			<th>ヘッダ</th>
			<th>ヘッダ</th>
			<th>ヘッダ</th>
			<th>ヘッダ</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td class="head">テキストテキスト</td>
			<td>テキストテキスト</td>
			<td>テキストテキスト</td>
			<td>テキストテキスト</td>
			<td><a href=""><i class="icon icon-pdf03 txtHide">pdf</i></a></td>
		</tr>
		<tr>
			<td class="head">テキストテキスト</td>
			<td>テキストテキスト</td>
			<td>テキストテキスト</td>
			<td>テキストテキスト</td>
			<td><a href=""><i class="icon icon-pdf03 txtHide">pdf</i></a></td>
		</tr>
	</tbody>
</table><!-- /.table -->

Styleguide 4.4.1
============================================================*/
.table {
	margin-top: 27px;
	display: table;
	width: 100%;
	table-layout: fixed;
	border-collapse:collapse;
	&:first-child {
		margin-top: 0;
	}
	.caption {
		padding: 8px;
		color: #fff;
		font-weight: bold;
		background-color: $mainColor01;
	}
	> thead ,
	> tbody ,
	> tfoot {
		> tr {
			> th,
			> td {
				min-width: 90px;
				padding: 6px 3px;
				text-align: center;
				vertical-align: middle;
				font-size: 13px;
				border: 1px solid $mainColor08;
			}
		}
	}
	> thead {
		> tr {
			> th {
				text-align: center;
				color: #fff;
				font-weight: bold;
				background-color: $mainColor04;
			}
		}
	}
	> tbody {
		> tr {
			> th,
			> td {
			}
		}
	}
	.head {
		font-weight: bold;
		background-color: $mainColor07;
	}
	&_mid {
		width: 115px;
	}
	ul ,
	ol {
		&:first-child {
			margin-top: 0;
		}
	}
	.txt_l {
		text-align: left;
	}
	.txt_c {
		text-align: center;
	}
	.txt_r {
		text-align: right;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.table {
		> thead ,
		> tbody ,
		> tfoot {
			> tr {
				> th,
				> td {
					padding: 3px;
				}
			}
		}
		> thead {
			> tr {
				> th {
					// min-height: 50px;
					padding: 8px 3px;
				}
			}
		}
	}
}

/*
テーブル2 (レスポンシブ時テーブル解除)

table02

Markup:
<table class="table02 {$modifiers}">
	<tbody>
		<tr>
			<th>ヘッダ</th>
			<td>テキストテキストテキストテキストテキストテキスト</td>
		</tr>
		<tr>
			<th>ヘッダ</th>
			<td>テキストテキストテキストテキストテキストテキスト</td>
		</tr>
		<tr>
			<th>ヘッダ</th>
			<td>テキストテキストテキストテキストテキストテキスト</td>
		</tr>
		<tr>
			<th>ヘッダ</th>
			<td>テキストテキストテキストテキストテキストテキスト</td>
		</tr>
		<tr>
			<th>ヘッダ</th>
			<td>テキストテキストテキストテキストテキストテキスト</td>
		</tr>
	</tbody>
</table><!-- /.table02 -->

.table02-wideS - wideS
.table02-wideHalf - wideHalf
.table02-wideL - wideL
.table02-wideMax - wideMax

Styleguide 4.4.2
============================================================*/
.table02 {
	margin-top: 27px;
	display: table;
	width: auto;
	table-layout: fixed;
	border-collapse:collapse;
	&-wideS {
		width: 30%;
	}
	&-wideHalf {
		width: 50%;
	}
	&-wideL {
		width: 80%;
	}
	&-wideMax {
		width: 100%;
	}
	&:first-child {
		margin-top: 0;
	}
	.caption {
		padding: 8px;
		color: #fff;
		font-weight: bold;
		background-color: $mainColor01;
	}
	> thead ,
	> tbody ,
	> tfoot {
		> tr {
			> th,
			> td {
				padding: .8em;
				vertical-align: middle;
				font-size: 13px;
				border: 1px solid $mainColor08;
			}
		}
	}
	> thead ,
	> tbody ,
	> tfoot {
		> tr {
			> th {
				width: 18em;
				padding: .8em 4em;
				text-align: center;
				color: #fff;
				font-weight: bold;
				background-color: $mainColor04;
			}
		}
	}
	.head {
		background-color: $mainColor07;
	}
	ul ,
	ol {
		&:first-child {
			margin-top: 0;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.table02 {
		&-wideS ,
		&-wideHalf ,
		&-wideL ,
		&-wideMax {
			width: 100%;
		}
		border: none;
		display: block;
		> thead ,
		> tbody ,
		> tfoot {
			display: block;
			width: auto;
			> tr {
				display: block;
				width: auto;
				> th,
				> td {
					display: block;
					width: auto;
				}
			}
		}
		> thead ,
		> tbody ,
		> tfoot {
			display: block;
			width: auto;
			> tr {
				display: block;
				width: auto;
				> th,
				> td {
					padding: .4em;
				}
			}
		}
	}
}

/*
ソート付きテーブル (tablesorter設定時使用)

tableのcss設定は上記「.table」を使用

tablesorter

Markup:
<table id="sampleTable" class="table tablesorter">
	<thead>
		<tr>
			<th>製品名</th>
			<th>品種</th>
			<th>サイズ</th>
			<th>最終改定日</th>
			<th>ダウンロード</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td class="head">ソルバイン</td>
			<td>A</td>
			<td>166KB</td>
			<td>2016/02/22</td>
			<td><a href=""><i class="icon icon-pdf03 txtHide">pdf</i></a></td>
		</tr>
		<tr>
			<td class="head">ソルバイン</td>
			<td>A</td>
			<td>166KB</td>
			<td>2016/02/22</td>
			<td><a href=""><i class="icon icon-pdf03 txtHide">pdf</i></a></td>
		</tr>
		<tr>
			<td class="head">ソルバイン</td>
			<td>D</td>
			<td>166KB</td>
			<td>2015/08/20</td>
			<td><a href=""><i class="icon icon-pdf03 txtHide">pdf</i></a></td>
		</tr>
	</tbody>
</table><!-- /.table -->

Styleguide 4.4.3
============================================================*/
.tablesorter {
	thead {
		.header {
			padding: 14px 20px 14px 15px;
			background-image: url('./../images/bullets/icon_arrow-sorter.png');
			background-repeat: no-repeat;
			background-position: 95% center;
			cursor: pointer;
		}
		.headerSortUp {
			background-image: url('./../images/bullets/icon_arrow-sorter-up.png');
		}
		.headerSortDown {
			background-image: url('./../images/bullets/icon_arrow-sorter-down.png');
		}
		.headerSortDown,
		.headerSortUp {
			background-color: #8dbdd8;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.tablesorter {
		thead {
			.header {
				background-position: 98% center;
			}
		}
	}
}

/*
スクロール付きテーブル

tableのcss設定は上記「.table」を使用

tableScroll

Markup:
<div class="tableScroll">
	<div class="tableScroll_wrap">
		<table _fixedhead="rows:1; cols:1" class="table">
			<thead class="tableScroll_head">
				<tr>
					<th>製品名</th>
					<th>品種</th>
					<th>サイズ</th>
					<th>最終改定日</th>
					<th>ダウンロード</th>
				</tr>
			</thead>
			<tbody class="tableScroll_body">
				<tr>
					<td class="head">ソルバイン</td>
					<td>A</td>
					<td>166KB</td>
					<td>2016/02/22</td>
					<td><a href=""><i class="icon icon-pdf03 txtHide">pdf</i></a></td>
				</tr>
				<tr>
					<td class="head">ソルバイン</td>
					<td>A</td>
					<td>166KB</td>
					<td>2016/02/22</td>
					<td><a href=""><i class="icon icon-pdf03 txtHide">pdf</i></a></td>
				</tr>
				<tr>
					<td class="head">ソルバイン</td>
					<td>D</td>
					<td>166KB</td>
					<td>2015/08/20</td>
					<td><a href=""><i class="icon icon-pdf03 txtHide">pdf</i></a></td>
				</tr>
			</tbody>
		</table><!-- /.table -->
	</div>
</div><!-- /.tableScroll -->

Styleguide 4.4.4
============================================================*/
.tableScroll {
	margin-top: 27px;
	&_wrap {
	}
	&_head {
	}
	&_body {
	}
	// .fixed_header_display_none_at_print {
	// 	left: auto !important;
	// }
	&-scroll2 {
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.tableScroll {
		// position: relative;
		// overflow: hidden;
		// min-height: 360px;
		// white-space: nowrap;
		&_wrap {
			overflow: auto;
			&::-webkit-scrollbar {
				cursor: pointer;
				width: 10px;
				height: 10px;
			}
			&::-webkit-scrollbar-track {
				background: $mainColor08;
				cursor: pointer;
			}
			&::-webkit-scrollbar-thumb {
				background: $mainColor03;
				cursor: pointer;
			}
		}
		&_body {
		}
		.table {
			width: auto;
			white-space: nowrap;
		}
		.fixed_header_display_none_at_print {
			left: auto !important;
			-webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.2);
			        box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.2);
			th {
				border-color: $mainColor03;
			}
		}
		&-scroll2 {
			min-height: initial;
			.table {
				position: relative;
			}
			.tableScroll_wrap {
				// max-height: 300px;
			}
			.tableScroll_head {
			}
		}
	}
}

/*
記事エリア

article

Markup:
<article class="article">
	<h2 class="headLine1">
		<span class="headLine1_title">「コンバーテック１月号」に掲載されました</span>
	</h2>
	<div class="article_inner">
		<div class="article_info">
			<p class="article_date">2016.05.10</p>
			<p class="article_tag"><span class="tag tag-type2">ニュース</span></p>
		</div><!-- /.article_info -->
		<div class="article_body edit">
			<p>
				テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
				テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
				テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
			</p>
			<p>
				テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
			</p>
			<p>
				<a href="" class="linkArrow">リンクはこちらからリンクはこちらから</a><br>
			</p>
		</div><!-- /.article_body -->
	</div><!-- /.article_inner -->
</article><!-- /.article -->

Styleguide 4.5
============================================================*/
.article {
	// background-color: #ddd;
	&_inner {
	}
	&_info {
		text-align: right;
	}
	&_date ,
	&_tag {
		margin-top: 0;
	}
	&_body {
		margin-top: 0;
	}
	.edit {
		> p {
			// margin-top: 40px;
		}
	}
	&_img {
		figure {
			display: inline-block;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.article {
		.edit {
			> p {
				// margin-top: 25px;
			}
		}
		&_img {
			figure {
				margin: 20px 0 0 0 !important;
				text-align: center;
				display: block;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}

/*
ページャー

pager

Markup:
<nav class="pager {$modifiers}">
	<ul class="pager_list">
		<li class="pager_item pager_item-prev"><a href=""><i>前の130件</i></a></li>
		<li class="pager_item"><a href="">1</a></li>
		<li class="pager_item pager_item-dot"><span>…</span></li>
		<li class="pager_item"><a href="">2</a></li>
		<li class="pager_item"><a href="">3</a></li>
		<li class="pager_item pager_item-active"><a href="">4</a></li>
		<li class="pager_item"><a href="">5</a></li>
		<li class="pager_item"><a href="">6</a></li>
		<li class="pager_item pager_item-dot"><span>…</span></li>
		<li class="pager_item"><a href="">15</a></li>
		<li class="pager_item pager_item-next"><a href=""><i>次の20件</i></a></li>
	</ul>
</nav><!-- /.pager -->

.pager-top - pager上部部配置
.pager-bottom - pager下部配置

Styleguide 4.6
============================================================*/
.pager {
	$this: &;
	&_list {
		@include inline_block-p;
		text-align: center;
	}
	&_item {
		@include inline_block;
		vertical-align: middle;
		position: relative;
		margin-left: 6px;
		&:first-child {
			margin-left: 0;
		}
		a,
		span {
			display: block;
			margin: 0;
			padding: 12px;
			line-height: 1;
			font-size: 14px;
			color: $mainColor02;
			transition: background-color .4s;
			text-decoration: none;
		}
		a:hover,
		&-active a {
			background: $mainColor05;
			-webkit-border-radius: 3px;
			        border-radius: 3px;
			opacity: 1;
		}
		&-prev ,
		&-next {
			> a {
				position: relative;
				display: block;
				margin: 0;
				padding: 11px 12px;
				width: 226px;
				background-color: #fff;
				border: 1px solid #D2E9F2;
				-webkit-border-radius: 3px;
				        border-radius: 3px;
				&:hover {
					opacity: .6;
				}
				&:before ,
				&:after {
					position: relative;
					top: 3px;
					line-height: 0;
					color: inherit;
					font-size: 24px;
					// position: absolute;
					// top: 0;
					// padding: 11px 0;
					// line-height: .8;
					// color: inherit;
					// font-size: 24px;
				}
			}
		}
		&-dot {
			vertical-align: middle;
		}
		&-prev ,
		&-next {
			a {
				i {
					font-style: normal;
				}
			}
		}
		&-prev {
			a {
				&:before {
					left: -8px;
					content:"\f104";
					font-family: FontAwesome;
					// margin-right:-18px;
				}
			}
		}
		&-next {
			a {
				&:after {
					right: -8px;
					content:"\f105";
					font-family: FontAwesome;
					// margin-left: 10px;
				}
			}
		}
	}

	// layout module
	&-top {
		margin: 0 0 30px;
	}
	&-bottom {
		margin: 30px 0 0;
	}
	&-bottom02 {
		margin: 70px 0 0;
	}
	&-type01 {
		#{$this}_item {
			&-prev ,
			&-next {
				> a {
					width: 160px;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.pager {
		$this: &;
		&_item {
			a,
			span {
				padding: 9px;
				font-size: 12px;
			}
			&-prev ,
			&-next {
				text-align: center;
				> a {
					position: relative;
					display: block;
					margin: 0 auto;
					padding: 0;
					// width: 180px;
					width: 33px;
					height: 33px;
					line-height: 33px;
					&:before ,
					&:after {
						position: absolute;
						top: 50%;
						font-size: 24px;
						// text-indent: -1em;
						white-space: normal;
					}
					i {
						@include textHidden;
					}
				}
			}
			&-prev {
				> a {
					&:before {
						left: 10px;
					}
				}
			}
			&-next {
				> a {
					&:after {
						right: 10px;
					}
				}
			}
		}

		// layout module
		&-top {
		}
		&-bottom {
			margin: 30px 0 0;
		}
		&-bottom02 {
			margin: 40px 0 0;
		}
		&-type01 {
			#{$this}_item {
				&-prev ,
				&-next {
					> a {
						width: 90px;
						i {
							font-size: 14px;
							text-indent: 0;
						}
					}
				}
				&-prev {
					> a {
						padding-left: 10px;
					}
				}
				&-next {
					> a {
						padding-right: 10px;
					}
				}
			}
		}
	}
}

/*
新着情報

news

Markup:
<article class="news">
	<div class="news_inner">
		<h2 class="news_title">新着情報</h2>
		<ul class="news_list">
			<li class="news_item">
				<a href="<%= siteRoot %>news/detail.html">
					<dl class="news_detail">
						<dt class="news_date">2015.09.28</dt>
						<dd class="news_tag"><span class="tag tag-type1">CSR活動</span><span class="tag tag-type1">CSR活動</span><span class="tag tag-type1">CSR活動</span></dd>
						<dd class="news_txt">キッズ用防犯ブザープレゼントキャンペーンに協賛しています</dd>
					</dl><!-- /.news_detail -->
				</a>
			</li><!-- /.news_item -->
			<li class="news_item">
				<a href="">
					<dl class="news_detail">
						<dt class="news_date">2015.09.25</dt>
						<dd class="news_tag"><span class="tag tag-type2">展示/メディア</span></dd>
						<dd class="news_txt">【越前モノづくりフェスタ２０１５】に出展しました</dd>
					</dl><!-- /.news_detail -->
				</a>
			</li><!-- /.news_item -->
			<li class="news_item">
				<a href="">
					<dl class="news_detail">
						<dt class="news_date">2015.09.25</dt>
						<dd class="news_tag"><span class="tag tag-type1">CSR活動</span></dd>
						<dd class="news_txt">地域社会への貢献/「体験農業」へ参画！（2015年・第３報）</dd>
					</dl><!-- /.news_detail -->
				</a>
			</li><!-- /.news_item -->
			<li class="news_item">
				<a href="">
					<dl class="news_detail">
						<dt class="news_date">2015.09.25</dt>
						<dd class="news_tag"><span class="tag tag-type2">展示/メディア</span></dd>
						<dd class="news_txt">9月3日「化学工業日報」に掲載されました</dd>
					</dl><!-- /.news_detail -->
				</a>
			</li><!-- /.news_item -->
			<li class="news_item">
				<div>
					<dl class="news_detail">
						<dt class="news_date">2015.09.25</dt>
						<dd class="news_tag"><span class="tag tag-type1">CSR活動</span></dd>
						<dd class="news_txt">リンク無しリンク無しリンク無しリンク無しリンク無しリンク無しリンク無し</dd>
					</dl><!-- /.news_detail -->
				</div>
			</li><!-- /.news_item -->
		</ul><!-- /.news_list -->
		<div class="news_link">
			<a href="">バックナンバーを見る</a>
		</div><!-- /.news_link -->
	</div><!-- /.news_inner -->
</article><!-- /.news -->

Styleguide 4.7
============================================================*/
.news {
	&_inner {
		margin: 0 auto;
	}
	&_title {
		text-align: center;
		color: #323232;
		line-height: 1.2;
		font-size: 28px;
		font-weight: normal;
	}
	&_list {
		margin: 33px auto 0;
		border-top: 1px solid $mainColor08;
	}
	&_item {
		padding: 2px 0;
		border-bottom: 1px solid $mainColor08;
		> a ,
		> div {
			position: relative;
			display: block;
			padding: 12px 45px 10px 15px;
			color: #333;
			text-decoration: none;
			&:after {
				display: inline-block;
				position: absolute;
				content: "\f105";
				font-family: FontAwesome;
				top: 50%;
				right: 18px;
				color: #adadad;
				margin-top: -14px;
				font-size: 20px;
			}
		}
		> div {
			&:after {
				display: none;
			}
		}
	}
	&_detail {
		margin-top: 0;
		&:before,
		&:after {
			content: ".";
			display: block;
			height: 0;
			visibility: hidden;
		}
		&:after {
			clear: both;
		}
	}
	&_date ,
	&_tag ,
	&_txt {
		font-size: 14px;
	}
	&_date ,
	&_tag {
		float: left;
	}
	&_date {
		width: 9em;
	}
	&_tag {
		width: 130px;
		.tag {
			position: relative;
			top: -2px;
			margin-top: 5px;
			width: 120px;
			height: 22px;
			&:first-child {
				margin-top: 0;
			}
		}
	}
	&_txt {
		color: #333;
		overflow: hidden; /* 改行表示の場合 */
		// text-overflow: ellipsis; /* 省略表示の場合 */
		// white-space: nowrap; /* 省略表示の場合 */
	}
	&_link {
		margin: 18px auto 0;
		// width: $topMainWidth;
		text-align: right;
		a {
			position: relative;
			display: block;
			text-decoration: none;
			color: inherit;
			font-size: 13px;
			&:before {
				content:"\f138";
				font-family: FontAwesome;
				margin-right: 6px;
				color: $mainColor01;
				font-size: inherit;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.news {
		margin-top: 26px;
		&_inner {
			padding: 0 6px;
		}
		&_title {
			font-size: 19px;
			font-weight: bold;
		}
		&_list {
			margin: 15px auto 0;
		}
		&_item {
			> a ,
			> div {
				position: relative;
				display: block;
				padding: 12px 2px 12px;
				color: #333;
				text-decoration: none;
				&:after {
					display: none;
				}
			}
		}
		&_detail {
			margin-top: 0;
			&:before,
			&:after {
				content: ".";
				display: block;
				height: 0;
				visibility: hidden;
			}
			&:after {
				clear: both;
			}
		}
		&_date ,
		&_tag ,
		&_txt {
			font-size: 12px;
		}
		&_date {
			width: auto;
			margin-right: 12px;
		}
		&_tag {
			width: 130px;
			.tag {
			}
		}
		&_txt {
			clear: both;
			padding: 8px 0 0;
		}
		&_link {
			margin: 14px auto 0;
			width: 100%;
		}
	}
}

/*
ページ内ガイド

pageGuide

Markup:
<nav class="pageGuide">
	<ul class="pageGuide_list">
		<li class="pageGuide_item"><a href="">当社について</a></li>
		<li class="pageGuide_item"><a href="">エマルジョンについて</a></li>
	</ul><!-- /.pageGuide_list -->
</nav><!-- /.pageGuide -->

Styleguide 4.8
============================================================*/
.pageGuide {
	margin-top: 23px;
	padding: 16px 30px;
	background-color: $mainColor06;
	&_list {
		@include inline_block-p;
		margin: -10px 0 0 -88px;
	}
	&_item {
		@include inline_block;
		margin: 10px 0 0 88px;
		a {
			position: relative;
			color: inherit;
			text-decoration: none;
			&:before {
				display: inline-block;
				content:"\f138";
				font-family: FontAwesome;
				color: inherit;
				font-size: inherit;
				margin-right: 5px;
				width: auto;
				height: auto;
				color: $mainColor04;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.pageGuide {
		padding: 10px;
		&_list {
			margin: 0;
			> :first-child {
				margin-top: 0;
			}
		}
		&_item {
			display: block;
			margin: 8px 0 0;
			a {
				display: block;
				padding: 3px 0;
			}
		}
	}
}

/*
ページリスト

pageList

Markup:
<article class="pageList">
	<ul class="pageList_list">
		<li class="pageList_item">
			<div class="pageList_inner">
				<figure class="pageList_img"><img src="http://placehold.it/212x114/888888/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
				<div class="pageList_body">
					<div class="pageList_title"><a href="./co-corporate.html"><span>会社概要</span></a></div>
					<p class="pageList_txt">説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
				</div><!-- /.pageList_body -->
			</div>
		</li><!-- /.pageList_item -->
		<li class="pageList_item">
			<div class="pageList_inner">
				<figure class="pageList_img"><img src="http://placehold.it/212x114/336699/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
				<div class="pageList_body">
					<div class="pageList_title"><a href="./csr.html"><span>CSR活動</span></a></div>
					<p class="pageList_txt">説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
				</div><!-- /.pageList_body -->
			</div>
		</li><!-- /.pageList_item -->
		<li class="pageList_item">
			<div class="pageList_inner">
				<figure class="pageList_img"><img src="http://placehold.it/800x200/CC5522/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
				<div class="pageList_body">
					<div class="pageList_title"><a href="./movie.html"><span>ムービー</span></a></div>
					<p class="pageList_txt">説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
				</div><!-- /.pageList_body -->
			</div>
		</li><!-- /.pageList_item -->
		<li class="pageList_item">
			<div class="pageList_inner">
				<figure class="pageList_img"><img src="http://placehold.it/400x800/99ccff/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
				<div class="pageList_body">
					<div class="pageList_title"><a href="./information.html"><span>日信化学ってどんな会社？</span></a></div>
					<p class="pageList_txt">説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
				</div><!-- /.pageList_body -->
			</div>
		</li><!-- /.pageList_item -->
		<li class="pageList_item">
			<div class="pageList_inner">
				<figure class="pageList_img"><img src="http://placehold.it/400x800/99ccff/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
				<div class="pageList_body">
					<div class="pageList_title"><a href="./movie.html"><span>製品紹介動画</span></a></div>
					<ul class="pageList_menuList">
						<li><a href="">製品紹介</a></li>
						<li><a href="">ビニブラン700</a></li>
						<li><a href="">オルフィン</a></li>
					</ul>
					<p class="pageList_txt">日信化学工業の製品をご利用の用途毎にご紹介しております。</p>
				</div><!-- /.pageList_body -->
			</div>
		</li><!-- /.pageList_item -->
	</ul><!-- /.pageList_list -->
</article><!-- /.pageList -->

Styleguide 4.9.1
============================================================*/
.pageList {
	$menuListSpace: 20px;
	$menuListUdSpace: 5px;

	margin-top: 27px;
	&:first-child {
		margin-top: 0;
	}
	&_list {
		margin: 0;
	}
	&_item {
		margin-top: 25px;
		padding: 16px;
		overflow: hidden;
		border: 2px solid $mainColor06;
		&:first-child {
			margin-top: 0;
		}
	}
	&_inner {
		position: relative;
		display: block;
		margin: -16px;
		padding: 16px;
		overflow: hidden;
		color: inherit;
		text-decoration: none;
		background: url('./../images/bullets/icon_arrow02.png') right bottom no-repeat;
	}
	&_img {
		float: left;
		margin: 0 32px 0 0;
		position: relative;
		width: 212px;
		height: 114px !important;
		overflow: hidden;
		text-align: center;
		// background-color: #f00;
		> img {
			display: block;
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
		}
	}
	&_body {
		padding-top: 7px;
		overflow: hidden;
	}
	&_title {
		position: relative;
		display: block;
		margin: 0 0 22px;
		color: #333;
		padding: 0 10px 3px 20px;
		font-size: 16px;
		font-weight: bold;
		border-bottom: 1px dotted $mainColor09;
		&:before {
			display: inline-block;
			position: absolute;
			top: 0px;
			left: 2px;
			content: "\f138";
			font-family: FontAwesome;
			color: $mainColor04;
			font-size: inherit;
		}
		> a {
			position: relative;
			display: block;
			color: inherit;
			font-style: normal;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&_txt {
		line-height: 1.6;
	}

	&_menuList {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: stretch;
				align-items: stretch;
		-webkit-flex-wrap: wrap;
				flex-wrap: wrap;

		margin: -#{$menuListUdSpace} 0 0 -#{$menuListSpace};
		list-style-type: none;
		line-height: 1.25;
		> li {
			position: relative;
			margin: #{$menuListUdSpace} 0 0 #{$menuListSpace};
			padding-left: 1em;
			width: calc( ( 100% - #{$menuListSpace} * 2 ) / 2);
			width: calc( ( 100% - 4px - #{$menuListSpace} * 2 ) / 2)\9;
			&:before {
				content:"\f101";
				display: inline-block;
				position: absolute;
				top: 4px;
				left: 0;
				font-family: FontAwesome;
				color: $baseColor;
				font-size: inherit;
			}
			> a ,
			> span {
				display: inline-block;
				padding: 4px 0;
				color: $baseColor;
				text-decoration: none;
				font-weight: bold;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.pageList {
		&_item {
			margin-top: 25px;
			padding: 8px;
		}
		&_inner {
			margin: -8px;
			padding: 8px;
		}
		&_img {
			float: none;
			margin: 0 auto;
			width: auto;
			text-align: center;
		}
		&_body {
			padding-top: 10px;
			overflow: hidden;
		}
		&_title {
			font-size: 14px;
		}
		&_txt {
			margin-top: 10px;
			font-size: 12px;
		}

		&_menuList {
			> li {
				&:before {
					top: 3px;
				}
			}
		}
	}
}

/*
ページリスト02

pageList02

Markup:
<article class="pageList02">
	<ul class="pageList02_list">
		<li class="pageList02_item">
			<a href="">
				<figure class="pageList02_img"><img src="http://placehold.it/210x134/888888/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
				<div class="pageList02_body">
					<div class="pageList02_title headLine4"><span>製品名から探す</span></div>
					<p class="pageList02_txt">
						説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
						説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
					</p>
				</div><!-- /.pageList02_body -->
			</a>
		</li><!-- /.pageList02_item -->
		<li class="pageList02_item">
			<a href="">
				<figure class="pageList02_img"><img src="http://placehold.it/800x200/CC5522/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
				<div class="pageList02_body">
					<div class="pageList02_title headLine4"><span>製品名から探す</span></div>
					<p class="pageList02_txt">
						説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
						説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
					</p>
				</div><!-- /.pageList02_body -->
			</a>
		</li><!-- /.pageList02_item -->
		<li class="pageList02_item">
			<a href="">
				<figure class="pageList02_img"><img src="http://placehold.it/400x800/99ccff/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
				<div class="pageList02_body">
					<div class="pageList02_title headLine4"><span>製品名から探す</span></div>
					<p class="pageList02_txt">
						説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
						説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
					</p>
				</div><!-- /.pageList02_body -->
			</a>
		</li><!-- /.pageList02_item -->
	</ul><!-- /.pageList02_list -->
</article><!-- /.pageList02 -->

Styleguide 4.9.2
============================================================*/
.pageList02 {
	margin-top: 27px;
	&:first-child {
		margin-top: 0;
	}
	&_list {
		margin: 0;
	}
	&_item {
		margin-top: 34px;
		overflow: hidden;
		&:first-child {
			margin-top: 28px;
		}
		> a {
			position: relative;
			display: block;
			text-decoration: none;
			overflow: hidden;
			color: inherit;
			text-decoration: none;
		}
	}
	&_img {
		float: left;
		margin: 0 32px 0 0;
		position: relative;
		width: 210px;
		height: 140px !important;
		overflow: hidden;
		text-align: center;
		// background-color: #f00;
		> img {
			display: block;
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
		}
	}
	&_body {
		padding-top: 4px;
		overflow: hidden;
	}
	&_title {
		margin-top: 0;
	}
	&_txt {
		font-size: 13px;
		line-height: 1.6;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.pageList02 {
		&_list {
		}
		&_item {
			margin-top: 25px;
			padding: 10px;
		}
		&_img {
			float: none;
			margin: 0 auto;
			width: auto;
			text-align: center;
		}
		&_body {
			padding-top: 10px;
			overflow: hidden;
		}
		&_title {
			margin-top: 0;
		}
		&_txt {
			margin-top: 12px;
			font-size: 12px;
		}
	}
}

/*
ページリスト03

pageList03

Markup:
<div class="pageList03">
	<div class="pageList03_item">
		<figure class="pageList03_img pageList03_img-left"><img src="http://placehold.it/140x90/888888/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
		<div class="pageList03_body">
			<div class="pageList03_title"><a href="#"><span>製品名から探す</span></a></div>
			<p class="pageList03_txt">
				説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
				説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
			</p>
		</div>
	</div>
	<div class="pageList03_item">
		<figure class="pageList03_img pageList03_img-left"><img src="http://placehold.it/200x150/336699/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
		<div class="pageList03_body">
			<div class="pageList03_title"><a href="#"><span>製品名から探す</span></a></div>
			<p class="pageList03_txt">
				説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
				説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
			</p>
		</div>
	</div>
	<div class="pageList03_item">
		<figure class="pageList03_img pageList03_img-right"><img src="http://placehold.it/150x200/99ccff/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
		<div class="pageList03_body">
			<div class="pageList03_title"><a href="#"><span>製品名から探す</span></a></div>
			<p class="pageList03_txt">
				説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
				説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
			</p>
		</div>
	</div>
</div><!-- /.pageList03 -->

Styleguide 4.9.3
============================================================*/
.pageList03 {
	margin-top: 20px;
	&:first-child {
		margin-top: 0;
	}
	&_item {
		position: relative;
		display: block;
		overflow: hidden;
		margin-top: 20px;
		padding-top: 20px;
		overflow: hidden;
		border-top: 1px solid $mainColor09;
		&:first-child {
			margin-top: 0;
			border-top: none;
		}
	}
	&_img {
		// min-width: 260px;
		// min-height: 167px;
		// background: #fff url('./../images/global/bg_no-image.png') center no-repeat;
		&-left {
			float: left;
			margin: 0 20px 0 0;
		}
		&-right {
			float: right;
			margin: 0 0 0 20px;
		}
	}
	&_body {
		padding-top: 4px;
		overflow: hidden;
	}
	&_title {
		position: relative;
		display: block;
		margin: 0 0 22px;
		color: #333;
		padding: 0 10px 3px 20px;
		font-size: 16px;
		font-weight: bold;
		border-bottom: 1px dotted $mainColor09;
		&:before {
			display: inline-block;
			position: absolute;
			top: 0px;
			left: 2px;
			content: "\f138";
			font-family: FontAwesome;
			color: $mainColor04;
			font-size: inherit;
		}
		> a {
			position: relative;
			display: block;
			color: inherit;
			font-style: normal;
			text-decoration: none;
		}
	}
	&_txt {
		margin-top: 10px;
		font-size: 13px;
		line-height: 1.6;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.pageList03 {
		&_item {
			margin-top: 25px;
			padding: 10px;
		}
		&_img {
			&-left ,
			&-right {
				float: none;
				margin: 0;
				text-align: center;
			}
		}
		&_body {
			padding-top: 10px;
			overflow: hidden;
		}
		&_title {
			margin-top: 0;
			font-size: 14px;
		}
		&_txt {
			margin-top: 12px;
			font-size: 12px;
		}
	}
}

/*
ボックスリスト

boxList

Markup:
<article class="boxList {$modifiers}">
	<ul class="boxList_list">
		<li class="boxList_item">
			<a href="" data-mh="mh_block">
				<figure class="boxList_img"><img src="http://placehold.it/420x200/888888/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
				<div class="boxList_body">
					<div class="boxList_title headLine4"><span>紙接着</span></div>
					<p class="boxList_txt">
						資料説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
					</p>
				</div><!-- /.boxList_body -->
			</a>
		</li><!-- /.boxList_item -->
		<li class="boxList_item">
			<a href="" data-mh="mh_block">
				<figure class="boxList_img"><img src="http://placehold.it/420x200/330066/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
				<div class="boxList_body">
					<div class="boxList_title headLine4"><span>紙加工</span></div>
					<p class="boxList_txt">
						資料説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
					</p>
				</div><!-- /.boxList_body -->
			</a>
		</li><!-- /.boxList_item -->
		<li class="boxList_item">
			<a href="" data-mh="mh_block">
				<figure class="boxList_img"><img src="http://placehold.it/800x200/cc6699/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
				<div class="boxList_body">
					<div class="boxList_title headLine4"><span>木工・建築</span></div>
					<p class="boxList_txt">
						資料説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
					</p>
					<div class="boxList_info">
						<p class="boxList_date">2016.05.10</p>
						<p class="boxList_tag"><span class="tag tag-type1">タグ1</span></p>
					</div>
				</div><!-- /.boxList_body -->
			</a>
		</li><!-- /.boxList_item -->
		<li class="boxList_item">
			<a href="" data-mh="mh_block">
				<figure class="boxList_img"><img src="http://placehold.it/400x800/55ddff/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
				<div class="boxList_body">
					<div class="boxList_title headLine4"><span>木工・建築</span></div>
					<p class="boxList_txt">
						資料説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
					</p>
					<div class="boxList_info">
						<p class="boxList_date">2016.05.10</p>
						<p class="boxList_tag"><span class="tag tag-type1">タグ1</span></p>
					</div>
				</div><!-- /.boxList_body -->
			</a>
		</li><!-- /.boxList_item -->
	</ul><!-- /.boxList_list -->
</article><!-- /.boxList -->

.box1 - box1
.box2 - box2
.box3 - box3
.box4 - box4

Styleguide 4.10.1
============================================================*/
.boxList {
	$this: &;
	$space: 45px;
	position: relative;
	margin-top: 27px;
	&:first-child {
		margin-top: 0;
	}
	&_list {
		@include inline_block-p;
		margin: -#{$space} 0 0 -#{$space};
	}
	&_item {
		@include inline_block;
		margin: #{$space} 0 0 #{$space};
		width: calc( ( 100% - #{$space} * 2 ) / 2);
		width: calc( ( 100% - 4px - #{$space} * 2 ) / 2)\9;
		> a ,
		> div {
			position: relative;
			display: block;
			width: 100%;
			height: 100%;
			// max-height: 100vh;
			text-decoration: none;
			color: inherit;
		}
	}
	&_img {
		position: relative;
		margin: 0 auto;
		width: 100%;
		height: 100px !important;
		overflow: hidden;
		text-align: center;
		background: #fff url('./../images/global/bg_no-image.png') center no-repeat;
		// background-color: #f00;
		> img {
			display: block;
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
		}
	}
	&_body {
		margin-top: 16px;
	}
	&_title {
	}
	&_txt {
		font-size: 13px;
	}
	&_info {
		margin-top: 0;
	}
	&_date ,
	&_tag {
		margin-top: 0;
		text-align: right;
	}
	&_date {
		margin-top: 10px;
		color: #999;
	}
	&_tag {
	}

	// box size
	&.box1 {
		$space: 25px;
		#{$this}_list {
			display: block;
			margin: 0;
		}
		#{$this}_item {
			display: block;
			margin: #{$space} 0 0;
			width: auto;
			width: auto\9;
			&:first-child {
				margin-top: 0;
			}
		}
	}
	&.box2 {
		$space: 45px;
		#{$this}_list {
			margin: -#{$space} 0 0 -#{$space};
		}
		#{$this}_item {
			margin: #{$space} 0 0 #{$space};
			width: calc( ( 100% - #{$space} * 2 ) / 2);
			width: calc( ( 100% - 4px - #{$space} * 2 ) / 2)\9;
		}
		#{$this}_img {
			height: 186px !important;
		}
	}
	&.box3 {
		$space: 45px;
		#{$this}_list {
			margin: -#{$space} 0 0 -#{$space};
		}
		#{$this}_item {
			margin: #{$space} 0 0 #{$space};
			width: calc( ( 100% - #{$space} * 3 ) / 3);
			width: calc( ( 100% - 3px - #{$space} * 3 ) / 3)\9;
		}
		#{$this}_img {
			height: 150px !important;
		}
	}
	&.box4 {
		$space: 25px;
		#{$this}_list {
			margin: -#{$space} 0 0 -#{$space};
		}
		#{$this}_item {
			margin: #{$space} 0 0 #{$space};
			width: calc( ( 100% - #{$space} * 4 ) / 4);
			width: calc( ( 100% - 4px - #{$space} * 4 ) / 4)\9;
		}
	}

	// 製品別
	&-feature {
		$space: 25px;
		#{$this}_list {
			margin: -#{$space} 0 0 -#{$space} !important;
		}
		#{$this}_item {
			margin: #{$space} 0 0 #{$space} !important;
			width: calc( ( 100% - #{$space} * 3 ) / 3) !important;
			width: calc( ( 100% - 4px - #{$space} * 3 ) / 3)\9 !important;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.boxList {
		$this: &;
		$space: 30px;
		&_list {
			display: block;
			margin: 0;
		}
		&_item {
			display: block;
			margin: #{$space} 0 0;
			padding: 10px;
			&:first-child {
				margin-top: 0;
			}
		}
		&_img {
			width: 100%;
			height: 130px !important;
		}
		&_body {
			margin-top: 10px;
			padding: 0;
		}
		&_title {
			margin-bottom: 15px;
		}
		&_txt {
			margin-top: 10px;
			font-size: 12px;
		}

		// box size
		&.box2 ,
		&.box3 ,
		&-feature {
			$space: 25px;
			#{$this}_list {
				margin: 0;
			}
			#{$this}_item {
				margin: #{$space} 0 0;
				width: auto;
				width: auto\9;
				&:first-child {
					margin-top: 0;
				}
			}
			#{$this}_img {
				width: 95%;
				height: 160px !important;
			}
		}
		&.box4 {
			$space: 10px;
			#{$this}_list {
				@include inline_block-p;
				margin: -#{$space} 0 0 -#{$space};
			}
			#{$this}_item {
				@include inline_block;
				margin: #{$space} 0 0 #{$space};
				width: calc( ( 100% - #{$space} * 2 ) / 2);
				width: calc( ( 100% - 4px - #{$space} * 2 ) / 2)\9;
			}
			#{$this}_img {
				width: 95%;
				height: 70px !important;
			}
		}

		// 製品別
		&-feature {
			$space: 15px;
			#{$this}_list {
				margin: 0 !important;
			}
			#{$this}_item {
				margin: #{$space} 0 0 !important;
				width: auto !important;
				width: auto\9 !important;
				padding: 0;
				&:first-child {
					margin-top: 0 !important;
				}
			}
		}
	}
}

/*
ボックスリスト02

boxList02

Markup:
<article class="boxList02 {$modifiers}">
	<ul class="boxList02_list">
		<li class="boxList02_item">
			<div class="boxList02_inner" data-mh="mh_block">
				<figure class="boxList02_img"><a href="https://www.youtube.com/embed/xxxxxxxxxxxxxx?autoplay=1&rel=0" class="movieBox" title="">
					<img src="http://placehold.it/210x134/888888/ffffff?text=!!!Dummy+Image!!!" alt="">
				</a></figure>
				<div class="boxList02_body">
					<div class="boxList02_title"><span>会社概要</span></div>
					<p class="boxList02_txt">
						会社概要、製品概要、社長メッセージ、CSR活動紹介<br>
						（4分55秒）<br>
					</p>
					<div class="boxList02_info">
						<p class="boxList02_date">最終改定日：2016/02/20</p>
					</div>
				</div><!-- /.boxList02_body -->
			</div>
		</li><!-- /.boxList02_item -->
		<li class="boxList02_item">
			<div class="boxList02_inner" data-mh="mh_block">
				<figure class="boxList02_img"><a href="https://www.youtube.com/embed/xxxxxxxxxxxxxx?autoplay=1&rel=0" class="movieBox" title="">
					<img src="http://placehold.it/800x200/CC5522/ffffff?text=!!!Dummy+Image!!!" alt="">
				</a></figure>
				<div class="boxList02_body">
					<div class="boxList02_title"><span>製品紹介</span></div>
					<p class="boxList02_txt">
						樹脂製品の製造方法、用途紹介<br>
						（5分5秒）<br>
					</p>
					<div class="boxList02_info">
						<p class="boxList02_date">最終改定日：2016/02/20</p>
					</div>
				</div><!-- /.boxList02_body -->
			</div>
		</li><!-- /.boxList02_item -->
		<li class="boxList02_item">
			<div class="boxList02_inner" data-mh="mh_block">
				<figure class="boxList02_img"><a href="https://www.youtube.com/embed/xxxxxxxxxxxxxx?autoplay=1&rel=0" class="movieBox" title="">
					<img src="http://placehold.it/400x800/99ccff/ffffff?text=!!!Dummy+Image!!!" alt="">
				</a></figure>
				<div class="boxList02_body">
					<div class="boxList02_title"><span>仕事紹介</span></div>
					<p class="boxList02_txt">
						研究職・技術職・営業職仕事紹介、社長メッセージ、製品概要、社員メッセージ<br>
						（13分7秒）<br>
					</p>
					<div class="boxList02_info">
						<p class="boxList02_date">最終改定日：2016/02/20</p>
					</div>
				</div><!-- /.boxList02_body -->
			</div>
		</li><!-- /.boxList02_item -->
	</ul><!-- /.boxList02_list -->
</article><!-- /.boxList02 -->

.box1 - box1
.box2 - box2

Styleguide 4.10.2
============================================================*/
.boxList02 {
	$this: &;
	$space: 34px;
	margin-top: 27px;
	&:first-child {
		margin-top: 0;
	}
	&_list {
		@include inline_block-p;
		margin: -#{$space} 0 0 -#{$space};
	}
	&_item {
		@include inline_block;
		margin: #{$space} 0 0 #{$space};
		padding: 16px;
		overflow: hidden;
		border: 2px solid $mainColor06;
		> a ,
		> div {
			position: relative;
			display: block;
			// width: 100%;
			height: 100%;
			// max-height: 100vh;
			text-decoration: none;
			color: inherit;
		}
	}
	&_inner {
		position: relative;
		display: block;
		margin: -16px;
		padding: 16px 16px 30px 16px;
		overflow: hidden;
		color: inherit;
		text-decoration: none;
		background: url('./../images/bullets/icon_arrow02.png') right bottom no-repeat;
	}
	&_img {
		margin-top: 0;
		text-align: center;
		> a ,
		> div {
			position: relative;
			display: block;
			margin: 0 auto;
			width: 372px;
			height: 206px !important;
			overflow: hidden;
			text-align: center;
			// background-color: #f00;
			&:hover {
				opacity: .8;
				&::before {
					opacity: .8;
				}
				&::after {
					opacity: .8;
				}
			}
		}
		img {
			display: block;
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
		}
	}
	&_movie {
		position: relative;
		display: block;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			background-color: rgba(0, 0, 0, 0.5);
			opacity: 0;
			transition: all 0.3s linear;
		}
		&::after {
			content: "\f144";
			font-family: FontAwesome;
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			line-height: 1;
			margin-top: -30px;
			color: #fff;
			text-align: center;
			font-size: 60px;
			opacity: 0;
			transition: all 0.3s ease-in-out;
		}
	}
	&_body {
		margin-top: 16px;
	}
	&_title {
		position: relative;
		display: block;
		margin: 0;
		color: #333;
		padding: 0 10px 3px 20px;
		font-size: 16px;
		font-weight: bold;
		border-bottom: 1px dotted $mainColor09;
		&:before {
			display: inline-block;
			position: absolute;
			top: 0px;
			left: 2px;
			content: "\f138";
			font-family: FontAwesome;
			color: $mainColor04;
			font-size: inherit;
		}
		> a {
			position: relative;
			display: block;
			color: inherit;
			font-style: normal;
			text-decoration: none;
		}
	}
	&_txt {
		margin-top: 10px;
		font-size: 13px;
	}
	&_info {
		margin-top: 0;
	}
	&_date ,
	&_tag {
		margin-top: 0;
		text-align: right;
	}
	&_date {
		margin-top: 5px;
		color: #999;
	}
	&_tag {
	}

	// box size
	&.box1 {
		$space: 25px;
		#{$this}_list {
			display: block;
			margin: 0;
		}
		#{$this}_item {
			display: block;
			margin: #{$space} 0 0;
			width: auto;
			width: auto\9;
			&:first-child {
				margin-top: 0;
			}
		}
	}
	&.box2 {
		#{$this}_list {
			margin: -#{$space} 0 0 -#{$space};
		}
		#{$this}_item {
			margin: #{$space} 0 0 #{$space};
			width: calc( ( 100% - #{$space} * 2 ) / 2);
			width: calc( ( 100% - 4px - #{$space} * 2 ) / 2)\9;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.boxList02 {
		$this: &;
		$space: 30px;
		&_list {
			display: block;
			margin: 0;
		}
		&_item {
			display: block;
			margin: #{$space} 0 0;
			padding: 10px;
			width: auto;
			width: auto\9;
			&:first-child {
				margin-top: 0;
			}
		}
		&_inner {
			margin: -10px;
			padding: 10px 10px 20px 10px;
		}
		&_img {
			> a ,
			> div {
				width: 100%;
				height: 160px !important;
			}
		}
		&_body {
			margin-top: 10px;
		}
		&_txt {
			margin-top: 12px;
			font-size: 12px;
		}
		&_info {
			font-size: 12px;
		}

		// box size
		&.box2 {
			$space: 25px;
			#{$this}_list {
				margin: 0;
			}
			#{$this}_item {
				margin: #{$space} 0 0;
				width: auto;
				width: auto\9;
			}
		}
	}
}

/*
ボックスリスト03

boxList03

Markup:
<article class="boxList03 {$modifiers}">
	<ul class="boxList03_list">
		<li class="boxList03_item">
			<div class="boxList03_inner" data-mh="mh_block">
				<figure class="boxList03_img"><a href="<%= siteRoot %>recruit/voice/researcher/" class="" title="">
					<img src="<%= siteRoot %>assets/images/bullets/icon_menu-voice.png" alt="">
				</a></figure>
				<div class="boxList03_body">
					<div class="boxList03_title"><a href="<%= siteRoot %>recruit/voice/researcher/" class="" title="">研究職</a></div>
					<p class="boxList03_txt">優れた性能と特徴のある機能を付与した機能性樹脂の開発や既存の製品の改良、取引先の要望に合わせた製品企画の試作開発および検討を行います。</p>
				</div><!-- /.boxList03_body -->
			</div>
		</li>
		<li class="boxList03_item">
			<div class="boxList03_inner" data-mh="mh_block">
				<figure class="boxList03_img"><a href="<%= siteRoot %>recruit/voice/technical/" class="" title="">
					<img src="<%= siteRoot %>assets/images/bullets/icon_menu-voice02.png" alt="">
				</a></figure>
				<div class="boxList03_body">
					<div class="boxList03_title"><a href="<%= siteRoot %>recruit/voice/technical/" class="" title="">技術職</a></div>
					<p class="boxList03_txt">製品を市場に安定して供給するための生産ラインを維持管理するとともに、製造のプロセスを合理化し、生産性の高いプラントの設計を進めていきます。</p>
				</div><!-- /.boxList03_body -->
			</div>
		</li>
	</ul><!-- /.boxList03_list -->
</article><!-- /.boxList03 -->

.box1 - box1
.box2 - box2

Styleguide 4.10.2
============================================================*/
.boxList03 {
	$this: &;
	$space: 34px;

	margin-top: 27px;
	&:first-child {
		margin-top: 0;
	}
	&_list {
		@include inline_block-p;
		margin: -#{$space} 0 0 -#{$space};
	}
	&_item {
		@include inline_block;
		margin: #{$space} 0 0 #{$space};
		padding: 16px;
		overflow: hidden;
		border: 2px solid $mainColor06;
		> a ,
		> div {
			position: relative;
			display: block;
			// width: 100%;
			height: 100%;
			// max-height: 100vh;
			text-decoration: none;
			color: inherit;
		}
	}
	&_inner {
		position: relative;
		display: block;
		margin: -16px;
		padding: 16px 16px 30px 16px;
		overflow: hidden;
		color: inherit;
		text-decoration: none;
		background: url('./../images/bullets/icon_arrow02.png') right bottom no-repeat;
	}
	&_img {
		margin-top: 0;
		text-align: center;
		> a ,
		> div {
			display: -webkit-flex;
			display: flex;
			-webkit-justify-content: center;
					justify-content: center;
			-webkit-align-items: center;
					align-items: center;
			position: relative;
			// display: block;
			margin: 0 auto;
			width: 100%;
			min-height: 120px;
			overflow: hidden;
			text-align: center;
			background-color: $mainColor05;
			&:hover {
				opacity: .8;
				&::before {
					opacity: .8;
				}
				&::after {
					opacity: .8;
				}
			}
		}
	}
	&_body {
		margin-top: 16px;
	}
	&_title {
		position: relative;
		display: block;
		margin: 0;
		color: #333;
		padding: 0 10px 3px 20px;
		font-size: 16px;
		font-weight: bold;
		border-bottom: 1px dotted $mainColor09;
		&:before {
			display: inline-block;
			position: absolute;
			top: 0px;
			left: 2px;
			content: "\f138";
			font-family: FontAwesome;
			color: $mainColor04;
			font-size: inherit;
		}
		> a {
			position: relative;
			display: block;
			color: inherit;
			font-style: normal;
			text-decoration: none;
		}
	}
	&_txt {
		margin-top: 10px;
		font-size: 13px;
	}

	// box size
	&.box1 {
		$space: 25px;
		#{$this}_list {
			display: block;
			margin: 0;
		}
		#{$this}_item {
			display: block;
			margin: #{$space} 0 0;
			width: auto;
			width: auto\9;
			&:first-child {
				margin-top: 0;
			}
		}
	}
	&.box2 {
		#{$this}_list {
			margin: -#{$space} 0 0 -#{$space};
		}
		#{$this}_item {
			margin: #{$space} 0 0 #{$space};
			width: calc( ( 100% - #{$space} * 2 ) / 2);
			width: calc( ( 100% - 4px - #{$space} * 2 ) / 2)\9;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.boxList03 {
		$this: &;
		$space: 30px;
		&_list {
			display: block;
			margin: 0;
		}
		&_item {
			display: block;
			margin: #{$space} 0 0;
			padding: 10px;
			width: auto;
			width: auto\9;
			&:first-child {
				margin-top: 0;
			}
		}
		&_inner {
			margin: -10px;
			padding: 10px 10px 20px 10px;
		}
		&_body {
			margin-top: 10px;
		}
		&_txt {
			margin-top: 12px;
			font-size: 12px;
		}

		// box size
		&.box2 {
			$space: 25px;
			#{$this}_list {
				margin: 0;
			}
			#{$this}_item {
				margin: #{$space} 0 0;
				width: auto;
				width: auto\9;
			}
		}
	}
}

/*
フレームボックス

frames

Markup:
<article class="frames {$modifiers}">
	<div class="frames_inner">
		<div class="frames_title"><span>見出し</span></div>
		<div class="frames_body">
			<figure class="frames_img"><img src="http://placehold.it/260x167/888888/ffffff?text=!!!Dummy+Image!!!" alt=""></figure>
			<p class="frames_txt">
				説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
				説明文テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
			</p>
		</div><!-- /.frames_body -->
	</div><!-- /.frames_inner -->
</article><!-- /.frames -->

.frames-wide - full

Styleguide 4.11
============================================================*/
.frames {
	margin: 35px auto 0;
	width: 765px;
	&:first-child {
		margin-top: 0;
	}
	&-wide {
		width: 100%;
	}
	&_inner {
	}
	&_title {
		padding: 8px 10px;
		text-align: center;
		color: #fff;
		font-size: 16px;
		background-color: $mainColor04;
	}
	&_body {
		padding: 18px 18px 14px;
		border: 2px solid $mainColor08;
		border-top: none;
	}
	&_img {
		margin-top: 0;
		text-align: center;
	}
	&_txt {
		font-size: inherit;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.frames {
		width: 100%;
		&_title {
			padding: 5px 10px;
		}
		&_body {
			padding: 10px 10px 8px;
		}
		&_img {
			img {
				width: 100%;
				height: auto;
			}
		}
		&_txt {
			font-size: 12px;
		}
	}
}

/*
リンクリスト

linkList

Markup:
<ul class="linkList">
	<li class="linkList_item"><a href="./aaa.html">リンクリストリンクリストリンクリスト</a></li>
	<li class="linkList_item"><a href="./bbb.html">リンクリストリンクリストリンクリスト</a></li>
	<li class="linkList_item"><a href="./ccc.html">リンクリストリンクリストリンクリスト</a></li>
</ul><!-- /.linkList -->

Styleguide 4.12
============================================================*/
.linkList {
	margin-top: 20px;
	&:first-child {
		margin-top: 0;
	}
	&_item {
		padding: 2px 0;
		border-bottom: 1px dotted #333;
		> a {
			position: relative;
			display: block;
			padding: 6px 20px 6px 6px;
			color: inherit;
			font-size: inherit;
			text-decoration: none;
			&:after {
				display: inline-block;
				position: absolute;
				content: "\f105";
				font-family: FontAwesome;
				top: 50%;
				right: 5px;
				margin-top: -15px;
				font-size: 20px;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.linkList {
		&_item {
			> a {
				font-size: 12px;
			}
		}
	}
}

/*
ファイルリンクリスト

fileList

Markup:
<ul class="fileList">
	<li class="fileList_item"><a href="">
		<dl class="fileList_detail">
			<dt class="fileList_title icon icon-pdf02">ファイル名（99.15MB）</dt>
			<dd class="fileList_txt">説明文テキストテキストテキストテキストテキストテキストテキスト</dd>
		</dl>
	</a></li>
	<li class="fileList_item"><a href="">
		<dl class="fileList_detail">
			<dt class="fileList_title icon icon-pdf02">ファイル名（99.15MB）</dt>
			<dd class="fileList_txt">説明文テキストテキストテキストテキストテキストテキストテキスト</dd>
		</dl>
	</a></li>
	<li class="fileList_item"><a href="">
		<dl class="fileList_detail">
			<dt class="fileList_title icon icon-pdf02">ファイル名（99.15MB）</dt>
			<dd class="fileList_txt">説明文テキストテキストテキストテキストテキストテキストテキスト</dd>
		</dl>
	</a></li>
</ul><!-- /.fileList -->

Styleguide 4.13
============================================================*/
.fileList {
$this: &;
	margin-top: 20px;
	&:first-child {
		margin-top: 0;
	}
	&_item {
		padding: 0;
		> a {
			position: relative;
			display: block;
			padding: 3px 10px;
			color: inherit;
			font-size: inherit;
			text-decoration: none;
		}
	}
	&_detail {
		margin-top: 0;
		&:before,
		&:after {
			content: ".";
			display: block;
			height: 0;
			visibility: hidden;
		}
		&:after {
			clear: both;
		}
	}
	&_title {
		float: left;
		font-weight: bold;
		&.icon + #{$this}_txt {
			padding-top: 5px;
		}
	}
	&_txt {
		position: relative;
		overflow: hidden;
		padding-left: 1.5em;
		// text-indent: -1em;
		&:before {
			display: inline-block;
			content: "…";
			margin-left: -1.5em;
			margin-right: .5em;
			position: relative;
			font-size: inherit;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.fileList {
	$this: &;
		&_item {
		border-bottom: 1px dotted $mainColor08;
			> a {
				position: relative;
				display: block;
				padding: 6px 20px 6px 6px;
				color: inherit;
				font-size: inherit;
				text-decoration: none;
			}
		}
		&_detail {
			margin-top: 0;
			&:before,
			&:after {
				content: ".";
				display: block;
				height: 0;
				visibility: hidden;
			}
			&:after {
				clear: both;
			}
		}
		&_title {
			float: none;
			&.icon + #{$this}_txt {
				padding-top: 0;
			}
		}
		&_txt {
			font-size: 12px;
			padding-left: 1.5em;
			// text-indent: -1em;
			&:before {
			}
		}
	}
}

/*
図関連

figures

Markup:
<div class="figures">
	<div class="figures_item">
		<figure><img src="./../assets/images/shared/products/detail/img_products-detail.gif" width="358" height="404" alt=""></figure>
	</div>
	<div class="figures_item">
		<figure><img src="./../assets/images/shared/products/detail/img_products-detail02.gif" width="358" height="404" alt=""></figure>
	</div>
</div><!-- /.figures -->

Styleguide 4.14
============================================================*/
.figures {
$this: &;
	@include inline_block-p;
	margin: 0 0 0 -10px;
	// width: 780px;
	text-align: center;
	&_item {
		@include inline_block;
		margin: 10px 0 0 10px;
		figure {
			margin: 0;
		}
	}
	&-wide {
		width: 100%;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.figures {
	$this: &;
		width: auto;
		&_item {
			display: block;
			padding: 0 10px;
			figure {
				margin: 0;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
		&-wide {
			width: 100%;
		}
	}
}

/*
FAQ

faq

Markup:
<article class="faq">
	<dl class="faq_item">
		<dt class="faq_q acd_btn">質問見出し質問見出し質問見出し質問見出し質問見出し</dt>
		<dd class="faq_a acd_panel">
			<p>
				質問回答テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
				質問回答テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
			</p>
		</dd><!-- /.faq_a -->
	</dl><!-- /.faq_item -->
	<dl class="faq_item">
		<dt class="faq_q acd_btn">質問見出し質問見出し質問見出し質問見出し質問見出し</dt>
		<dd class="faq_a acd_panel">
			<p>
				質問回答テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
				質問回答テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
			</p>
		</dd><!-- /.faq_a -->
	</dl><!-- /.faq_item -->
	<dl class="faq_item">
		<dt class="faq_q acd_btn">質問見出し質問見出し質問見出し質問見出し質問見出し</dt>
		<dd class="faq_a acd_panel">
			<p>
				質問回答テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
				質問回答テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト<br>
			</p>
		</dd><!-- /.faq_a -->
	</dl><!-- /.faq_item -->
</article><!-- /.faq -->

Styleguide 4.15
============================================================*/
.faq {
	margin-top: 27px;
	&_item {
		margin: 3px 0;
	}
	&_q ,
	&_a {
		position: relative;
		&:before {
			position: absolute;
			left: 0;
			display: inline-block;
			vertical-align: middle;
			width: 34px;
			height: 34px;
			line-height: 34px;
			margin-right: 0;
			color: $mainColor02;
			font-size: 22px;
			font-family: Arial;
			text-align: center;
			-webkit-border-radius: 3px;
			        border-radius: 3px;
		}
	}
	&_q {
		padding: 16px 20px 16px 52px;
		color: #333;
		font-size: 16px;
		font-weight: bold;
		border-bottom: 1px solid $mainColor08;
		cursor: pointer;
		&:before {
			top: 8px;
			content: 'Q';
			border: 1px solid #D2E9F2;
		}
	}
	&_a {
		margin-bottom: 20px;
		padding: 22px 20px 22px 52px;
		color: inherit;
		&:before {
			top: 15px;
			content: 'A';
			background-color: #E3F2F7;
		}
		p {
			margin-top: 0;
			font-size: 14px;
		}
		.imgTxt {
			&:first-child {
				margin-top: 0;
			}
			&_img {
				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.faq {
		&_q ,
		&_a {
			&:before {
				width: 30px;
				height: 30px;
				line-height: 30px;
				font-size: 20px;
			}
		}
		&_q {
			padding: 12px 20px 16px 42px;
			font-size: 14px;
		}
		&_a {
			padding: 20px 20px 10px 42px;
		}
	}
}

/*
検索ボックス

searchFilter

Markup:


Styleguide 4.16
============================================================*/
.searchFilter {
$this: &;
	background-color: $mainColor06;
	&_inner {
		padding: 24px 28px;
	}
	&_txt {
		&:first-child {
			margin-top: 0;
		}
	}
	&_body {
		padding: 0 40px;
		&:before,
		&:after {
			content: ".";
			display: block;
			height: 0;
			visibility: hidden;
		}
		&:after {
			clear: both;
		}
		// &_block {
		// 	@include inline_block-p;
		// 	float: left;
		// 	&:before,
		// 	&:after {
		// 		content: ".";
		// 		display: block;
		// 		height: 0;
		// 		visibility: hidden;
		// 	}
		// 	&:after {
		// 		clear: both;
		// 	}
		// }
	}
	&_block {
		display: table;
		table-layout: fixed;
		width: 100%;
	}
	&_th ,
	&_td {
		display: table-cell;
		vertical-align: middle;
	}
	&_th {
		width: 5em;
		padding-left: 2em;
		font-weight: bold;
		&:first-child {
			padding-left: 0;
		}
	}
	&_td {
	}
	.btnGroup {
		margin-top: 30px;
	}
	.btn-searchFilter {
		padding: 13px 30px;
		width: 314px;
	}
	&-en ,
	&-cn {
		#{$this}_block ,
		#{$this}_th ,
		#{$this}_td {
			display: block;
		}
		#{$this}_th {
			margin-top: 1em;
			padding-left: 0;
			width: auto;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.searchFilter {
	$this: &;
		&_inner {
			padding: 15px;
		}
		&_body {
			padding: 0;
		}
		&_block ,
		&_th ,
		&_td {
			display: block;
		}
		&_th {
			width: auto;
			margin-top: 1em;
			padding-left: 0;
		}
		.btnGroup {
			margin-top: 20px;
		}
		.btn-searchFilter {
			width: 80%;
		}
		&-en {
		}
		&-cn {
		}
	}
}

/*
カラム

column

Markup:
<div class="column column-2">
	<div class="column_cel">
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
	</div><!-- /.column_cel -->
	<div class="column_cel">
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
	</div><!-- /.column_cel -->
</div><!-- /.column -->

Styleguide 4.17
============================================================*/
.column {
$this: &;
	@include inline_block-p;
	margin: -20px 0 0 -40px;
	&_cel {
		@include inline_block;
		margin: 20px 0 0;
		padding: 0 0 0 40px;
	}
	&-2 {
		#{$this}_cel {
			width: 50%;
		}
	}
	&-3 {
		#{$this}_cel {
			width: 33.333333333%;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.column {
	$this: &;
		margin: 0;
		&_cel {
			display: block;
			margin: 20px 0 0;
			padding: 0;
		}
		&-2 {
			#{$this}_cel {
				width: 100%;
			}
		}
	}
}

/*
サイトマップ

sitemap

Markup:
<div class="sitemap">
	<nav class="sitemap_nav">
		<div class="sitemap_set sitemap_set-full" data-mh="sitemap_set">
			<p class="sitemap_cate"><a href="../">日信化学工業株式会社　HOME</a></p>
		</div>
		<dl class="sitemap_set" data-mh="sitemap_set">
			<dt class="sitemap_cate02"><span>製品情報</span></dt>
			<dd class="sitemap_item"><a href="<%= siteRoot %>products/">製品情報トップ</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>products/search-product.html">製品名から探す</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>products/search-application.html">用途から探す</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>products/search-feature.html">機能から探す</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>products/emulsion.html">エマルジョンとは</a></dd>
		</dl><!-- /.sitemap_set -->
		<dl class="sitemap_set" data-mh="sitemap_set">
			<dt class="sitemap_cate02"><span>会社案内</span></dt>
			<dd class="sitemap_item"><a href="<%= siteRoot %>company/">会社案内トップ</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>company/overview.html">会社概要</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>company/csr.html">CSR活動</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>company/movie.html">ムービー</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>company/information.html">日信化学ってどんな会社？</a></dd>
		</dl><!-- /.sitemap_set -->
		<dl class="sitemap_set" data-mh="sitemap_set">
			<dt class="sitemap_cate02"><span>サポート</span></dt>
			<dd class="sitemap_item"><a href="<%= siteRoot %>support/">サポートトップ</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>support/faq.html">よくある質問</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>support/sds-download.html">SDSダウンロード</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>support/catalog-download.html">カタログダウンロード</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>support/contact_product.html">製品に関するお問い合わせ</a></dd>
		</dl><!-- /.sitemap_set -->
		<dl class="sitemap_set" data-mh="sitemap_set">
			<dt class="sitemap_cate02"><span>採用情報</span></dt>
			<dd class="sitemap_item"><a href="<%= siteRoot %>recruit/">採用情報トップ</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>recruit/job-category.html">採用情報</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>recruit/voice.html">先輩の声</a></dd>
		</dl><!-- /.sitemap_set -->
		<dl class="sitemap_set" data-mh="sitemap_set">
			<dt class="sitemap_cate02"><span>お問い合わせ</span></dt>
			<dd class="sitemap_item"><a href="<%= siteRoot %>form/">お問い合わせトップ</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>form/contact.html">お問い合わせフォーム</a></dd>
			<dd class="sitemap_item"><a href="<%= siteRoot %>support/contact_sds.html">カタログ/SDSのご請求</a></dd>
		</dl><!-- /.sitemap_set -->
		<ul class="sitemap_set sitemap_set-full" data-mh="sitemap_set">
			<li class="sitemap_cate sitemap_cate-sub"><a href="<%= siteRoot %>news"><span>ニュース</span></a></li>
			<li class="sitemap_cate sitemap_cate-sub"><a href="<%= siteRoot %>privacy"><span>個人情報保護ポリシー</span></a></li>
			<li class="sitemap_cate sitemap_cate-sub"><a href="<%= siteRoot %>sitemap"><span>サイトマップ</span></a></li>
			<li class="sitemap_cate sitemap_cate-sub"><a href="">English</a></li>
			<li class="sitemap_cate sitemap_cate-sub"><a href="">Chinese</a></li>
		</ul><!-- /.sitemap_set -->
	</nav><!-- /.sitemap_nav -->
</div><!-- /.sitemap -->

Styleguide 4.18
============================================================*/
.sitemap {
	$this: &;
	$space: 45px;
	position: relative;
	margin: 27px 0 0;
	&_nav {
	}

	// 横２列並べ
	&_block {
		@include inline_block-p;
		margin: -#{$space} 0 0 -#{$space};
		&-full {
			margin: #{$space} 0;
			#{$this}_blockItem {
				display: block;
				margin: #{$space} 0 0;
				width: 100%;
				width: 100%\9;
				&:first-child {
					margin-top: 0;
				}
			}
		}
	}
	&_blockItem {
		@include inline_block;
		vertical-align: top;
		margin: #{$space} 0 0 #{$space};
		width: calc( ( 100% - #{$space} * 2 ) / 2);
		width: calc( ( 100% - 4px - #{$space} * 2 ) / 2)\9;
	}

	&_title {
		margin: 5px 0 0;
		padding: .5em .5em .2em;
		background-color: $mainColor05;
		&:before {
			font: normal normal normal 14px/1 FontAwesome;
			font-size: inherit;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: '\f138';
			color: $mainColor01;
			display: inline-block;
			margin: 0 5px 0 0;
		}
		> a ,
		> span {
			color: inherit;
			text-decoration: none;
			font-size: 16px;
			font-weight: bold;
		}
		> a {
			&:hover {
				text-decoration: underline;
			}
		}
		&-sub:before {
			font: normal normal normal 14px/1 FontAwesome;
			font-size: inherit;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: '\f101';
			margin: 0 .5em 0 0;
		}
	}
	&_title02 {
		margin: 5px 0 0;
		&:before {
			font: normal normal normal 14px/1 FontAwesome;
			font-size: inherit;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: '\f138';
			color: $mainColor01;
			display: inline-block;
			margin: 0 5px 0 0;
		}
		> a ,
		> span {
			color: inherit;
			font-size: 15px;
			text-decoration: none;
		}
		> a {
			&:hover {
				text-decoration: underline;
			}
		}
		&-sub:before {
			font: normal normal normal 14px/1 FontAwesome;
			font-size: inherit;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: '\f101';
			margin: 0 .5em 0 0;
		}
	}
	&_title03 {
		margin: 5px 0;
		padding: .5em .5em .2em;
		border-bottom: 1px solid $mainColor08;
		background-color: $mainColor06;
		&:before {
			font: normal normal normal 14px/1 FontAwesome;
			font-size: inherit;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: '\f13a';
			color: $mainColor01;
			display: inline-block;
			margin: 0 5px 0 0;
		}
		> a ,
		> span {
			color: inherit;
			font-size: 14px;
			font-weight: bold;
			text-decoration: none;
		}
		> a {
			&:hover {
				text-decoration: underline;
			}
		}
	}

	&_list {
		margin: 0;
	}
	&_item {
		margin: 8px 0 0 22px;
		> a {
			text-decoration: none;
			color: inherit;
			&:before {
				font: normal normal normal 14px/1 FontAwesome;
				font-size: inherit;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				content: '\f101';
				color: $mainColor04;
				display: inline-block;
				margin: 0 5px 0 0;
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.sitemap {
		$this: &;
		$space: 40px;

		// 横２列並べ
		&_block {
			margin: #{$space} 0 0;
			&-full {
				margin: #{$space} 0 0;
			}
		}
		&_blockItem {
			display: block;
			margin: #{$space} 0 0;
			width: 100%;
			width: 100%\9;
			&:first-child {
				margin-top: 0;
			}
		}

		&_title {
			padding: .3em .3em .2em;
		}

		&_list {
			margin: 0;
		}
		&_item {
			margin: 8px 0 0 10px;
			padding: 1px 0;
			> a {
				padding: .1em 0px;
				font-size: 13px;
			}
		}
	}
}

/*
フォームエリア

form

Markup:
<form action="" method="post">

</form>

Styleguide 4.19
============================================================*/
.form {
	margin-top: 27px;
	&_privacy ,
	&_privacyCheck {
		margin-top: 30px;
		text-align: center;
	}
	&_privacy {
		margin-top: 30px;
		text-align: center;
		&Txt {
		}
	}
	&_button {
	}
	&Table {
		width: 100%;
		table-layout: fixed;
		th ,
		td {
			padding: 16px 50px 16px 22px;
			vertical-align: middle;
			border: 1px solid $mainColor08;
			> p {
				margin: 0;
			}
		}
		th {
			width: 17em;
			background-color: $mainColor07;
		}
		td {
		}
		&_must {
			position: relative;
			&:before {
				position: absolute;
				content: '必須';
				right: 15px;
				top: 50%;
				display: inline-block;
				margin-top: -10px;
				padding: 4px 7px;
				line-height: 1;
				vertical-align: middle;
				color: #fff;
				font-size: 12px;
				text-align: center;
				background-color: #B7335E;
				-webkit-border-radius: 3px;
				        border-radius: 3px;
			}
			&-en ,
			&-cn {
				&:before {
					content: '※';
				}
			}
		}
		&_elem {
			//横並び
			padding-top: 8px;
			&:first-child {
				padding-top: 0;
			}
			&-column {
				display: inline-block;
				padding: 0 15px 0 0;
			}
		}
	}
	//郵便番号検索用ボタン
	.btn-zipSearch {
		margin-left: 15px;
		.btn {
			position: relative;
			padding: 0 0 0 10px;
			width: 97px;
			height: 30px;
			line-height: 33px;
			color: $mainColor02;
			font-size: 14px;
			border: none;
			background: $mainColor05 url('./../images/bullets/icon_arrow03.png') 20px center no-repeat;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.form {
		&_privacy ,
		&_privacyCheck {
			margin-top: 20px;
		}
		&_privacy {
			margin-top: 20px;
		}
		&_button {
			padding: 0 20px;
			.btn {
				width: 100%;
			}
		}
		&Table {
			display: block;
			width: 100%;
			tbody ,
			tr ,
			th ,
			td {
				display: block;
				width: 100%;
			}
			th ,
			td {
				padding: 8px;
				> p {
					margin: 0;
				}
			}
			th {
				padding: 8px;
			}
			td {
				padding: 8px;
			}
		}
		.btn-zipSearch {
			margin-left: 10px;
			.btn {
				width: 67px;
				background-position: 10px center;
			}
		}
	}
}

/*
括弧付の数字リスト

list_brackets

Markup:
<ol class="list_brackets">
	<li>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</li>
	<li>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</li>
	<li>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</li>
</ol><!-- /.list_brackets -->

Styleguide 4.20
============================================================*/
.list_brackets{
	margin: 20px 0;
	&:first-child {
		margin-top: 0;
	}
	> li{
		display: block;
		margin: 5px 0 0;
		padding: 0 0 0 1.8em;
		text-indent: -1.8em;
		list-style-type: none;
		list-style-position: inside;
		counter-increment: cnt;
		&:before{
			display: marker;
			content: "(" counter(cnt) ") ";
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
}


/*
グリッド

grid

Markup:
<!-- 2カラム　レイアウト -->
<div class="grid">
	<div class="grid_col grid_col-6">col</div>
	<div class="grid_col grid_col-6">col</div>
</div><!-- /.grid -->
<!-- 3カラム -->
<div class="grid">
	<div class="grid_col grid_col-4">col</div>
	<div class="grid_col grid_col-4">col</div>
	<div class="grid_col grid_col-4">col</div>
</div><!-- /.grid -->
<!-- 4カラム　レイアウト -->
<div class="grid">
	<div class="grid_col grid_col-3">col</div>
	<div class="grid_col grid_col-3">col</div>
	<div class="grid_col grid_col-3">col</div>
	<div class="grid_col grid_col-3">col</div>
</div><!-- /.grid -->
<!-- マージンあり -->
<div class="grid grid-gutters">
	<div class="grid_col grid_col-3">col</div>
	<div class="grid_col grid_col-3">col</div>
	<div class="grid_col grid_col-3">col</div>
	<div class="grid_col grid_col-3">col</div>
</div><!-- /.grid -->

.grid-gutters

Styleguide 4.21
============================================================*/
.grid {
	width: $var-max-width;
	margin: 0 auto;
	letter-spacing: -0.35em;
	&-right{
		text-align: right;
	}
	&-center{
		text-align: center;
	}
	&-justify {
		display: block;
		text-align: justify;
		text-justify: distribute-all-lines;
		&:after{
			content: '';
			display: inline-block;
			width: $var-max-width;
		}
	}
	&-middle &_col{
		vertical-align: middle;
	}
	&-top &_col{
		vertical-align: top;
	}
	&-bottom &_col{
		vertical-align: bottom;
	}
	&_col{
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		display: block;
		width: $var-max-width;
		vertical-align: top;
		letter-spacing: normal;
		word-spacing: normal;
		word-wrap: break-word;
		&-middle{
			vertical-align: middle;
		}
		&-top{
			vertical-align: top;
		}
		&-bottom{
			vertical-align: bottom;
		}
	}
}
@media screen and (min-width: $breakpoint-sp) {
	.grid{
		&-gutters &_col{
			margin-left: $var-col-gutters;
			&:first-child{
				margin-left: 0;
			}
		}
		&_col{
			display: inline-block;
			width: auto;
			@for $i from 1 through $var-col{
				&-#{$i}{
					width: $var-col-width * $i;
				}
			}
		}
		&_offset{
			@for $i from 1 through $var-col{
				&-#{$i}{
					margin-right: $var-col-width * $i;
				}
			}
		}
		&-gutters &_col{
			@for $i from 1 through $var-col - 1{
				&-#{$i}{
					width: $var-col-width-gutters * $i - $var-col-gutters;
				}
			}
			&-#{$var-col}{
				width: $var-max-width;
			}
		}
		&-gutters &_offset{
			@for $i from 1 through $var-col - 1{
				&-#{$i}{
					margin-right: $var-col-width-gutters * $i;
				}
			}
			&-#{$var-col}{
				margin-right: $var-max-width;
			}
		}
	}
}

/*
サムネイルリスト

thumbnails

Markup:
<ul class="thumbnails">
	<li class="thumbnails_item"><img src="http://placehold.it/100x80/888888/ffffff?text=!!!Dummy+Image!!!" alt="ダミーイメージ"></li>
	<li class="thumbnails_item"><img src="http://placehold.it/100x80/336699/ffffff?text=!!!Dummy+Image!!!" alt="ダミーイメージ"></li>
	<li class="thumbnails_item"><img src="http://placehold.it/100x80/225588/ffffff?text=!!!Dummy+Image!!!" alt="ダミーイメージ"></li>
	<li class="thumbnails_item"><img src="http://placehold.it/100x80/CCDD99/ffffff?text=!!!Dummy+Image!!!" alt="ダミーイメージ"></li>
	<li class="thumbnails_item"><img src="http://placehold.it/100x80/99DDFF/ffffff?text=!!!Dummy+Image!!!" alt="ダミーイメージ"></li>
</ul><!-- /.thumbnails -->

Styleguide 4.22
============================================================*/
.thumbnails {
	@include inline_block-p;
	margin: 0 0 0 -10px;
	overflow: hidden;
	// text-align: center;
	&_item {
		@include inline_block;
		margin: 10px;
		> figure ,
		> p {
			margin-top: 0;
			display: inline-block;
		}
		a {
			display: inline-block;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.thumbnails {
		margin: -10px 0 0 -10px;
		&_item {
			margin: 10px 0 0 10px;
		}
	}
}

/*
問い合わせ一覧

inquiryList

Markup:
<div class="inquiryList">
	<dl class="inquiryList_block">
		<dt class="inquiryList_th">営業本部</dt>
		<dd class="inquiryList_td">03-0000-0000</dd>
	</dl>
	<dl class="inquiryList_block">
		<dt class="inquiryList_th">企画本部</dt>
		<dd class="inquiryList_td">03-0000-0000</dd>
	</dl>
	<dl class="inquiryList_block">
		<dt class="inquiryList_th">制作本部</dt>
		<dd class="inquiryList_td">03-0000-0000</dd>
	</dl>
</div>

Styleguide 4.23
============================================================*/
.inquiryList {
	&_block {
		margin-top: 10px;
		&:first-child {
			margin: 0;
		}
		&:before,
		&:after {
			content: ".";
			display: block;
			height: 0;
			visibility: hidden;
		}
		&:after {
			clear: both;
		}
	}
	&_th {
		position: relative;
		float: left;
		padding: 0 10px 0 20px;
		width: 10em;
		font-weight: bold;
		&:before {
			display: inline-block;
			position: absolute;
			top: -2px;
			left: 2px;
			content: "\f098";
			font-family: FontAwesome;
			color: $mainColor04;
			font-size: inherit;
		}
	}
	&_td {
		overflow: hidden;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.inquiryList {
		&_th {
			float: none;
			padding: 0 0 0 20px;
			width: auto;
		}
		&_td {
			padding: 0 0 0 20px;
		}
	}
}

/*
infoGuide

infoGuide

Markup:
<div class="infoGuide">
	<div class="infoGuide_inner">
		<figure class="infoGuide_imgMaps"><img src="<%= siteRoot %>assets/images/shared/company/information/img_illust-map.png" class="" data-useMap="" usemap="#ImageMap"></figure>
		<map name="ImageMap">
			<area shape="rect" coords="439,29,623,104" data-remodal-target="js-rM-clipMap01" href="javascript:;" alt="オフィス" title="オフィス" />
			<area shape="rect" coords="179,215,359,291" data-remodal-target="js-rM-clipMap01" href="javascript:;" alt="家" title="家" />
			<area shape="rect" coords="644,223,826,303" data-remodal-target="js-rM-clipMap01" href="javascript:;" alt="スーパー" title="スーパー" />
			<area shape="rect" coords="356,475,533,558" data-remodal-target="js-rM-clipMap01" href="javascript:;" alt="車" title="車" />
		</map>
	</div>
</div><!-- /.infoGuide -->

Styleguide 4.24
============================================================*/
.infoGuide {
	$this: &;
	$imgs: './../images/shared/company/information/img_illust-map-off.png';
	$width: image-width($imgs);
	$height: image-height($imgs);

	position: relative;
	margin: 25px auto 0;
	&_inner {
		position: relative;
		width: calc( #{$width} );
		height: calc( #{$height} );
	}
	&_imgMaps {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
	}

	&_popPos {
		position: absolute;
		margin: 0;
		&-01 {
			top: 205px;
			left: 192px;
		}
		&-02 {
			top: 20px;
			left: 435px;
		}
		&-03 {
			bottom: 45px;
			left: 340px;
		}
		&-04 {
			top: 215px;
			right: 40px;
		}
	}

	&_popTitle {
		position: relative;
		display: inline-block;
		min-width: 160px;
		text-align: center;
		color: $mainColor11;
		font-size: 18px;
		-webkit-border-radius: 6px;
		        border-radius: 6px;
		-webkit-box-shadow: 2px 2px 4px $gray;
		-moz-box-shadow: 2px 2px 4px $gray;
		box-shadow: 2px 2px 4px $gray;
		background-color: #fff;
		border: 3px solid $mainColor11;
		text-decoration: none;
		// animation-iteration-count: infinite;
		cursor: pointer;
		&::before ,
		&::after {
			content: ' ';
			position: absolute;
			width: 0;
			height: 0;
		}
		&::before {
			left: 30px;
		}
		&::after {
			left: 34px;
		}
		> span {
			position: relative;
			display: block;
			width: 100%;
			padding: 8px 10px 6px;
			overflow: hidden;
			> i {
				position: absolute;
				top: 3px;
				left: -58px;
				display: inline-block;
				-webkit-transform: translateY(0) rotate(-23deg);
					-ms-transform: translateY(0) rotate(-23deg);
						transform: translateY(0) rotate(-23deg);
				z-index: 50;
				display: none;
				&::before {
					display: block;
					content: "Click！";
					width: 100%;
					padding: 12px 40px 0 29px;
					color: #FFF;
					font-size: 10px;
					font-weight: bold;
					font-style: normal;
					background-color: #f00;
					-webkit-border-radius: 5px;
					        border-radius: 5px;
				}
			}
		}

		&:hover {
			opacity: 1 !important;
			color: $mainColor11;
			background: $yellow-light;
			animation-iteration-count: 3; //繰返し infinite
			> span {
				> i {
					display: inline-block;
				}
			}
		}

		// 吹き出しの向き
		&-dirTop {
			&::before ,
			&::after {
				bottom: -20px;
				border: 10px solid;
			}
			&::before {
				border-color: $mainColor11 transparent transparent $mainColor11;
			}
			&::after {
				bottom: -11px;
				border-color: #fff transparent transparent #fff;
			}

			&:hover {
				&::after {
					border-color: $yellow-light transparent transparent $yellow-light;
				}
			}
		}
		&-dirBtm {
			&::before ,
			&::after {
				top: -20px;
				border: 10px solid;
			}
			&::before {
				border-color: transparent $mainColor11 $mainColor11 transparent;
			}
			&::after {
				top: -13px;
				left: 27px;
				border-color: transparent #fff #fff transparent;
			}

			&:hover {
				&::after {
					border-color: transparent $yellow-light $yellow-light transparent;
				}
			}
		}
	}
}
.fuwafuwa {
	-webkit-animation-name:fuwafuwa; /* fuwafuwaっていうアニメーションをしてね！ */
	-webkit-animation-duration:2s;
	-webkit-animation-iteration-count:infinite;
	/*-webkit-animation-direction:alternate;*/
	-webkit-animation-timing-function:ease;

	-moz-animation-name:fuwafuwa;
	-moz-animation-duration:2s;
	-moz-animation-iteration-count:infinite;
	/*-moz-animation-direction:alternate;*/
	-moz-animation-timing-function:ease;
}
/* fuwafuwaっていうアニメーションはこんなふうだよ！ */
@-webkit-keyframes fuwafuwa {
	0% {-webkit-transform:translate(0, 0);}
	50% {-webkit-transform:translate(0, -10px);}
	100% {-webkit-transform:translate(0, 0);}
}
@-moz-keyframes fuwafuwa {
	0% {-moz-transform:translate(0, 0);}
	50% {-moz-transform:translate(0, -10px);}
	100% {-moz-transform:translate(0, 0);}
}


#ex2 {
    animation: anime 0.5s ease 0s infinite alternate;
    -webkit-animation: anime 0.5s ease 0s infinite alternate;
}

@keyframes anime {
    0% {
        width: 50px;
    }
    100% {
        width: 150px;
    }
}

@-webkit-keyframes anime {
    0% {
        width: 50px;
    }
    100% {
        width: 150px;
    }
}


@media screen and (max-width: $breakpoint-sp) {
	.infoGuide {
		&_inner {
			width: auto;
			height: auto;
		}
		&_imgMaps {
			position: relative;
			top: auto;
			left: auto;
		}
	}
}

/*
infoImgGuide

infoImgGuide

Markup:
<div class="infoImgGuide">
	<div class="infoImgGuide_inner">
		<div class="infoImgGuide_thumbs">
			<figure class="infoImgGuide_imgs">
				<img class="infoImgGuide_img" src="<%= siteRoot %>assets/images/shared/company/information/img_illust-pickup-office-on.png" alt="">
			</figure>
		</div>
		<div class="infoImgGuide_body">
			<div class="infoImgGuide_bodyInner balloonTipWrap">
				<div class="infoImgGuide_tipPos infoImgGuide_tipPos-01">
					<p class="infoImgGuide_tipHover balloonBtn"><span title="Hover"></span></p>
					<div class="infoImgGuide_popBox balloonTip">
						<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
					</div>
				</div>
				<div class="infoImgGuide_tipPos infoImgGuide_tipPos-02">
					<p class="infoImgGuide_tipHover balloonBtn"><span title="Hover"></span></p>
					<div class="infoImgGuide_popBox infoImgGuide_popBox-bottom balloonTip">
						<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
					</div>
				</div>
				<div class="infoImgGuide_tipPos infoImgGuide_tipPos-03">
					<p class="infoImgGuide_tipHover balloonBtn"><span title="Hover"></span></p>
					<div class="infoImgGuide_popBox infoImgGuide_popBox-bottom balloonTip">
						<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
					</div>
				</div>
				<div class="infoImgGuide_tipPos infoImgGuide_tipPos-04">
					<p class="infoImgGuide_tipHover balloonBtn"><span title="Hover"></span></p>
					<div class="infoImgGuide_popBox balloonTip">
						<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div><!-- /.infoImgGuide -->

Styleguide 4.25
============================================================*/
.infoImgGuide {
	$this: &;
	$imgs: './../images/shared/company/information/img_illust-pickup-car-off.png';
	$width: image-width($imgs);
	$height: image-height($imgs);
	$widthNum: 1000px;

	position: relative;
	margin: 25px auto 0;
	&_inner {
		position: relative;
		background-color: #ccc;
	}
	&_thumbs {
	}
	&_imgs {
		margin: 0;
	}
	&_img {
	}
	&_body {
		position: absolute;
		top: 0;
		left: 0;
		width: calc( #{$width} );
		height: calc( #{$height} );
		// &::before {
		//	position: absolute;
		//	width: 100%;
		//	height: 100%;
		//	content: "";
		//	background-color: rgba(123,255,250,.5);
		//	z-index: 1;
		// }
	}

	&_tipPos {
		position: absolute;
		// top: 0;
		// left: 0;
		// width: 100%;
		// height: 100%;
		// background-color: rgba(#ccc, .5);
		// background: rgba(yellow, .5);
		width: 230px;
	}

	&_tipHover {
		$size: 30px;
		position: absolute;
		display: block;
		margin: 0;
		width: #{$size};
		height: #{$size};
		border-radius: 100px;
		background: $mainColor10;
		// background: rgba($mainColor10, .3);
		border: 1px solid #fff;
		cursor: pointer;
		z-index: 100;
		> span {
			background: rgba($lightenColor01, .5);
			position: absolute;
			z-index:-1;
			border-radius: 100px;
			height: 58px;
			width: 58px;
			top: calc( -#{$size} / 2 );
			left: calc( -#{$size} / 2 );
		}
		&:hover {
			> span {
				animation: pulsate 1s ease-out;
				animation-iteration-count: infinite;
			}
		}

		// &-test {
		//	margin-top: floor((( 387px / $widthNum ) * 10000% )) / 100;
		//	left: floor((( 385px / $widthNum ) * 10000% )) / 100;
		// }
	}
	&_popBox {
		position: absolute;
		z-index: 90;
		width: 100%;
		height: 100%;
		& > * {
			width: 225px;
		}
		&::before {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			right: 0;
			content: "";
			width: 50%;
			height: 3px;
			margin: 0 5px 0;
			background-color: $mainColor10;
		}

		&-top ,
		&-bottom {
			&::before {
				top: 8px;
				left: 50%;
				margin-left: -4px;
				bottom: 0;
				margin-top: 0;
				width: 3px;
				height: 50%;
			}
		}
		&-top {
			&::before {
				top: auto;
			}
		}
		&-bottom {
			&::before {
				bottom: auto;
			}
		}
	}

	// each type
	&-house {
		// display: none;
		#{$this}_tipPos {
			&-01 {
				width: 265px;
				min-height: 146px;
				margin-top: floor((( 300px / $widthNum ) * 10000% )) / 100;
				left: floor((( 20px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: 59px;
					left: auto;
					right: -8px;
				}
				#{$this}_popBox {
					&::before {
						top: 73px;
						left: auto;
						right: 0;
						width: 50%;
					}
				}
			}
			&-02{
				min-height: 160px;
				margin-top: floor((( 510px / $widthNum ) * 10000% )) / 100;
				left: floor((( 116px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: -6px;
					left: auto;
					right: 30px;
				}
				#{$this}_popBox {
					padding-top: 28px;
					&::before {
						left: auto;
						right: 38px;
					}
				}
			}
			&-03 {
				min-height: 180px;
				margin-top: floor((( 484px / $widthNum ) * 10000% )) / 100;
				left: floor((( 416px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: -6px;
					left: 6px;
				}
				#{$this}_popBox {
					padding-top: 54px;
					&::before {
						left: 24px;
					}
				}
			}
			&-04 {
				min-height: 180px;
				margin-top: floor((( 480px / $widthNum ) * 10000% )) / 100;
				left: floor((( 737px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: -6px;
					left: 20px;
				}
				#{$this}_popBox {
					padding-top: 56px;
					&::before {
						left: 38px;
					}
				}
			}
			&-05 {
				min-height: 206px;
				margin-top: floor((( 20px / $widthNum ) * 10000% )) / 100;
				left: auto;
				right: floor((( 20px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: auto;
					bottom: -4px;
					left: 18px;
				}
				#{$this}_popBox {
					padding-left: 5px;
					&::before {
						left: 36px;
						top: auto;
						bottom: 10px;
					}
				}
			}
		}
	}

	&-office {
		// display: none;
		#{$this}_tipPos {
			&-01 {
				width: 386px;
				min-height: 146px;
				margin-top: floor((( 337px / $widthNum ) * 10000% )) / 100;
				left: floor((( 20px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: 47px;
					left: auto;
					right: -7px;
				}
				#{$this}_popBox {
					width: 376px;
					&::before {
						top: 62px;
						left: auto;
						right: -4px;
					}
				}
			}
			&-02{
				min-height: 245px;
				margin-top: floor((( 370px / $widthNum ) * 10000% )) / 100;
				left: floor((( 315px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: -4px;
					left: auto;
					right: 27px;
				}
				#{$this}_popBox {
					padding-top: 110px;
					&::before {
						left: auto;
						right: 36px;
					}
				}
			}
			&-03 {
				min-height: 300px;
				margin-top: floor((( 380px / $widthNum ) * 10000% )) / 100;
				left: floor((( 587px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: 0px;
					left: 1px;
				}
				#{$this}_popBox {
					padding-top: 103px;
					&::before {
						top: 10px;
						left: 18px;
					}
				}
			}
			&-04 {
				width: 255px;
				min-height: 146px;
				margin-top: floor((( 180px / $widthNum ) * 10000% )) / 100;
				left: floor((( 720px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: 90px;
					left: -10px;
				}
				#{$this}_popBox {
					padding-left: 35px;
					&::before {
						left: 5px;
						top: 104px;
					}
				}
			}
		}
	}

	&-car {
		// display: none;
		#{$this}_tipPos {
			&-01 {
				min-height: 190px;
				margin-top: floor((( 83px / $widthNum ) * 10000% )) / 100;
				left: floor((( 272px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: auto;
					bottom: -4px;
					left: 81px;
				}
				#{$this}_popBox {
					&::before {
						left: 98px;
						top: auto;
						bottom: 10px;
					}
				}
			}
			&-02 {
				min-height: 235px;
				margin-top: floor((( 80px / $widthNum ) * 10000% )) / 100;
				left: floor((( 610px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: auto;
					bottom: -9px;
					left: 16px;
				}
				#{$this}_popBox {
					&::before {
						left: 32px;
						top: auto;
						bottom: 10px;
					}
				}
			}
			&-03 {
				min-height: 240px;
				margin-top: floor((( 390px / $widthNum ) * 10000% )) / 100;
				left: floor((( 304px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: -10px;
					left: auto;
					right: 2px;
				}
				#{$this}_popBox {
					padding-top: 108px;
					&::before {
						top: 5px;
						left: auto;
						right: 10px;
					}
				}
			}
			&-04{
				min-height: 230px;
				margin-top: floor((( 360px / $widthNum ) * 10000% )) / 100;
				left: floor((( 594px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: -10px;
					left: 54px;
				}
				#{$this}_popBox {
					padding-top: 137px;
					&::before {
						top: 5px;
						left: 72px;
						height: 80%;
					}
				}
			}
		}
	}

	&-market {
		// display: none;
		#{$this}_tipPos {
			&-01 {
				width: 320px;
				min-height: 130px;
				margin-top: floor((( 390px / $widthNum ) * 10000% )) / 100;
				left: floor((( 10px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: auto;
					bottom: 2px;
					left: auto;
					right: -3px;
				}
				#{$this}_popBox {
					&::before {
						top: auto;
						bottom: 16px;
						left: auto;
						right: 6px;
					}
					& > * {
						width: 230px;
					}
				}
			}
			&-02 {
				width: 280px;
				min-height: 130px;
				margin-top: floor((( 540px / $widthNum ) * 10000% )) / 100;
				left: floor((( 10px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: 0px;
					left: auto;
					right: -3px;
				}
				#{$this}_popBox {
					&::before {
						top: 13px;
						left: auto;
						right: 6px;
					}
					& > * {
						width: 230px;
					}
				}
			}
			&-03 {
				min-height: 210px;
				margin-top: floor((( 114px / $widthNum ) * 10000% )) / 100;
				left: floor((( 227px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: auto;
					bottom: -8px;
					left: auto;
					right: 38px;
				}
				#{$this}_popBox {
					&::before {
						top: auto;
						bottom: 12px;
						left: auto;
						right: 49px;
					}
				}
			}
			&-04 {
				min-height: 225px;
				margin-top: floor((( 30px / $widthNum ) * 10000% )) / 100;
				left: floor((( 613px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: auto;
					bottom: -6px;
					left: 92px;
				}
				#{$this}_popBox {
					&::before {
						left: 110px;
						top: auto;
						bottom: 10px;
					}
				}
			}
			&-05 {
				width: 275px;
				min-height: 146px;
				margin-top: floor((( 240px / $widthNum ) * 10000% )) / 100;
				left: auto;
				right: floor((( 10px / $widthNum ) * 10000% )) / 100;
				#{$this}_tipHover {
					top: 61px;
					left: -6px;
				}
				#{$this}_popBox {
					padding-left: 50px;
					&::before {
						left: 5px;
						top: 74px;
					}
				}
			}
		}
	}
}
@keyframes pulsate {
    0%   { transform: scale(1, 1); }
    50%  { opacity: 1; }
    100% { transform: scale(2, 2); opacity: 0.0; }
}
@media screen and (max-width: $breakpoint-sp) {
}
// IE対応用
@media all and (-ms-high-contrast: none) {
	.infoImgGuide {
		&_tipHover {
			&:hover {
				> span {
					animation-iteration-count: 3; //繰返し infinite
				}
			}
		}
	}
}
.infoImgGuide {
	&_tipHover {
		&:hover {
			> span {
				animation-iteration-count: 3\9;
			}
		}
	}
}

/*
infoImgGuidePop

infoImgGuidePop

Markup:
<div class="infoImgGuidePop">
	<div class="infoImgGuidePop_inner">
		<div class="infoImgGuidePop_heading">
			<p class="infoImgGuidePop_labels">
				<span class="infoImgGuidePop_label"><i>ラベル</i></span>
			</p>
			<p class="infoImgGuidePop_title">タイトル</p>
		</div>
		<div class="infoImgGuidePop_body">
			<p class="infoImgGuidePop_txt">テキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
		</div>
	</div>
</div><!-- /.infoImgGuidePop -->

Styleguide 4.26
============================================================*/
.infoImgGuidePop {
	$this: &;
	position: relative;
	margin: 0;
	// max-width: 225px;
	background-color: #fff;
	border: 2px solid $mainColor10;
	&_inner {
		padding: 10px;
	}
	&_heading {
	}
	&_labels {
		margin: 0;
	}
	&_label {
		padding: 2px 14px 0;
		color: #fff;
		font-size: 11px;
		line-height: 1.2;
		background-color: $mainColor10;
	}
	&_title {
		margin-top: 7px;
		color: $mainColor10;
		font-size: 17px;
		font-weight: bold;
		line-height: 1.4;
		> i {
			font-style: normal;
			font-size: 12px;
		}
	}
	&_body {
		margin-top: 5px;
		padding-top: 8px;
		border-top: 1px dashed $gray-border;
	}
	&_txt {
		margin: 0;
		font-size: 11px;
		line-height: 1.8;
		letter-spacing: .1em;
	}
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
balloonTip

balloonTip

Markup:
<div class="balloonTipWrap">
	<button type="button" class="btn btn-max balloonBtn"><i>フォーカスエリア</i></button>
	<div class="balloonTip">
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
	</div>
</div><!-- /.balloonTipWrap -->

Styleguide 4.27
============================================================*/
.balloonTipWrap {
}
.balloonTip {
	display: none;
	animation-duration: 0.3s;
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
privacy

privacy

Markup:
<div class="privacy">
	<div class="privacy_inner">
		<ul class="privacy_list">
			<li class="privacy_item privacy_item-blank">
				<div>
					<a href="http://www.shinetsu.co.jp/jp/privacy.html" target="_blank" class="privacy_figureLink">
						<figure class="privacy_figure">
							<img class="privacy_img" src="./../assets/images/shared/privacy/img_logo-shinetsu.png" alt="信越グループ">
						</figure>
					</a>
					<div class="privacy_body">
						<p class="privacy_title"><a href="http://www.shinetsu.co.jp/jp/privacy.html" target="_blank"><span>信越グループ</span></a></p>
					</div>
				</div>
			</li>
			<li class="privacy_item">
				<div>
					<a href="<%= siteRoot %>privacy\nissinchem/" class="privacy_figureLink">
						<figure class="privacy_figure">
							<img class="privacy_img" src="./../assets/images/shared/privacy/img_logo-nisshin.png" alt="日信化学工業株式会社">
						</figure>
					</a>
					<div class="privacy_body">
						<p class="privacy_title"><a href="<%= siteRoot %>privacy\nissinchem/"><span>日信化学工業株式会社</span></a></p>
					</div>
				</div>
			</li>
		</ul><!-- /.privacy_list -->
	</div>
</div><!-- /.privacy -->

Styleguide 4.28
============================================================*/
.privacy {
	$this: &;
	$space: 94px;
	position: relative;
	margin: 0;
	&_inner {
		text-align: center;
	}
	&_list {
		@include inline_block-p;
		margin: -20px 0 0 -#{$space};
		& > * {
			@include inline_block;
			margin: 20px 0 0 #{$space};
		}
	}
	&_item {
		> a ,
		> div {
		}
		&-blank {
			#{$this}_figureLink {
				position: relative;
				&::after {
					display: inline-block;
					position: absolute;
					bottom: 5px;
					right: 5px;
					width: 14px;
					height: 12px;
					content: "";
					background: url('./../images/bullets/icon_blank.png') center no-repeat;
					z-index: 10;
				}
			}
		}
	}

	&_figureLink {
		display: block;
		width: 100%;
		height: 100%;
	}
	&_figure {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
				justify-content: center;
		-webkit-align-items: center;
				align-items: center;

		margin: 0;
		min-width: 300px;
		min-height: 160px;
		background-color: #fff;
		border: 1px solid $mainColor08;
	}
	&_img {
	}

	&_body {
		text-align: center;
		margin-top: .8em;
	}
	&_title {
		position: relative;
		margin: 0;
		color: #333;
		font-size: 15px;
		span {
			position: relative;
			padding-left: 20px;
			display: inline-block;
			&:before {
				display: inline-block;
				position: absolute;
				top: 0px;
				left: 2px;
				content: "\f138";
				font-family: FontAwesome;
				color: $mainColor04;
				font-size: inherit;
			}
		}
		> a {
			position: relative;
			display: block;
			color: inherit;
			font-style: normal;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.privacy {
		$this: &;
		$space: 25px;
		&_list {
			margin: 0;
			padding: 0 1em;
			& > * {
				display: block;
				margin: #{$space} 0 0;
				&:first-child {
					margin-top: 0;
				}
			}
		}

		&_figure {
			min-width: 240px;
			min-height: 128px;
		}

		&_body {
			text-align: center;
			margin-top: .8em;
		}
		&_title {
			font-size: 14px;
			span {
				padding-left: 20px;
				&:before {
					left: 2px;
					font-size: inherit;
				}
			}
		}
	}
}

/*
ご利用規約 詳細

termsDesc

Markup:
<div class="termsDesc">
	<div class="termsDesc_inner">
		<p>
			テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
		</p>
		<dl class="termsDescDef">
			<dt class="termsDescDef_title"><em>第1条</em> 総則</dt>
			<dd class="termsDescDef_body">
				<p>
					テキストテキストテキストテキストキストテキストテキストテキストテキスト
				</p>
				<ul>
					<li>リストリストリストリスト</li>
					<li>リストリストリストリスト</li>
				</ul>
			</dd>
		</dl><!-- /.termsDescDef -->
		<dl class="termsDescDef">
			<dt class="termsDescDef_title"><em>第2条</em> 〇〇</dt>
			<dd class="termsDescDef_body">
				<p>テキストテキストテキストテキストキストテキストテキストテキストテキスト</p>
				<ul class="termsDescList termsDescList-brackets">
					<li>（1）テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</li>
					<li>（2）テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</li>
				</ul>
			</dd>
		</dl><!-- /.termsDescDef -->
	</div><!-- /.termsDesc_inner -->
</div><!-- /.termsDesc -->

Styleguide 4.29
============================================================*/
.termsDesc {
	$this: &;
}

.termsDescDef {
	margin-top: 2em;
	&:first-child {
		margin-top: 0;
	}
	&_title {
		position: relative;
		margin-top: 1em;
		padding: 10px 5px 5px;
		font-size: 16px;
		font-weight: bold;
		border-bottom: 1px solid $mainColor01;
		> em {
			color: $mainColor01;;
			margin-right: .5em;
			font-weight: bold;
			font-style: normal;
		}
	}
	&_body {
		padding-left: 1em;
		p {
			margin-top: 1em;
			&:first-child {
				margin-top: .5em;
			}
		}
	}
}
.termsDescList {
	padding-left: 1em;
	> li {
		position: relative;
		margin-top: .5em;
		text-indent: -1em;
		&:first-child {
			margin-top: 0;
		}
		> em {
			position: relative;
			left: 0em;
			font-style: normal;
		}
	}

	&-brackets {
		> li {
			text-indent: -2.5em;
			padding-left: 1em;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.termsDesc {
		font-size: 13px;
	}

	.termsDescDef {
		&_title {
			font-size: 14px;
		}
		&_body {
			padding-left: 0;
		}
	}
}

/*
先輩の声一覧

voiceList

Markup:
<div class="voiceList">
	<div class="voiceList_list">
		<article class="voiceList_item">
			<div class="voiceList_itemInner">
				<div class="voiceList_hero">
					<figure class="voiceList_figure">
						<img class="voiceList_img" src="<%= siteRoot %>assets/images/shared/recruit/voice/researcher/img_thumb01.jpg" alt="">
					</figure>
				</div>
				<div class="voiceList_card">
					<div class="voiceList_cardInner">
						<a href="<%= siteRoot %>recruit/voice/researcher/voice01.html" class="voiceList_title">Ｎ・Ｕさん</a>
						<p class="voiceList_txt">
							2017年度入社
							<br>研究所第一研究室所属
							<br>福井工業高等専門学校専攻科環境システム工学専攻出身
						</p>
					</div>
				</div>
			</div>
		</article>
		<article class="voiceList_item">
			<div class="voiceList_itemInner">
				<div class="voiceList_hero">
					<figure class="voiceList_figure">
						<img class="voiceList_img" src="<%= siteRoot %>assets/images/shared/recruit/voice/researcher/img_thumb02.jpg" alt="">
					</figure>
				</div>
				<div class="voiceList_card">
					<div class="voiceList_cardInner">
						<a href="<%= siteRoot %>recruit/voice/researcher/voice02.html" class="voiceList_title">Ｋ・Ｙさん</a>
						<p class="voiceList_txt">
							2016年度入社
							<br>研究所第二研究室所属
							<br>福井大学大学院工学研究科繊維先端工学専攻出身
						</p>
					</div>
				</div>
			</div>
		</article>
	</div><!-- /.voiceList_list -->
</div><!-- /.voiceList -->

Styleguide 4.30
============================================================*/
.voiceList {
	$this: &;
	$gutter-h: 20px;
	$gutter-v: 60px;
	$side-width: 45%;
	$size-skew: 6deg;

	// main image size
	$main-maxRatioWidth: 500;
	$main-maxRatioHeight: 330;
	$main-imgAspectRatio: calc( #{$main-maxRatioHeight} / #{$main-maxRatioWidth} * 100% ); // アスペクト比

	$main-maxWidth: 500px;
	$main-maxHeight: 330px;
	$main-maxWidthRatio: floor((( $main-maxWidth / $mainWidth ) * 10000% )) / 100;

	margin: 30px 0 0;
	&_list {
		margin: 0;
		list-style-type: none;
		& > * {
			margin: 0;
			&:not(:first-child) {
				margin-top: #{$gutter-v};
			}
		}
	}
	&_item {
		> a ,
		> div {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
					align-items: center;

			position: relative;
			// display: block;
			margin: 0;
			width: 100%;
			color: $baseColor;
			text-decoration: none;
			outline: none;
			-webkit-transition: .3s ease;
					transition: .3s ease;
			&:hover {
				opacity: 1;
			}
		}

		&:nth-child(odd) {
			#{$this}_card {
				margin-left: -5%;
			}
		}
		&:nth-child(even) {
			#{$this}_hero {
				-webkit-order: 2;
				    -ms-order: 2;
				        order: 2;
			}
			#{$this}_card {
				-webkit-order: 1;
				    -ms-order: 1;
				        order: 1;
				margin-right: -7%;
				&:before {
					@include gradientColor02;
				}
			}
		}
	}
	&_inner {
	}

	// _hero area
	&_hero {
		position: relative;
		width: #{$side-width};
		min-width: #{$side-width};
		// -webkit-transform: skewX(-#{$size-skew});
		//         transform: skewX(-#{$size-skew});
		overflow: hidden;
		&::before ,
		&::after {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			border-color: transparent;
			z-index: 5;
		}
		&::before {
			top: 0;
			left: 0;
			border-width: #{$main-maxHeight} 35px 0 0;
			border-top-color: $white;
		}
		&::after {
			right: 0;
			bottom: 0;
			border-width: 0 0 #{$main-maxHeight} 35px;
			border-bottom-color: $white;
		}
	}
	&_figure {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
				justify-content: center;
		-webkit-align-items: center;
				align-items: center;

		position: relative;
		// display: block;
		margin: 0 auto;
		padding-top: #{$main-imgAspectRatio};
		width: 100%;
		overflow: hidden;
		text-align: center;
		text-decoration: none;
		// -webkit-transform: skewX(#{$size-skew});
		//         transform: skewX(#{$size-skew});
		-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
		> a ,
		> span {
			position: relative;
			display: block;
			outline: none;
			transition: none;
		}
	}
	&_img {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		max-width: 100%;
		max-height: 100%;
		// width: 100%; // 「 cover 」時設定追加
		// height: 100%; // 「 cover 」時設定追加
		object-fit: contain;
		font-family: 'object-fit: contain;';
		-webkit-transition: .3s;
				transition: .3s;
		-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
	}

	// _card area
	&_card {
		position: relative;
		z-index: 10;
		width: 100%;
		&:before {
			content: "";
			position: absolute;
			display: block;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: calc( 100% - 12px);
			height: 100%;
			@include gradientColor01;
			-webkit-transform: skewX(-#{$size-skew});
			        transform: skewX(-#{$size-skew});
		}
	}
	&_cardInner {
		position: relative;
		z-index: 5;
		display: block;
		color: $white;
		padding: 25px 40px 30px;
		text-decoration: none;
		opacity: 1;
		&:before {
			content: "";
			position: absolute;
			display: block;
			right: 30px;
			bottom: 6px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 8px 8px;
			border-color: transparent transparent rgba(255,255,255,.6) transparent;
			z-index: 5;
		}
	}
	&_title {
		position: relative;
		display: block;
		margin: 0;
		color: $white;
		padding: 0 10px 3px 20px;
		font-size: 18px;
		font-weight: bold;
		border-bottom: 1px dotted $mainColor09;
		-webkit-font-smoothing: antialiased;
		text-decoration: none;
		&:before {
			display: inline-block;
			position: absolute;
			top: 0px;
			left: 2px;
			content: "\f138";
			font-family: FontAwesome;
			color: $white;
			font-size: 16px;
		}
		> a {
			position: relative;
			display: block;
			color: inherit;
			font-style: normal;
			text-decoration: none;
		}
	}
	&_txt {
		margin-top: 15px;
		padding-left: 1.8em;
		font-size: 14px;
		-webkit-font-smoothing: antialiased;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.voiceList {
		$this: &;
		$gutter-h: 20px;
		$gutter-v: 40px;
		$side-width: 90%;
		$size-skew: 4deg;

		// main image size
		$main-maxRatioWidth: 380;
		$main-maxRatioHeight: 220;
		$main-imgAspectRatio: calc( #{$main-maxRatioHeight} / #{$main-maxRatioWidth} * 100% ); // アスペクト比

		$main-maxWidth: 380px;
		$main-maxHeight: 220px;
		$main-maxWidthRatio: floor((( $main-maxWidth / $mainWidth ) * 10000% )) / 100;

		margin: 20px 0 0;
		&_list {
			margin: 0;
			list-style-type: none;
			& > * {
				margin: 0;
				&:not(:first-child) {
					margin-top: #{$gutter-v};
				}
			}
		}
		&_item {
			> a ,
			> div {
				-webkit-align-items: stretch;
						align-items: stretch;
				-webkit-flex-flow: column wrap;
				        flex-flow: column wrap;
			}
			&:nth-child(even) {
				#{$this}_hero {
					-webkit-order: 1;
					    -ms-order: 1;
					        order: 1;
				}
				#{$this}_card {
					-webkit-order: 2;
					    -ms-order: 2;
					        order: 2;
				}
			}
		}

		// _hero area
		&_hero {
			text-align: center;
			left: auto;
			margin: auto;
			width: #{$side-width};
			min-width: #{$side-width};
			max-height: #{$main-maxHeight};
			&::before {
				border-width: #{$main-maxHeight} 25px 0 0;
			}
			&::after {
				border-width: 0 0 #{$main-maxHeight} 25px;
			}
		}
		&_figure {
		}

		// _card area
		&_card {
			margin: -10px 0 0 !important;
			width: 100%;
			&:before {
				width: calc( 95%);
				left: 50%;
				-webkit-transform: translateX(-50%) skewX(-#{$size-skew});
				        transform: translateX(-50%) skewX(-#{$size-skew});
			}
		}
		&_cardInner {
			padding: 15px 25px ;
			&:before {
				right: 20px;
				bottom: 6px;
			}
		}
		&_title {
			font-size: 16px;
			&:before {
				font-size: 14px;
			}
		}
		&_txt {
			margin-top: 15px;
			padding-left: 1em;
			font-size: 12px;
		}
	}
}

/*
先輩の声一覧

voiceList

Markup:

Styleguide 4.31
============================================================*/
.voiceDetail {
	$this: &;
	// $gutter-h: @@px;
	$gutter-v: 35px;

	&_contents {
		margin-top: 35px;
	}
	&_section {
		margin: 0;
		&:not(:first-child) {
			margin-top: #{$gutter-v};
		}
		.headLine2 {
			margin-bottom: 20px;
		}
		p {
			margin: 0;
			&:not(:first-child) {
				margin-top: 20px;
			}
		}
	}
}
.voiceHeader {
	$this: &;
	$side-width: 38%;
	$gutter-h: 25px;

	display: -webkit-flex;
	display: flex;

	margin: 0;
	& > * {
		margin: 0;
		&:not(:first-child) {
			margin-left: #{$gutter-h};
		}
	}
	&_figure {
		-webkit-flex: 0 0 auto;
		    -ms-flex: 0 0 auto;
		        flex: 0 0 auto;
		margin: 0;
		width: #{$side-width};
		min-width: #{$side-width};
	}
	&_img {
		text-align: center;
	}
	&_intro {
		width: 100%;
		padding: 10px 0;
	}
	&_introTxt {
		margin: 0;
		@include fts(9, 16);
		font-weight: bold;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.voiceDetail {
		$this: &;
		// $gutter-h: @@px;
		$gutter-v: 25px;

		&_contents {
			margin-top: 30px;
		}
		&_section {
			margin: 0;
			&:not(:first-child) {
				margin-top: #{$gutter-v};
			}
			.headLine2 {
				margin-bottom: 15px;
			}
			p {
				margin: 0;
				&:not(:first-child) {
					margin-top: 15px;
				}
			}
		}
	}
	.voiceHeader {
		$this: &;
		$side-width: 100%;
		$gutter-h: 15px;

		-webkit-align-items: stretch;
				align-items: stretch;
		-webkit-flex-flow: column wrap;
		        flex-flow: column wrap;
		& > * {
			margin: 0;
			width: 100%;
			width: 100%\9;
			&:not(:first-child) {
				margin: #{$gutter-h} 0 0;
			}
		}
		&_figure {
			width: #{$side-width};
			min-width: 0;
		}
		&_intro {
			width: 100%;
			padding: 0;
		}
	}
}


