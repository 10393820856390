@charset "UTF-8";
@import '_config.scss';
/**********************************************
 animation
***********************************************/


/*============================================================
  popFade 共通
============================================================*/
.popFadeInUp ,
.popFadeInDown ,
.popFadeInLeft ,
.popFadeInRight {
	display: inline-block !important;
	-webkit-animation-fill-mode: both;
		-ms-animation-fill-mode: both;
			animation-fill-mode: both;
	-webkit-animation-duration: .5s;
		-ms-animation-duration: .5s;
			animation-duration: .5s;
	visibility: visible !important;
}


/*============================================================
  popFadeInUp
============================================================*/
.popFadeInUp {
	-webkit-animation-name: fadeInUp;
			animation-name: fadeInUp;
}
@-webkit-keyframes fadeInUp {
	0% { opacity: 0; -webkit-transform: translateY(20px); }
	100% { opacity: 1; -webkit-transform: translateY(0); }
}
@keyframes fadeInUp {
	0% { opacity: 0; -webkit-transform: translateY(20px); -ms-transform: translateY(20px); transform: translateY(20px); }
	100% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); }
}


/*============================================================
  popFadeInDown
============================================================*/
.popFadeInDown {
	-webkit-animation-name: fadeInDown;
			animation-name: fadeInDown;
}
@-webkit-keyframes fadeInDown {
	0% { opacity: 0; -webkit-transform: translateY(-20px); }
	100% { opacity: 1; -webkit-transform: translateY(0); }
}
@keyframes fadeInDown {
	0% { opacity: 0; -webkit-transform: translateY(-20px); -ms-transform: translateY(-20px); transform: translateY(-20px); }
	100% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); }
}
@media screen and (max-width: $breakpoint-sp) {
}


/*============================================================
  popFadeInLeft
============================================================*/
.popFadeInLeft {
	-webkit-animation-name: fadeInLeft;
			animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInLeft {
	0% { opacity: 0; -webkit-transform: translateX(20px); }
	100% { opacity: 1; -webkit-transform: translateX(0); }
}
@keyframes fadeInLeft {
	0% { opacity: 0; -webkit-transform: translateX(20px); -ms-transform: translateX(20px); transform: translateX(20px); }
	100% { opacity: 1; -webkit-transform: translateX(0); -ms-transform: translateX(0); transform: translateX(0); }
}




/*============================================================
  popFadeInRight
============================================================*/
.popFadeInRight {
	-webkit-animation-name: fadeInRight;
			animation-name: fadeInRight;
}
@-webkit-keyframes fadeInRight {
	0% { opacity: 0; -webkit-transform: translateX(-20px); }
	100% { opacity: 1; -webkit-transform: translateX(0); }
}
@keyframes fadeInRight {
	0% { opacity: 0; -webkit-transform: translateX(-20px); -ms-transform: translateX(-20px); transform: translateX(-20px); }
	100% { opacity: 1; -webkit-transform: translateX(0); -ms-transform: translateX(0); transform: translateX(0); }
}

