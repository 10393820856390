@charset "UTF-8";
@import '_config.scss';
/*
Modal theme

各Modal用 CSSテーマ

Styleguide 7.
*/

/*
remodal 再設定
============================================================*/
.modal-content {
	z-index: 25000;
	margin: 0;
	padding: 10px;
	border: 2px solid $gray-border;
	background: #fff;
	position: fixed;
	display: none;
}
#modal-overlay {
	z-index: 20000;
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 120%;
	background: rgba(0, 0, 0, 0.8);
}
.remodal-wrapper {
	padding: 0;
}
.remodal-overlay {
	background: rgba(0, 0, 0, 0.8);
}
.remodal-close {
	position: absolute;
	top: 5px;
	left: auto;
	right: 5px;
	cursor: pointer;
	&::before {
		display: none;
	}
	* {
		-webkit-transition: all .5s;
		   -moz-transition: all .5s;
		    -ms-transition: all .5s;
		     -o-transition: all .5s;
		        transition: all .5s;
	}
	> span {
		position: relative;
		display: inline-block;
		width: 35px;
		height: 35px;
		&::before ,
		&::after {
			position: absolute;
			top: 50%;
			left: 0;
			display: inline-block;
			content: "";
			width: 35px;
			height: 1px;
			background-color: #000;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
		&::before {
			-webkit-transform: translateY(0) rotate(-45deg);
			-ms-transform: translateY(0) rotate(-45deg);
			transform: translateY(0) rotate(-45deg);
		}
		&::after {
			-webkit-transform: translateY(0) rotate(45deg);
			-ms-transform: translateY(0) rotate(45deg);
			transform: translateY(0) rotate(45deg);
		}
		> i {
			@include textHidden;
		}
	}
	// &:hover {
	//	> span {
	//		&::before ,
	//		&::after {
	//			background-color: $gray-bg;
	//		}
	//	}
	// }
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
モーダルボックス（remodal用）

remodal

Markup:
<button type="button" data-remodal-target="js-rM-sample01" class="btn btn-thick btn-success"><span>Modal Open</span></button>
<button type="button" data-remodal-target="js-rM-sample02" class="btn btn-thick btn-success"><span>Modal Open</span></button>
<button type="button" data-remodal-target="js-rM-complete" class="btn btn-thick btn-success"><span>Modal Open</span></button>
<!-- sample01 -->
<div class="remodal {$modifiers}" data-remodal-id="js-rM-sample01">
	<div data-remodal-action="close" class="remodal-close"><span><i>CLOSE</i></span></div>
	<!-- <button data-remodal-action="close" class="remodal-close"><span><i>CLOSE</i></span></button> -->
	<div class="remodal_inner">
		<div class="remodalHead">
			<h3 class="remodalHead_title">titile</h3>
		</div>
		<div class="remodalBody">
			<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
		</div>
		<div class="remodalFoot">
			<ul class="remodal_btns">
				<li class="remodal_btn"><button type="button" data-remodal-target="js-rM-complete" class="btn btn-thick btn-success"><span>OK</span></button></li>
				<li class="remodal_btn"><button type="button" data-remodal-action="cancel" class="btn btn-thick btn-cancel"><span>キャンセル</span></button></li>
			</ul>
		</div>
	</div>
</div><!-- /.remodal -->
<!-- sample02 -->
<div class="remodal {$modifiers}" data-remodal-id="js-rM-sample02">
	<div data-remodal-action="close" class="remodal-close"><span><i>CLOSE</i></span></div>
	<!-- <button data-remodal-action="close" class="remodal-close"><span><i>CLOSE</i></span></button> -->
	<div class="remodal_inner">
		<div class="remodalBody">
			<p class="remodal_message">レビューを削除してよろしいですか？</p>
			<p class="remodal_input"><input type="text" name="" id="" class="input" placeholder=""></p>
			<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
		</div>
		<div class="remodalFoot">
			<ul class="remodal_btns">
				<li class="remodal_btn"><button type="button" data-remodal-target="js-rM-complete" class="btn btn-thick btn-success"><span>OK</span></button></li>
				<li class="remodal_btn"><button type="button" data-remodal-action="cancel" class="btn btn-thick btn-cancel"><span>キャンセル</span></button></li>
			</ul>
		</div>
	</div>
</div><!-- /.remodal -->
<!-- complete -->
<div class="remodal remodal-completes" data-remodal-id="js-rM-complete">
	<div data-remodal-action="close" class="remodal-close"><span><i>CLOSE</i></span></div>
	<div class="remodal_inner">
		<div class="remodalBody">
			<p class="remodal_message">削除が完了しました。</p>
		</div>
	</div>
</div><!-- /.remodal -->

Styleguide 7.2
============================================================*/
.remodal {
	$this: &;
	$space: 20px;
	position: relative;
	text-align: left;
	margin: 0 auto;
	padding: 35px 5px 15px;
	width: 95%;
	border: 1px solid $gray-border;
	&_inner {
		background-color: #fff;
	}
	&Head {
		border-bottom: 1px dotted $gray-dot;
		&_title {
			padding: 5px 10px;
			font-size: 14px;
			font-weight: bold;
			text-align: center;
		}
	}
	&Body {
		padding: 0 5px;
	}
	&_message {
		margin: 0;
		padding: 10px;
		font-size: 14px;
		// font-weight: bold;
		text-align: center;
	}
	// &_input {
	//	text-align: center;
	//	> input {
	//		min-height: 38px;
	//	}
	// }
	&Foot {
		margin-top: 10px;
		padding: 5px;
	}
	&_btns {
		@include inline_block-p;
		margin: -10px 0 0 -#{$space};
		text-align: center;
	}
	&_btn {
		@include inline_block;
		vertical-align: top;
		margin: 10px 0 0 #{$space};
	}
	//モーダルの表示サイズ
	&-boxS {
		max-width: 450px !important;
	}
	&-boxL {
		max-width: 1100px !important;
	}
	// 確認画面モーダルの時
	&-confirms {
		#{$this}_btn {
			.btn-success {
				min-width: 85px;
			}
		}
	}
	// 完了画面モーダルの時
	&-completes {
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.remodal {
		//モーダルの表示サイズ
		&-boxS ,
		&-boxL {
			max-width: none !important;
		}
	}
}