@charset "UTF-8";
@import '_config.scss';
/*
グローバル

gNavとかgHeaderとか
大まかなレイアウトもここに

Styleguide 2.
*/

/*
wrapper

wrapper

Markup:
{$modifiers}

Styleguide 2.2
============================================================*/
.wrapper {
	position: relative;
}
@media screen and (max-width: $breakpoint-sp) {
	.wrapper {
		&-isFixed {
			overflow: hidden;
		}
	}
}

/*
コンテンツブロック

pageContents, pageContentsSide, pageContentsMain

Markup:
{$modifiers}

Styleguide 2.2
============================================================*/
.pageContents {
	margin: 0 auto;
	width: $mainWidthWide;
	&_inner {
		padding: 32px 85px 0;
		&:before,
		&:after {
			content: ".";
			display: block;
			height: 0;
			visibility: hidden;
		}
		&:after {
			clear: both;
		}
	}
	&Main {
		width: $mainWidth;
		float: left;
		&.main-wide {
			float: none;
			width: 100%;
		}
	}
	&Side {
		width: $sideWidth;
		float: right;
	}
}
// pageContentsSide内
.side {
	&Section {
		margin-top: 27px;
		&:first-child {
			margin-top: 0;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.pageContents {
		margin: 0 auto;
		padding-top: 0;
		width: 100%;
		&_inner {
			padding: 20px 10px 0;
			width: 100%;
		}
		&Main ,
		&Side {
			width: 100%;
			float: none;
		}
		&Main {
		}
		&Side {
			margin-top: 39px;
		}
	}
}

/*
セクション

section

Markup:
{$modifiers}

Styleguide 2.3.1
============================================================*/
.section {
// $this: &;
	position: relative;
	margin-top: 45px;
	&:first-child {
		margin-top: 0;
	}
	&-first {
		margin-top: 45px;
	}
	&-borderTop {
		border-top: 1px dotted $mainColor09;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.section {
	// $this: &;
		position: relative;
		margin-top: 39px;
		&:first-child {
			margin-top: 0;
		}
		&-first {
			margin-top: 25px;
		}
	}
}

/*
グローバルヘッダー

gHeader

Markup:
<header class="gHeader">
	<div class="gHeader_inner">
		<h1 class="gHeader_logo"><a href="<%= siteRoot %>"><img src="./../assets/images/global/header_logo.png" width="384" height="54" alt="日信化学工業株式会社"></a></h1><!-- /.logo -->
		<div class="primaryNav">
			<!-- サブメニュー上部 -->
			<div class="primaryNav_top">
				<ul class="language_list">
					<li class="language_item language_item-en"><a href="">English</a></li>
					<li class="language_item language_item-ch"><a href="">Chinese</a></li>
				</ul><!-- /.language_list -->
			</div><!-- /.primaryNav_top -->
			<!-- サブメニュー下部 -->
			<div class="primaryNav_bottom">
				<ul class="primaryNav_list">
					<li class="primaryNav_item"><a href="<%= siteRoot %>news"><span>ニュース</span></a></li>
					<li class="primaryNav_item"><a href="<%= siteRoot %>privacy"><span>個人情報保護ポリシー</span></a></li>
					<li class="primaryNav_item"><a href="<%= siteRoot %>sitemap"><span>サイトマップ</span></a></li>
				</ul><!-- /.primaryNav_list -->
			</div><!-- /.primaryNav_bottom -->
		</div><!-- /.primaryNav -->
	</div><!-- /.gHeader_inner -->
</header><!-- /.gHeader -->

Styleguide 2.3.1
============================================================*/
.gHeader {
	background-color: #fff;
	&_inner {
		margin: 0 auto;
		padding: 0 70px;
		width: 1300px;
		min-height: 90px;
		&:before,
		&:after {
			content: ".";
			display: block;
			height: 0;
			visibility: hidden;
		}
		&:after {
			clear: both;
		}
	}
	&_logo ,
	.primaryNav {
		position: relative;
	}
	&_logo {
		top: 20px;
		float: left;
	}
	.primaryNav {
		float: right;
		a {
			display: inline-block;
			text-decoration: none;
		}
		&_top {
			margin-top: 13px;
			width: 100%;
		}
		&_bottom {
			margin-top: 24px;
			width: 100%;
		}
		&_list {
			margin: 0;
			@include inline_block-p;
		}
		&_item {
			@include inline_block;
			margin-left: 26px;
			&:first-child {
				margin-left: 0;
			}
			a {
				position: relative;
				color: inherit;
				font-size: 13px;
				line-height: 1;
				&:before {
					content:"\f138";
					font-family: FontAwesome;
					margin-right: 6px;
					color: $mainColor01;
					font-size: inherit;
				}
			}
		}
		.language {
			&_list {
				@include inline_block-p;
				margin: 0;
				text-align: right;
			}
			&_item {
				@include inline_block;
				margin-left: 20px;
				&:first-child {
					margin-left: 0;
				}
				a {
					position: relative;
					color: #333;
					font-size: 13px;
					line-height: 1;
					&:before {
						position: relative;
						content: "";
						display: inline-block;
						vertical-align: middle;
						margin-right: 6px;
					}
				}
				&-jp {
					a {
						&:before {
							width: 29px;
							height: 19px;
							background: url('./../images/bullets/icon-language_jp.png') no-repeat;
							-webkit-background-size: cover;
							background-size: cover;
						}
					}
				}
				&-en {
					a {
						&:before {
							width: 29px;
							height: 19px;
							background: url('./../images/bullets/icon-language_en.png') no-repeat;
							-webkit-background-size: cover;
							background-size: cover;
						}
					}
				}
				&-ch {
					a {
						&:before {
							width: 29px;
							height: 19px;
							background: url('./../images/bullets/icon-language_ch.png') no-repeat;
							-webkit-background-size: cover;
							background-size: cover;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.gHeader {
		&_inner {
			margin: 0 auto;
			padding: 0;
			width: 100%;
			min-height: 0;
			height: 70px;
		}
		&_logo ,
		.primaryNav {
			position: relative;
			float: none;
		}
		&_logo {
			top: 22px;
			padding-left: 55px;
			width: auto;
			img {
				width: 235px;
				// height: 30px;
			}
		}
		.primaryNav {
			display: none;
		}
	}
}

/*
グローバルヘッダー02

gHeader02

Markup:
<header class="gHeader02">
	<div class="gHeader02_inner">
		<h1 class="gHeader02_logo"><a href="<%= siteRoot %>"><img src="<%= siteRoot %>assets/images/global/header_logo.png" alt="日信化学工業株式会社"></a></h1>
	</div><!-- /.gHeader02_inner -->
</header><!-- /.gHeader02 -->

Styleguide 2.3.2
============================================================*/
.gHeader02 {
	&_inner {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		margin: 0 auto;
		padding: 10px 1em 7px;

		width: 100%;
		max-width: 1300px;
		min-height: 140px;
		&:before,
		&:after {
			content: ".";
			display: block;
			height: 0;
			visibility: hidden;
		}
		&:after {
			clear: both;
		}
	}
	&_logo {
		position: relative;
		text-align: center;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.gHeader02 {
		&_inner {
			max-width: none;
			min-height: 80px;
		}
		&_logo {
			img {
				width: 225px;
				// height: 30px;
			}
		}
	}
}

/*
グローバルヘッダー ： グローバルナビ

gNav

Markup:
<p class="gNavBtn pc-hidden">
	<a href="javascript: void(0);"><span></span></a>
</p>
<nav class="gNav">
	<ul class="gNav_list">
		<li class="gNav_item gNav_item-spMenu"><a href="<%= siteRoot %>">HOME</a></li>
		<li class="gNav_item gNav_item-01"><a href="javascript: void(0);"><span>製品情報</span></a>
			<div class="gNavDropdown">
				<ul>
					<li class="navIcon01-1"><a href="<%= siteRoot %>products/">製品情報トップ</a></li>
					<li class="navIcon01-2"><a href="<%= siteRoot %>products/search-product.html">製品名から探す</a></li>
					<li class="navIcon01-3"><a href="<%= siteRoot %>products/search-application.html">用途から探す</a></li>
					<li class="navIcon01-4"><a href="<%= siteRoot %>products/search-feature.html">機能から探す</a></li>
					<li class="navIcon01-5"><a href="<%= siteRoot %>products/emulsion.html">エマルジョンとは</a></li>
				</ul><!-- /.gNavDropdown -->
			</div>
		</li>
		<li class="gNav_item gNav_item-02"><a href="javascript: void(0);"><span>会社案内</span></a>
			<div class="gNavDropdown">
				<ul>
					<li class="navIcon02-1"><a href="<%= siteRoot %>company/">会社案内トップ</a></li>
					<li class="navIcon02-2"><a href="<%= siteRoot %>company/overview.html">会社概要</a></li>
					<li class="navIcon02-3"><a href="<%= siteRoot %>company/csr.html">CSR活動</a></li>
					<li class="navIcon02-4"><a href="<%= siteRoot %>company/movie.html">ムービー</a></li>
					<li class="navIcon02-5"><a href="<%= siteRoot %>company/information.html">日信化学ってどんな会社？</a></li>
				</ul><!-- /.gNavDropdown -->
			</div>
		</li>
		<li class="gNav_item gNav_item-03"><a href="javascript: void(0);"><span>サポート</span></a>
			<div class="gNavDropdown">
				<ul>
					<li class="navIcon03-1"><a href="<%= siteRoot %>support/">サポートトップ</a></li>
					<li class="navIcon03-2"><a href="<%= siteRoot %>support/faq.html">よくある質問</a></li>
					<li class="navIcon03-2"><a href="<%= siteRoot %>support/sds-download.html">SDSダウンロード</a></li>
					<li class="navIcon03-3"><a href="<%= siteRoot %>support/catalog-download.html">カタログダウンロード</a></li>
					<li class="navIcon03-4"><a href="<%= siteRoot %>support/contact_product.html">製品に関するお問い合わせ</a></li>
				</ul><!-- /.gNavDropdown -->
			</div>
		</li>
		<li class="gNav_item gNav_item-04"><a href="javascript: void(0);"><span>採用情報</span></a>
			<div class="gNavDropdown">
				<ul>
					<li class="navIcon04-1"><a href="<%= siteRoot %>recruit/">採用情報トップ</a></li>
					<li class="navIcon04-2"><a href="<%= siteRoot %>recruit/job-category.html">採用情報</a></li>
					<li class="navIcon04-3"><a href="<%= siteRoot %>recruit/voice.html">先輩の声</a></li>
				</ul><!-- /.gNavDropdown -->
			</div>
		</li>
		<li class="gNav_item gNav_item-05"><a href="javascript: void(0);"><span>お問い合わせ</span></a>
			<div class="gNavDropdown">
				<ul>
					<li class="navIcon05-1"><a href="<%= siteRoot %>form/">お問い合わせトップ</a></li>
					<li class="navIcon05-2"><a href="<%= siteRoot %>form/contact.html">お問い合わせフォーム</a></li>
					<li class="navIcon05-3"><a href="<%= siteRoot %>support/contact_sds.html">カタログ/SDSのご請求</a></li>
				</ul><!-- /.gNavDropdown -->
			</div>
		</li>
	</ul><!-- /.gNav_list -->
	<ul class="gSubNav_list">
		<li class="gSubNav_item"><a href="<%= siteRoot %>news"><span>ニュース</span></a></li>
		<li class="gSubNav_item"><a href="<%= siteRoot %>privacy"><span>個人情報保護ポリシー</span></a></li>
		<li class="gSubNav_item"><a href="<%= siteRoot %>sitemap"><span>サイトマップ</span></a></li>
	</ul><!-- /.gSubNav_list -->
	<p class="gNav_close pc-hidden"><a href="javascript: void(0);">CLOSE</a></p>
</nav><!-- /.gNav -->
<a href="javascript: void(0);" class="gNavOverlay"></a>

Styleguide 2.4
============================================================*/
.gNav {
	position: relative;
	width: 100%;
	height: #{$gNavHight};
	background-color: $mainColor01;
	&_list {
		@include inline_block-p;
		margin: 0 auto;
		width: $topMainWidth;
		text-align: center;
	}
	&_item {
		@include inline_block;
		> a {
			position: relative;
			display: block;
			padding-top: 2px;
			width: ( $topMainWidth / 5);
			line-height: #{$gNavHight};
			color: #fff;
			font-size: 15px;
			text-decoration: none;
			// cursor: default;
			cursor: pointer;
			&:before {
				display: inline-block;
				content:"";
				position: absolute;
				top: 10px;
				left: 0;
				width: 1px;
				height: 18px;
				background: #7596C5;
				opacity: 1;
				z-index: 500;
			}
			&:after {
				display: inline-block;
				content:"";
				position: absolute;
				top: 10px;
				right: -1px;
				width: 1px;
				height: 18px;
				background: #7596C5;
				opacity: 1;
				z-index: 500;
			}
			&:hover {
				background-color: #507BB6;
			}
		}
		&-spMenu {
			display: none;
		}

		// is-current
		&.is-current {
			background-color: darken($mainColor01, 7%);
			> a {
			}
		}
	}
}
/* ドロップダウンメニュー
--------------------------------------*/
// .gNavDropdown {
//	display: block !important;
// }
.gNavDropdown {
	display: none;
	position: absolute;
	padding: 10px 0;
	width: 100%;
	min-height: 133px;
	top: #{$gNavHight};
	left: 0;
	text-align: left;
	background-color: $mainColor05;
	z-index: 1000;
	overflow: visible;
	> ul {
		@include inline_block-p;
		margin: 0 auto;
		width: $topMainWidth;
		> li {
			@include inline_block;
			margin: 6px 6px 0 0;
			&:first-child {
				margin-left: 1px;
			}
			> a {
				position: relative;
				display: block;
				padding: 14px;
				line-height: 1.4;
				width: 226px;
				height: 100px;
				text-align: left;
				color: $mainColor03;
				font-size: 13px;
				background: #fff;
				text-decoration: none;
				&:before {
					content:"\f138";
					font-family: FontAwesome;
					margin-right: 6px;
					color: inherit;
					font-size: inherit;
				}
				&:after {
					position: absolute;
					bottom: 13px;
					left: 0;
					display: inline-block;
					content:"";
					width: 100%;
					height: 56px;
				}
			}
			&:nth-child(5n) {
				margin-right: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
		//プルダウンアイコン ： 製品情報
		.navIcon01-1 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-product.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon01-2 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-product02.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon01-3 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-product03.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon01-4 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-product04.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon01-5 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-product05.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon01-6 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-company04.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		//プルダウンアイコン ： 会社案内
		.navIcon02-1 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-company.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon02-2 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-company02.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon02-3 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-company03.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon02-4 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-company04.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon02-5 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-company05.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		//プルダウンアイコン ： サポート
		.navIcon03-1 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-support.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon03-2 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-support02.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon03-3 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-support03.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon03-4 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-support04.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon03-5 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-support05.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		//プルダウンアイコン ： 採用情報
		.navIcon04-1 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-recruit.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon04-2 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-recruit02.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon04-3 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-recruit03.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon04-4 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-company04.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		//プルダウンアイコン ： お問い合わせ
		.navIcon05-1 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-form.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon05-2 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-form02.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
		.navIcon05-3 {
			a {
				&:after {
					background: url('./../images/bullets/icon_menu-form03.png') center no-repeat;
					-webkit-background-size: contain;
					background-size: contain;
				}
			}
		}
	}
}
/* サブメニュー（SPのみ表示）
--------------------------------------*/
.gSubNav {
	display: none;
}
.gSubNav02 {
	display: none;
}

/* グローバルナビ固定処理用Class > .isFixedPc
-------------------------------------------------------*/
.isFixedPc {
    position: fixed;
    top: 0 !important;
    left: 0;
    width: 100%;
    z-index: 2030;
    margin-top: 0 !important;
}
/* グローバルナビ固定処理用Class > .isFixedSp
-------------------------------------------------------*/
.isFixedSp {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	// height: 100%;
    z-index: 2010;
    background-color: #fff;
   -webkit-box-shadow: $box-shadow;
           box-shadow: $box-shadow;
}
@media screen and (max-width: $breakpoint-sp) {
	.gNav {
		position: fixed;
		top: 0;
		right: -100%;
		width: 80%;
		height: 100%;
		z-index: 3000;
		padding: 0;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		-webkit-transition: right .4s;
		transition: right .4s;
		&_list {
			width: 100%;
			text-align: left;
		}
		&_item {
			display: block;
			// -webkit-box-shadow: 0 1px 0 #fff;
			//         box-shadow: 0 1px 0 #fff;
			border-bottom: 1px solid #000;
			> a {
				padding: 16px 20px 14px 8px;
				width: 100%;
				height: auto;
				line-height: 1.2;
				background: none;
				text-indent: 0;
				white-space: normal;
				overflow: auto;
				&:hover ,
				&:active {
					background: none;
				}
				&:before ,
				&:after {
					display: none;
				}
				&:after {
					position: absolute;
					display: inline-block;
					content:"\f13a";
					font-family: FontAwesome;
					color: inherit;
					font-size: inherit;
					top: 50%;
					right: 15px;
					margin-top: -7px;
					width: auto;
					height: auto;
					background: none;
				}
				//アコーディオン時のアイコン処理
				&.active {
					&:after {
						content:"\f139";
					}
				}
			}
			&-05 {
				a {
					&:after {
						display: none;
					}
				}
			}
			//SPのみ表示のメニューリンク
			&-spMenu {
				display: block;
			}
			//HOMEリンク
			&-home {
				// display: none;
				a {
					&:after {
						display: none;
					}
				}
			}
		}
		&-isOpen {
			right: 0;
			-webkit-box-shadow: 0 0 5px #555;
			box-shadow: 0 0 5px #555;
		}
		&_close {
			margin: 30px auto 10px;
			width: 80%;
			color: #333;
			text-align: center;
			> a {
				display: block;
				padding: 12px;
				color: inherit;
				font-size: 16px;
				background: $mainColor05;
				border-radius: 4px;
				border: 1px solid #000;
				text-decoration: none;
				&:before {
					display: inline-block;
					margin: 0 8px 0 0;
					font-family: FontAwesome;
					font-size: inherit;
					content: "\f057";
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
				}
			}
		}
		&::-webkit-scrollbar {
			cursor: pointer;
			width: 10px;
		}
		&::-webkit-scrollbar-track {
			background: $mainColor08;
			cursor: pointer;
		}
		&::-webkit-scrollbar-thumb {
			background: #000;
			cursor: pointer;
		}
	}
	// ハンバーガーメニューボタン
	// --------------------------------------
	.gNavBtn {
		position: fixed;
		top: 14px;
		left: 9px;
		display: block;
		margin: 0;
		vertical-align: middle;
		z-index: 3050;
		> a {
			position: relative;
			display: block;
			width: 40px;
			height: 40px;
			border-radius: 4px;
			background: $mainColor01;
			border: 1px solid $mainColor05;
			&:hover {
				opacity: 1;
			}
		}
		> a span,
		> a:before,
		> a:after {
			display: block;
			width: 18px;
			height: 1px;
			background: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: 0px 0 0 -9px;
			-webkit-transition: all .4s;
			transition: all .4s;
		}
		> a:before, > a:after {
			content: '';
		}
		> a:before {
			-webkit-transform: translateY(-8px);
			-ms-transform: translateY(-8px);
			transform: translateY(-8px);
		}
		> a:after {
			-webkit-transform: translateY(8px);
			-ms-transform: translateY(8px);
			transform: translateY(8px);
		}
	}
	.gNavBtn-isOpen a span ,
	.gNavBtn02-isOpen a span {
		opacity: 0;
	}
	.gNavBtn-isOpen a:before ,
	.gNavBtn02-isOpen a:before {
		-webkit-transform: translateY(0) rotate(-45deg);
		-ms-transform: translateY(0) rotate(-45deg);
		transform: translateY(0) rotate(-45deg);
	}
	.gNavBtn-isOpen a:after ,
	.gNavBtn02-isOpen a:after {
		-webkit-transform: translateY(0) rotate(45deg);
		-ms-transform: translateY(0) rotate(45deg);
		transform: translateY(0) rotate(45deg);
	}
	.gNavOverlay ,
	.gNavOverlay02 {
		background: #fff;
		opacity: 0;
		z-index: 2900;
		-webkit-transition: all .4s;
		transition: all .4s;
	}
	.gNavOverlay-isOpen ,
	.gNavOverlay02-isOpen {
		display: block;
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: .8;
	}
	// ドロップダウンメニュー
	// --------------------------------------
	.gNavDropdown {
		position: relative;
		padding: 0;
		min-height: 0;
		top: auto;
		left: auto;
		> ul {
			margin: 0 auto;
			width: 100%;
			> li {
				display: block;
				margin: 0;
				width: 100%;
				border-bottom: 1px solid $mainColor08;
				&:first-child {
					margin: 0;
				}
				> a {
					position: relative;
					display: block;
					padding: 16px 28px 14px 8px;
					line-height: 1.4;
					width: 100%;
					height: auto;
					text-align: left;
					&:before {
						position: absolute;
						display: inline-block;
						content:"\f101";
						font-family: FontAwesome;
						color: inherit;
						font-size: inherit;
						top: 50%;
						right: 15px;
						margin-top: -7px;
						margin-right: 0;
						width: auto;
						height: auto;
						background: none;
					}
					&:after {
						display: none;
					}
				}
			}
		}
	}
	// サブメニュー（SPのみ表示）
	// --------------------------------------
	.gSubNav {
		margin-top: 20px;
		display: block;
		&_list {
			display: block;
			margin: 0;
			background-color: $mainColor05;
		}
		&_item {
			border-bottom: 1px solid $mainColor08;
			> a {
				position: relative;
				display: block;
				padding: 10px 20px 8px 8px;
				width: 100%;
				height: auto;
				line-height: 1.2;
				color: inherit;
				background: none;
				text-indent: 0;
				white-space: normal;
				overflow: auto;
				text-decoration: none;
				&:after {
					position: absolute;
					display: inline-block;
					content:"\f101";
					font-family: FontAwesome;
					color: inherit;
					font-size: inherit;
					top: 50%;
					right: 15px;
					margin-top: -7px;
					width: auto;
					height: auto;
					background: none;
				}
			}
		}
	}
	.gSubNav02 {
		margin-top: 20px;
		display: block;
		&_list {
			display: block;
			margin: 0;
			background-color: #fff;
		}
		&_item {
			border-bottom: 1px solid $mainColor08;
			> a {
				position: relative;
				display: block;
				padding: 10px 20px 8px 8px;
				width: 100%;
				height: auto;
				line-height: 1.2;
				color: inherit;
				background: none;
				text-indent: 0;
				white-space: normal;
				overflow: auto;
				text-decoration: none;
				&:before {
					position: relative;
					content: "";
					display: inline-block;
					vertical-align: middle;
					margin-right: 8px;
				}
				&:after {
					position: absolute;
					display: inline-block;
					content:"\f101";
					font-family: FontAwesome;
					color: inherit;
					font-size: inherit;
					top: 50%;
					right: 15px;
					margin-top: -7px;
					width: auto;
					height: auto;
					background: none;
				}
			}
			&-jp {
				> a {
					&:before {
						width: 29px;
						height: 19px;
						background: url('./../images/bullets/icon-language_jp.png') no-repeat;
						-webkit-background-size: cover;
						background-size: cover;
					}
				}
			}
			&-en {
				> a {
					&:before {
						width: 29px;
						height: 19px;
						background: url('./../images/bullets/icon-language_en.png') no-repeat;
						-webkit-background-size: cover;
						background-size: cover;
					}
				}
			}
			&-ch {
				> a {
					&:before {
						width: 29px;
						height: 19px;
						background: url('./../images/bullets/icon-language_ch.png') no-repeat;
						-webkit-background-size: cover;
						background-size: cover;
					}
				}
			}
		}
	}
}

/*
グローバルフッター ： ページトップリンク

pageTop

Markup:
<div class="pageTop">
	<a href="#top"><span>PAGETOP</span></a>
</div><!-- /.pageTop -->

Styleguide 2.5
============================================================*/
.pageTop {
	text-align: right;
	// position: absolute;
	position: fixed;
	bottom: 0;
	right: 5%;
	width: 36px;
	height: 36px;
	z-index: 1100;
	a {
		position: relative;
		display: inline-block;
		width: 36px;
		height: 36px;
		background: #3160A8;
		-webkit-border-radius: 3px;
		        border-radius: 3px;
		text-decoration: none;
		text-align: center;
		&:before {
			position: relative;
			top: -2px;
			content:"\f106";
			font-family: FontAwesome;
			color: #fff;
			font-size: 26px;
			font-weight: normal;
		}
	}
	span {
		@include textHidden;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.pageTop {
		position: fixed !important;
		bottom: 10px !important;
	}
}

/*
グローバルフッター ： バナーエリア

gFooterBnr

Markup:
<footer class="gFooterBnr">
	<div class="gFooterBnr_inner">
		<div class="gFooterBnrNav">
			<ul class="gFooterBnrNav_list">
				<li class="gFooterBnrNav_item"><a href=""><img src="./../assets/images/banner/banner.png" width="226" height="81" alt=""></a></li>
				<li class="gFooterBnrNav_item"><a href=""><img src="./../assets/images/banner/banner02.png" width="226" height="81" alt=""></a></li>
				<li class="gFooterBnrNav_item"><a href=""><img src="./../assets/images/banner/banner03.png" width="226" height="81" alt=""></a></li>
				<li class="gFooterBnrNav_item"><a href=""><img src="./../assets/images/banner/banner04.png" width="226" height="81" alt=""></a></li>
				<li class="gFooterBnrNav_item"><a href=""><img src="./../assets/images/banner/banner05.png" width="226" height="81" alt=""></a></li>
			</ul><!-- /.gFooterBnrNav_list -->
		</div><!-- /.gFooterBnrNav -->
	</div><!-- /.gFooterBnr_inner -->
</footer><!-- /.gFooterBnr -->

Styleguide 2.6
============================================================*/
.gFooterBnr {
	margin-top: 73px;
	&_inner {
		margin: 0 auto;
		width: $topMainWidth;
	}
	&Nav {
		&_list {
			@include inline_block-p;
			margin: -7px 0 0 -7px;
		}
		&_item {
			@include inline_block;
			vertical-align: middle;
			margin: 7px 0 0 7px;
			width: 226px;
			> a {
				display: block;
			}
			img {
				width: auto;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.gFooterBnr {
		margin-top: 45px;
		&_inner {
			width: 100%;
		}
		&Nav {
			&_list {
				margin: 0 0 0 -5px;
				padding: 0 5px;
			}
			&_item {
				margin: 5px 0 0 0;
				padding-left: 5px;
				width: 50%;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}

/*
グローバルフッター

gFooter

Markup:
<footer class="gFooter">
	<div class="gFooter_inner">
		<nav class="gFooterNav">
			<div class="gFooterNav_inner">
				<div class="gFooterNav_block" data-mh="gFooterNav_block">
					<dl class="siteNav">
						<dt><a href="<%= siteRoot %>">日信化学工業ホーム</a></dt>
						<dt><a href="<%= siteRoot %>products/">製品情報</a></dt>
						<dd>
							<ul class="siteNav_list">
								<li class="siteNav_item"><a href="">ソルバイン</a></li>
								<li class="siteNav_item"><a href="">ホットメルト</a></li>
								<li class="siteNav_item"><a href="">シャリーヌ</a></li>
								<li class="siteNav_item"><a href="">サーフィノール</a></li>
								<li class="siteNav_item"><a href="">ビニブラン</a></li>
								<li class="siteNav_item"><a href="">オルフィン</a></li>
								<li class="siteNav_item"><a href="">ビニブラン700シリーズ</a></li>
								<li class="siteNav_item"><a href="">シルフェイス</a></li>
								<li class="siteNav_item"><a href="">ビニブラン1540シリーズ</a></li>
							</ul><!-- /.siteNav_list -->
						</dd>
					</dl>
				</div><!-- /.gFooterNav_block -->
				<div class="gFooterNav_block" data-mh="gFooterNav_block">
					<dl class="siteNav">
						<dt><a href="">カタログ</a></dt>
						<dt><a href="<%= siteRoot %>company/">会社案内</a></dt>
						<dd>
							<ul class="siteNav_list">
								<li class="siteNav_item"><a href="<%= siteRoot %>company/detial.html">ご挨拶</a></li>
								<li class="siteNav_item"><a href="">環境方針</a></li>
								<li class="siteNav_item"><a href="">会社概要・沿革</a></li>
								<li class="siteNav_item"><a href="">会社組織図</a></li>
								<li class="siteNav_item"><a href="">経営目標</a></li>
								<li class="siteNav_item"><a href="">研究開発・品質管理</a></li>
								<li class="siteNav_item"><a href="">品質方針</a></li>
								<li class="siteNav_item"><a href="">売上・利益</a></li>
							</ul><!-- /.siteNav_list -->
						</dd>
					</dl>
				</div><!-- /.gFooterNav_block -->
				<div class="gFooterNav_block" data-mh="gFooterNav_block">
					<dl class="siteNav siteNav-01">
						<dt><a href="<%= siteRoot %>support/">サポート</a></dt>
						<dt><a href="<%= siteRoot %>form/">お問い合わせ</a></dt>
						<dd>
							<ul class="siteNav_list">
								<li class="siteNav_item"><a href="<%= siteRoot %>products/emulsion.html">エマルジョンについて</a></li>
								<li class="siteNav_item"><a href="<%= siteRoot %>news/">ニュース</a></li>
								<li class="siteNav_item"><a href="<%= siteRoot %>recruit/">採用情報</a></li>
								<li class="siteNav_item"><a href="<%= siteRoot %>company/csr.html">CSR活動</a></li>
								<li class="siteNav_item"><a href="">個人情報保護ポリシー</a></li>
							</ul><!-- /.siteNav_list -->
						</dd>
					</dl>
					<dl class="siteNav siteNav-language">
						<dt></dt>
						<dd><a href="">English</a></dd>
						<dd><a href="">Chinese</a></dd>
					</dl>
				</div><!-- /.gFooterNav_block -->
			</div><!-- /.gFooterNav_inner -->
		</nav><!-- /.gFooterNav -->
		<div class="logomark">
			<div class="logomark_inner">
				<p><a href=""><img src="./../assets/images/global/logo_cqa.png" width="68" height="50" alt=""></a></p>
				<p><a href=""><img src="./../assets/images/global/logo_lab.png" width="56" height="73" alt=""></a></p>
			</div><!-- /.logomark_inner -->
		</div><!-- /.logomark -->
		<p class="copyright">© Nissin Chemical co.,ltd. All rights reserved. </p>
	</div><!-- /.gFooter_inner -->
</footer><!-- /.gFooter -->

Styleguide 2.7
============================================================*/
.gFooter {
	$this: &;
	margin-top: 47px;
	&_inner {
		margin: 0 auto;
		padding: 27px 0 25px;
		width: $topMainWidth;
		border-top: 1px solid $mainColor07;
	}
	&Nav {
		font-size: 13px;
		&_inner {
			margin-top: 0;
			&:before,
			&:after {
				content: ".";
				display: block;
				height: 0;
				visibility: hidden;
			}
			&:after {
				clear: both;
			}
		}
		&_block {
			position: relative;
			float: left;
			padding: 0 20px 0 40px;
			width: 385px;
			border-left: 1px solid $mainColor08;
			&:first-child {
				border-left: none;
			}
		}
	}
	.siteNav {
		position: relative;
		&:before,
		&:after {
			content: ".";
			display: block;
			height: 0;
			visibility: hidden;
		}
		&:after {
			clear: both;
		}
		a ,
		span {
			position: relative;
			display: inline-block;
			color: #333;
			text-decoration: none;
		}
		> dt {
			margin-top: 33px;
			font-weight: bold;
			&:first-child {
				margin-top: 0;
			}
			a ,
			span {
				&:before {
					display: inline-block;
					position: absolute;
					top: 0px;
					left: -17px;
					content: "\f138";
					font-family: FontAwesome;
					color: $mainColor01;
					font-size: inherit;
				}
			}
		}
		&_list {
			@include inline_block-p;
			margin: 0 0 0 -25px;
		}
		&_item {
			@include inline_block;
			padding: 0 0 0 25px;
			width: 50%;
		}
		//ブロック3番目のみfloat設定
		&-01 {
			width: 140px;
			float: left;
			dt {
				margin-top: 5px;
				&:first-child {
					margin-top: 0;
				}
			}
			dd {
				margin-top: 33px;
			}
			.siteNav {
				&_item {
					width: 100%;
				}
			}
		}
		&-language {
			float: right;
			width: 140px;
		}
	}
	//認証マーク絶対配置
	.logomark {
		position: relative;
		margin: 0 auto;
		width: $topMainWidth;
		&_inner {
			@include inline_block-p;
			text-align: right;
			position: absolute;
			bottom: 0;
			right: 0;
			// width: 160px;
			p {
				@include inline_block;
				vertical-align: middle;
				margin: 0;
				margin-left: 33px;
				&:first-child {
					margin: 0;
				}
			}
		}
	}
	.copyright {
		text-align: center;
		color: #666;
		font-size: 9px;
		&:not(:first-child) {
			margin-top: 25px;
			padding-top: 18px;
			border-top: 1px solid $mainColor07;
		}
	}

	&-type01 {
		border: none;
		#{$this}_inner {
			padding-top: 0;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.gFooter {
		$this: &;
		margin-top: 0;
		&_inner {
			padding: 27px 0 25px;
			width: 100%;
			border-top: none;
		}
		&Nav {
			display: none;
		}
		//認証マーク絶対配置
		.logomark {
			position: relative;
			margin: 0 auto;
			width: 100%;
			&_inner {
				text-align: center;
				position: relative;
				bottom: auto;
				right: auto;
			}
		}
		.copyright {
			padding: 0 10px;
			text-align: left;
			&:not(:first-child) {
				margin-top: 0;
				padding-top: 24px;
				border-top: none;
			}
		}

		&-type01 {
			&:not(:first-child) {
				margin-top: 25px;
				padding-top: 10px;
				border-top: 1px solid $mainColor07;
			}
			.copyright {
				text-align: center;
			}
		}
	}
}

/*
コンテンツ内　サイドメニュー

sideNav

Markup:
<nav class="sideNav">
	<h2 class="sideNav_title"><span>会社案内</span></h2>
	<ul class="sideNav_list">
		<li class="sideNav_item"><a href="<%= siteRoot %>company/index.html">会社案内トップ</a></li>
		<li class="sideNav_item active">
			<span>会社概要</span>
			<ul class="sideSubMenu_list">
				<li class="sideSubMenu_item active"><span>会社案内（ご挨拶）</span></li>
				<li class="sideSubMenu_item"><a href="">会社概要・沿革</a></li>
				<li class="sideSubMenu_item"><a href="">経営目標</a></li>
				<li class="sideSubMenu_item"><a href="">品質方針</a></li>
				<li class="sideSubMenu_item"><a href="">環境方針</a></li>
				<li class="sideSubMenu_item"><a href="">会社組織図</a></li>
				<li class="sideSubMenu_item"><a href="">研究開発・品質管理</a></li>
				<li class="sideSubMenu_item"><a href="">売上・利益</a></li>
			</ul><!-- /.sideSubMenu_list -->
		</li>
		<li class="sideNav_item"><a href="<%= siteRoot %>company/csr.html">CSR活動</a></li>
		<li class="sideNav_item"><a href="<%= siteRoot %>company/movie.html">ムービー</a></li>
		<li class="sideNav_item"><a href="<%= siteRoot %>company/information.html">日信化学ってどんな会社？</a></li>
	</ul><!-- /.sideNav_list -->
</nav><!-- /.sideNav -->

Styleguide 2.8
============================================================*/
.sideNav {
	&_title {
		position: relative;
		padding: 16px 17px 14px;
		color: #fff;
		font-size: 15px;
		font-weight: normal;
		background-color: $mainColor04;
	}
	&_list {
		margin-top: 0;
	}
	&_item {
		position: relative;
		display: block;
		margin-top: -1px;
		background-color: $mainColor06;
		border-top: 1px dotted #BBBBBB;
		border-bottom: 1px dotted #BBBBBB;
		transition: .3s;
		&:hover {
			opacity: .5;
		}
		&:first-child {
			border-top: none;
		}
		> a ,
		> span {
			position: relative;
			display: block;
			padding: 19px 15px 17px 32px;
			line-height: 1.4;
			height: auto;
			color: inherit;
			text-align: left;
			text-decoration: none;
			&:before {
				position: absolute;
				top: 19px;
				left: 16px;
				display: inline-block;
				content:"\f138";
				font-family: FontAwesome;
				color: inherit;
				font-size: inherit;
				// margin-top: -10px;
				width: auto;
				height: auto;
				color: $mainColor04;
			}
		}
		&.active {
			background-color: #fff;
			border-left: 1px solid $mainColor08;
			border-right: 1px solid $mainColor08;
			transition: 0;
			&:hover {
				opacity: 1;
			}
			> a ,
			> span {
				// color: #999;
				// &:before {
				// 	color: #8EC9DF;
				// }
			}
		}
	}
}
.sideMenu {
$this: &;
	margin-top: 0;
	&_btn {
		position: relative;
		display: block;
		margin-top: -1px;
		padding: 19px 15px 17px 32px;
		line-height: 1.4;
		height: auto;
		color: inherit;
		text-align: left;
		text-decoration: none;
		background-color: $mainColor06;
		border-top: 1px dotted #BBBBBB;
		border-bottom: 1px dotted #BBBBBB;
		cursor: pointer;
		&:before {
			position: absolute;
			top: 19px;
			left: 16px;
			display: inline-block;
			content:"\f138";
			font-family: FontAwesome;
			color: inherit;
			font-size: inherit;
			// margin-top: -10px;
			width: auto;
			height: auto;
			color: $mainColor04;
		}
		&.active {
			background-color: $mainColor05;
			&:before {
				content:"\f13a";
			}
		}
	}
	&_list {
		margin-top: 0;
	}
	&_item {
		position: relative;
		margin-top: -1px;
		padding: 10px 15px;
		display: block;
		border-left: 1px solid #BBBBBB;
		border-right: 1px solid #BBBBBB;
		border-top: 1px dotted #BBBBBB;
		border-bottom: 1px dotted #BBBBBB;
		&:first-child {
			border-top: none;
		}
		> span {
			display: inline-block;
			color: #999;
			font-size: 12px;
		}
		> a {
			position: relative;
			display: block;
			margin: -2px -15px -2px 0;
			padding: 2px 15px 2px 18px;
			line-height: 1.4;
			height: auto;
			color: #333;
			font-size: 13px;
			text-align: left;
			text-decoration: none;
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				display: inline-block;
				content:"\f105";
				font-family: FontAwesome;
				color: inherit;
				font-size: inherit;
				margin-top: 3px;
				color: $mainColor04;
			}
		}
		&-active {
			background-color: #084197;
			a {
				color: #fff;
				&:before {
					color: inherit;
				}
			}
		}
	}
	&.active {
		background-color: #fff;
		border-left: 1px solid $mainColor08;
		border-right: 1px solid $mainColor08;
		transition: 0;
		&:hover {
			opacity: 1;
		}
		> a ,
		> span {
			// color: #999;
			// &:before {
			// 	color: #8EC9DF;
			// }
		}
	}
}
.sideSubMenu {
	&_list {
		margin: 0 0 17px 32px;
	}
	&_item {
		position: relative;
		display: block;
		padding: 2px 0;
		> a ,
		> span {
			position: relative;
			display: block;
			padding: 3px 18px;
			height: auto;
			color: #333;
			font-size: 13px;
			text-align: left;
			text-decoration: none;
			&:before {
				position: absolute;
				top: 3px;
				left: 0;
				display: inline-block;
				content:"\f0da";
				font-family: FontAwesome;
				color: inherit;
				font-size: inherit;
				// margin-top: -10px;
				color: $mainColor04;
			}
		}
		&.active {
			> a ,
			> span {
				color: $mainColor04;
				font-weight: bold;
				background-color: $mainColor05;
				margin-right: 10px;
				&:before {
					color: #8EC9DF;
					left: 5px;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
コンテンツ内　サイドメニュー02

sideNav02

Markup:
<nav class="sideNav02">
	<dl class="sideMenu02">
		<!-- ______ 製品名から探す ______ -->
		<dt class="sideMenu02_btn acd_btn active">製品名から探す</dt>
		<dd class="sideMenu02_panel acd_panel">
			<ul class="sideMenu02_list">
				<li class="sideMenu02_item">
					<span>塩化ビニル・酢酸ビニル系変性樹脂</span><br>
					<a href="">ソルバイン</a>
				</li>
				<li class="sideMenu02_item">
					<span>各種合成樹脂エマルジョン</span><br>
					<a href="">ビニブラン</a>
					<ul class="sideSubMenu02_list">
						<li class="sideSubMenu02_item"><a href="">塩化ビニル系エマルジョン</a></li>
						<li class="sideSubMenu02_item"><a href="">酢酸ビニル系エマルジョン</a></li>
						<li class="sideSubMenu02_item"><a href="">アクリル系エマルジョン</a></li>
					</ul><!-- /.sideMenu02_list -->
				</li>
				<li class="sideMenu02_item">
					<span>塩化ビニル・アクリル系ハイブリッド樹脂</span><br>
					<a href="">ビニブラン700シリーズ</a>
				</li>
				<li class="sideMenu02_item">
					<span>高性能型紙管用接着剤</span><br>
					<a href="">ビニブラン1540シリーズ</a>
				</li>
				<li class="sideMenu02_item">
					<span>シリコーン・アクリル系ハイブリッド樹脂</span><br>
					<a href="">シャリーヌ</a>
				</li>
				<li class="sideMenu02_item">
					<span>ホットメルト接着剤</span><br>
					<a href="">ホットメルト</a>
				</li>
				<li class="sideMenu02_item">
					<span>ステアリン酸亜鉛ブロック成型体</span><br>
					<a href="">固形潤滑剤</a>
				</li>
				<li class="sideMenu02_item">
					<span>アセチレン系化学品</span><br>
					<a href="">サーフィノール</a>
					<a href="">オルフィン</a>
				</li>
				<li class="sideMenu02_item">
					<span>シリコーン系添加剤</span><br>
					<a href="">シルフェイス</a>
				</li>
			</ul><!-- /.sideMenu02_list -->
		</dd><!-- /.sideMenu02_panel -->
		<!-- ______ 用途から探す ______ -->
		<dt class="sideMenu02_btn acd_btn">用途から探す</dt>
		<dd class="sideMenu02_panel acd_panel">
			<ul class="sideMenu02_list">
				<li class="sideMenu02_item">
					<a href="">紙接着</a>
				</li>
				<li class="sideMenu02_item">
					<a href="">紙加工</a>
				</li>
				<li class="sideMenu02_item">
					<a href="">木工・建築</a>
				</li>
				<li class="sideMenu02_item">
					<a href="">樹脂加工</a>
				</li>
				<li class="sideMenu02_item">
					<a href="">繊維加工</a>
				</li>
				<li class="sideMenu02_item">
					<a href="">粘着</a>
				</li>
				<li class="sideMenu02_item">
					<a href="">化粧品</a>
				</li>
			</ul><!-- /.sideMenu02_list -->
		</dd><!-- /.sideMenu02_panel -->
		<!-- ______ 機能から探す ______ -->
		<dt class="sideMenu02_btn acd_btn">機能から探す</dt>
		<dd class="sideMenu02_panel acd_panel">
			<ul class="sideMenu02_list">
				<li class="sideMenu02_item">
					<span>見出し見出し見出し見出し</span><br>
					<a href="">リンクリンクリンク</a>
				</li>
				<li class="sideMenu02_item">
					<span>見出し見出し見出し見出し</span><br>
					<a href="">リンクリンクリンク</a>
					<ul class="sideSubMenu02_list">
						<li class="sideSubMenu02_item"><a href="">サブリンクサブリンク</a></li>
						<li class="sideSubMenu02_item"><a href="">サブリンクサブリンク</a></li>
						<li class="sideSubMenu02_item"><a href="">サブリンクサブリンク</a></li>
					</ul><!-- /.sideMenu02_list -->
				</li>
				<li class="sideMenu02_item">
					<span>見出し見出し見出し見出し</span><br>
					<a href="">リンクリンクリンク</a>
				</li>
			</ul><!-- /.sideMenu02_list -->
		</dd><!-- /.sideMenu02_panel -->
		<!-- ______ エマルジョンとは？ ______ -->
		<dt class="sideMenu02_btn">エマルジョンとは？</dt>
	</dl><!-- /.sideMenu02 -->
</nav><!-- /.sideNav02 -->

Styleguide 2.9
============================================================*/
.sideNav02 {
	&_title {
		position: relative;
		padding: 16px 17px 14px;
		color: #fff;
		font-size: 15px;
		font-weight: normal;
		background-color: $mainColor04;
	}
}
.sideMenu02 {
$this: &;
	margin-top: 0;
	&_btn ,
	&_btn02 {
		position: relative;
		display: block;
		margin-top: -1px;
		padding: 16px 32px 16px 16px;
		line-height: 1.4;
		height: auto;
		color: $mainColor02;
		text-align: left;
		background-color: $mainColor05;
		border: 1px solid $mainColor05;
		border-top: 1px solid #fff;
		text-decoration: none;
		cursor: pointer;
		&:after {
			position: absolute;
			display: inline-block;
			content:"\f107";
			font-family: FontAwesome;
			color: inherit;
			font-size: inherit;
			top: 50%;
			right: 15px;
			margin-top: -7px;
			width: auto;
			height: auto;
			background: none;
		}
		&.active {
			&:after {
				content:"\f106";
			}
		}
	}
	&_btn02 {
		> a {
			margin: -16px -32px -16px -16px;
			padding: 16px 32px 16px 16px;
			text-decoration: none;
		}
		&:after {
			content:"\f105";
		}
	}
	&_list {
		margin-top: 0;
	}
	&_item ,
	&_item02 {
		position: relative;
		margin-top: -1px;
		padding: 10px 15px;
		display: block;
		border-left: 1px solid #BBBBBB;
		border-right: 1px solid #BBBBBB;
		border-top: 1px dotted #BBBBBB;
		border-bottom: 1px dotted #BBBBBB;
		&:first-child {
			border-top: none;
		}
		> span {
			display: inline-block;
			color: #999;
			font-size: 12px;
		}
		> a {
			position: relative;
			display: block;
			margin: -2px -15px -2px 0;
			padding: 2px 15px 2px 18px;
			line-height: 1.4;
			height: auto;
			color: #333;
			font-size: 15px;
			text-align: left;
			text-decoration: none;
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				display: inline-block;
				content:"\f138";
				font-family: FontAwesome;
				color: inherit;
				font-size: inherit;
				// margin-top: -10px;
				color: $mainColor04;
			}
		}
	}
	&_item02 {
		> a {
			font-size: 13px;
			&:before {
				top: 0;
				left: 0;
				content:"\f105";
				margin-top: 3px;
				font-family: FontAwesome;
			}
		}
		&-active {
			background-color: #084197;
			a {
				color: #fff;
				&:before {
					color: inherit;
				}
			}
		}
	}
	> dd:empty {
		display: none;
	}
}
.sideSubMenu02 {
	&_list {
		margin: 0 0 0 20px;
	}
	&_item {
		position: relative;
		display: block;
		padding: 1px 0;
		> a {
			position: relative;
			display: block;
			padding: 1px 0 1px 18px;
			height: auto;
			color: #333;
			font-size: 13px;
			text-align: left;
			text-decoration: none;
			&:before {
				position: absolute;
				top: 1px;
				left: 0;
				display: inline-block;
				content:"\f0da";
				font-family: FontAwesome;
				color: inherit;
				font-size: inherit;
				// margin-top: -10px;
				color: $mainColor04;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.sideMenu02 {
	$this: &;
		&_item {
			> a {
				padding: 4px 0 4px 18px;
				&:before {
					top: 2px;
				}
			}
		}
	}
	.sideSubMenu02 {
		&_item {
			> a {
				padding: 2px 0 2px 18px;
				&:before {
					top: 2px;
				}
			}
		}
	}
}
