@charset "UTF-8";
@import '_config.scss';
/*============================================================
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
============================================================*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	margin:0;
	padding:0;
	border:0;
	outline:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
body {
	line-height:1;
}
article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
	display:block;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
blockquote, q {
	quotes:none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content:'';
	content:none;
}
a {
	margin:0;
	padding:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
	outline:none;
	> img {
		border: 0;
		outline:none;
	}
}
/* change colours to suit your needs */
ins {
	text-decoration:none;
}
/* change colours to suit your needs */
area {
	cursor: pointer;
}
mark {
}
del {
	text-decoration: line-through;
}
abbr[title], dfn[title] {
}
table {
	border-collapse:collapse;
	border-spacing:0;
}
/* change border colour to suit your needs */
hr {
	display:block;
	height:1px;
	border:0;
	border-top:1px solid #cccccc;
	margin:1em 0;
	padding:0;
}
input, select {
	vertical-align:middle;
}
li {
	display: block;
}
input[type="check"],
input[type="radio"] {
	margin: 0 5px 0 0;
	vertical-align: middle;
}

/* 再定義
-------------------------------------------------------------*/
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
body {
	min-width: 1330px;
	font-family: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro','Hiragino Kaku Gothic Pro',Verdana,Arial,sans-serif,'ＭＳ Ｐゴシック';
	font-size: 14px;
	line-height: 1.6;
	color: $baseColor;
	text-align: left;
	-webkit-text-size-adjust: 100%;
	word-break: break-all;
	// overflow-x: hidden;
}
h1 ,
h2 ,
h3 ,
h4 ,
h5 {
	font-weight: normal;
}
sub {
	vertical-align: sub;
	font-size: 12px;
}
sup {
	vertical-align: super;
	font-size: 12px;
}
img {
	vertical-align:bottom;
	max-width: 100%;
	// max-height: 100%;
	// width: auto;
	height: auto;
}
hr { margin: 20px 0 0; }
p,ul,ol,table,dl,figure {
	margin-top: 20px;
}
b,strong {
	font-weight: bold;
}
em {
	font-style: italic;
}
ol {
	margin-left: 1.5em;
	li {
		display: list-item;
	}
}
ol:not([class]) ,
ul:not([class]) {
	margin-left: 1.5em;
	li {
		display: list-item;
		margin: 5px 0 0;
	}
}
figcaption {
	margin: 5px 0 0;
	font-size: 12px;
}
table{
	font-size:100%;
}
@media screen and (max-width: $breakpoint-sp) {
	html {
		&.html-isFixed {
			overflow-y: hidden;
		}
	}
	body {
		min-width: 0;
		// overflow-x: hidden;
		&.spMode-navIsOpen {
			overflow-y: hidden;
			// position: fixed;
			// width: 100%;
			// height: 100%;
		}
		&::-webkit-scrollbar {
			cursor: pointer;
			width: 10px;
		}
		&::-webkit-scrollbar-track {
			background: #F8F8F8;
			cursor: pointer;
		}
		&::-webkit-scrollbar-thumb {
			border-image-source: initial;
			border-image-slice: initial;
			border-image-width: initial;
			border-image-outset: initial;
			border-image-repeat: initial;
			z-index: 9999;
			background-clip: content-box;
			width: 5px;
			background-color: rgba(0, 0, 0, 0.4);
			border-width: 2px;
			border-style: solid;
			border-color: transparent;
			border-radius: 12px;
			margin: 1px !important;
		}
	}
}

/* wysiwygエディタ用リセット
--------------------------------------*/
table:not([class]) {
	border-width: 1px;
	border-style: solid;
	tbody th,
	tbody td {
		border-width: 1px;
		border-style: solid;
		padding: 4px;
		vertical-align: middle;
	}
	tbody th {
		text-align: center;
		font-weight: bold;
		background-color: #F0F0F0;
	}
}
dl:not([class]) {
	> dt {
		position: relative;
		margin-top: 10px;
		padding: .35em 1em;
		font-weight: bold;
		border-bottom: 2px solid #E6F3F8;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			width: 5px;
			height: 100%;
			background-color: $mainColor04;
		}
	}
	> dd {
		margin-top: 5px;
		padding-left: 2em;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	dl:not([class]) {
		> dd {
			padding-left: 1em;
		}
	}
}

/* リンク関連
--------------------------------------*/
a {
	color: $linkColor;
	cursor: pointer;
	&:visited {
	}
	&:hover {
		opacity: .7;
	}
	&:active {
	}
}

/* フォーム関連
--------------------------------------*/
// UIリセット
button ,
input[type="file"] ,
input[type="radio"] ,
input[type="checkbox"] {
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	outline: 0;
	border: none;
	cursor: pointer;
}

// デフォルト設定
input[type="text"],
input[type="file"],
input[type="email"],
input[type="tel"],
input[type="password"],
select ,
textarea {
	padding: 6px 10px 3px;
	min-height: 30px;
	color: inherit;
	font-size: 13px;
	// font-family: "Hiragino Sans","ヒラギノ角ゴシック","Hiragino Kaku Gothic Pro","ヒラギノ角ゴ Pro W3","メイリオ","Meiryo","ＭＳ Ｐゴシック", Osaka, sans-serif;
	border: 1px solid $mainColor06;
	-webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, .4);
	        box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, .4);
	-webkit-border-radius: 3px;
	        border-radius: 3px;
	background-color: #fff;
}

// ファイル
// input[type="file"] {
// 	display: none;
// }

// ラジオボタン
input[type="radio"] {
	outline: none;
	position: relative;
	display: inline-block;
	// top: 1px;
	// margin-right: 6px;
	// vertical-align: top;
	// width: 14px;
	// height: 14px;
	// border: 1px solid $mainColor02;
	// -webkit-border-radius: none;
	//         border-radius: none;
	color: #999;
	font-size: 18px;
	&:before {
		display: inline-block;
		position: relative;
		top: 0;
		left: 0;
		content:"\f1db";
		font-family: FontAwesome;
		font-size: inherit;
		background-color: #fff;
		-webkit-border-radius: 50%;
		        border-radius: 50%;
	}
	&:checked{
		&:before {
			display: inline-block;
			position: relative;
			top: 0;
			left: 0;
			content:"\f192";
			font-family: FontAwesome;
			font-size: inherit;
		}
	}
}

// チェックボックス
input[type="checkbox"] {
	outline: none;
	position: relative;
	display: inline-block;
	width: 18px;
	// top: 1px;
	// margin-right: 6px;
	// vertical-align: top;
	// width: 14px;
	// height: 14px;
	// border: 1px solid $mainColor02;
	// -webkit-border-radius: none;
	//         border-radius: none;
	color: #333;
	font-size: 16px;
	&:before {
		display: inline-block;
		position: relative;
		top: 0;
		left: 0;
		content:"\f096";
		font-family: FontAwesome;
		font-size: inherit;
	}
	&:checked{
		&:before {
			display: inline-block;
			position: relative;
			top: 0;
			left: 0;
			content:"\f046";
			font-family: FontAwesome;
			font-size: inherit;
		}
	}
}

// ボタン
button {
	font-family: "Hiragino Sans","ヒラギノ角ゴシック","Hiragino Kaku Gothic Pro","ヒラギノ角ゴ Pro W3","メイリオ","Meiryo","ＭＳ Ｐゴシック", Osaka, sans-serif;
}

// テキストエリア
textarea {
	resize: vertical;
	height: 135px;
}

// placeholderカラー処理
:placeholder-shown ,
::-webkit-input-placeholder {
	color: #bfbfbf;
}

// フォーカス時の処理
input:focus:-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
// textarea:focus, input:focus {
// 	outline: 0;
// }

// inputタグ サイズ指定
.input ,
.select {
	&-min {
		width: 65px;
	}
	&-mid {
		width: 300px;
	}
	&-long {
		width: 500px;
	}
	&-max {
		width: 100%;
	}
	// inputタグ 名前・カナ
	&-name ,
	&-name2 {
		width: 300px;
	}
	// inputタグ 郵便番号
	&-postcode {
		width: 200px;
	}
	&-postcode1 {
		width: 80px;
	}
	&-postcode2 {
		width: 90px;
	}
	// inputタグ 電話番号
	&-tel {
		width: 163px;
	}
	&-tel1 {
		width: 60px;
	}
	&-tel2 {
		width: 70px;
	}
	// selectタグ 生年月日
	&-birthYear ,
	&-birthMonth ,
	&-birthDay {
		width: 110px;
	}
	&-birthMonth ,
	&-birthDay {
		margin-left: 10px;
	}
	// textareaタグ お問い合わせ内容・SNS記載
	&-comment {
		width: 500px;
		min-height: 150px;
	}
	&-SpWrap {
		margin-left: 20px;
		&:first-child {
			margin-left: 0;
		}
	}
}
label.selector {
}
@media screen and (max-width: $breakpoint-sp) {
	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	select  {
		padding: 10px;
	}
	input[type="radio"] {
		font-size: 18px;
	}
	input[type="checkbox"] {
		font-size: 16px;
	}
	.input ,
	.select {
		&-min {
			width: 65px;
		}
		&-mid ,
		&-mid02 ,
		&-long {
			width: 100%;
		}
		&-name ,
		&-name2 {
			width: 100%;
		}
		&-postcode1 {
			width: 70px;
		}
		&-postcode2 {
			width: 80px;
		}
		&-birthYear ,
		&-birthMonth ,
		&-birthDay {
			width:70%;
		}
		&-birthMonth ,
		&-birthDay {
			margin-left: 2.6em;
		}
		&-comment {
			width: 100%;
			min-height: 100px;
		}
		&-SpWrap {
			margin: 15px 0;
			display: block;
			&:first-child {
				margin-top: 0;
			}
		}
	}
}



/* ==================================================================================== */
/*
	@breakpoint check !!!!!!!!!!!!!!
*/
/* ==================================================================================== */

// @media screen and (min-width: $breakpoint-sp + 1) {
// 	body {
// 		background: url('https://data.shift-jp.net/nissin-chem/design/998_%E3%83%A1%E3%83%B3%E3%83%86%E3%83%8A%E3%83%B3%E3%82%B9.jpg?0614103402') top 0 center no-repeat;
// 		 // background: url("#{$scssImgPath}plan03.jpg") top -0 center no-repeat;
// 		 // -webkit-background-size: 100% auto;
// 		// 	  -o-background-size: 100% auto;
// 				 // background-size: 100% auto;
// 		* {
// 			 background: none !important;
// 			 background-image: none !important;
// 			 background-color: transparent !important;
// 			 // background-color: rgba(0,0,0, .1) !important;
// 		}

// 		img {
// 			opacity: .5;
// 			box-shadow: 0 0 0 1px red;
// 		}

// 		.btn {
// 			border: 1px solid red;
// 		}
// 	}
// }
// figure {
// 	background-color: red !important;
// 	box-shadow: inset 0 0 0 1px black !important;
// }
// img {
// 	opacity: 0.5 !important;
// }
// .grid ,
// .badge ,
// .pageHeadLine4 {
// 	box-shadow: inset 0 0 0 1px black !important;
// }

// .pageHeadLine1_title span {
// 	color: #f0c !important;
// }

// // a[href="javascript:alert('リンク先不明');"] {
// // 	background-color: #f0c !important;
// // }


