@charset "UTF-8";
@import '_config.scss';

/*
editor関連エリアCSS

editor関連

Styleguide 6.
*/

/*
editor関連

.edit

Markup:
<section class="section edit">
	<div class="editBody">
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
		<hr>
		<blockquote class="blockquote">
		引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト引用テキスト
		</blockquote>
		<p>リスト</p>
		<ul class="list list-disc">
			<li>テキストテキストテキストテキストテキストテキストテキストテキスト</li>
			<li>テキストテキストテキストテキストテキストテキスト</li>
			<li>テキストテキストテキストテキスト</li>
			<li>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</li>
		</ul><!-- /.list -->
		<p>番号つきリスト</p>
		<ol class="list">
			<li>テキストテキストテキストテキストテキストテキストテキストテキスト</li>
			<li>テキストテキストテキストテキストテキストテキスト</li>
			<li>テキストテキストテキストテキスト</li>
			<li>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</li>
		</ol>
	</div><!-- /.editBody -->
</section><!-- /.edit -->

Styleguide 6.1.1
============================================================*/
.edit {
	margin: 0;
	padding: 10px;
	background-color: #fff;

	> p:not([class]) ,
	> dl:not([class]) ,
	> ul:not([class]) ,
	> ol:not([class]) {
		&:first-child {
			margin-top: 0;
		}
	}
	ol:not([class]) ,
	ul:not([class]) {
		margin-left: 2em;
	}
	hr {
		margin: 20px 0;
	}
	// blockquote ,
	// .blockquote {
	//	margin: 20px;
	//	position: relative;
	//	display: block;
	//	padding: 35px 82px 30px;
	//	@include fts(16);
	//	background-color: $mainColor02;
	//	&:before ,
	//	&:after {
	//		display: inline-block;
	//		position: absolute;
	//		content: "";
	//		width: 55px;
	//		height: 46px;
	//	}
	//	&:before {
	//		top: 8px;
	//		left: 14px;
	//		background: url("./../images/bullets/icon_blockquote-top.png") no-repeat;
	//		-webkit-background-size: cover;
	//		background-size: cover;
	//	}
	//	&:after {
	//		bottom: 8px;
	//		right: 14px;
	//		background: url("./../images/bullets/icon_blockquote-bottom.png") no-repeat;
	//		-webkit-background-size: cover;
	//		background-size: cover;
	//	}
	// }
	&Body {
		padding: 10px 10px 20px;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.edit {
		padding: 0;
		hr {
			margin: 10px 0;
		}
		// blockquote ,
		// .blockquote {
		// 	margin: 20px 0 0;
		// 	padding: 35px 20px 30px;
		// 	@include fts(14);
		// 	&:before ,
		// 	&:after {
		// 		width: 30px;
		// 		height: 25px;
		// 	}
		// 	&:before {
		// 		top: 8px;
		// 		left: 8px;
		// 	}
		// 	&:after {
		// 		bottom: 8px;
		// 		right: 8px;
		// 	}
		// }
		&Body {
			padding: 10px 0 20px;
		}
	}
}

// editor用 : 見出し1　はない。

/*
editor用 : 見出し2

editorHeadLine2

Markup:
<h2 class="editorHeadLine2">見出し2</h2>

Styleguide 6.2.2
============================================================*/
.editorHeadLine2 {
	display: block;
	margin: 0 0 27px;
	padding: 8px 0 6px 19px;
	font-weight: normal;
	border-left: 4px solid $mainColor03;
	font-size: 26px;
	color: #323232;
	line-height: 1.2;
}
@media screen and (max-width: $breakpoint-sp) {
	.editorHeadLine2 {
		padding: 6px 0 4px 9px;
		font-size: 20px;
	}
}

/*
editor用 : 見出し3

editorHeadLine3

Markup:
<h3 class="editorHeadLine3">見出し3</h3>

Styleguide 6.2.3
============================================================*/
.editorHeadLine3 {
	display: block;
	margin: 0 0 27px;
	padding: 7px 15px 5px;
	line-height: 1.2;
	color: #fff;
	font-size: 16px;
	font-weight: normal;
	background-color: $mainColor04;
}
@media screen and (max-width: $breakpoint-sp) {
	.editorHeadLine3 {
		padding: 7px 10px 5px;
	}
}

/*
editor用 : 見出し4

editorHeadLine4

Markup:
<h4 class="editorHeadLine4 {$modifiers}">見出し4</h4>

.editorHeadLine4-bld - 太字

Styleguide 6.2.4
============================================================*/
.editorHeadLine4 {
	display: block;
	margin: 0 0 22px;
	padding: 3px 3px 6px;
	font-size: 18px;
	line-height: 1.4;
	color: #333;
	font-weight: normal;
	border-bottom: 1px dotted $mainColor09;
	&-bld {
		font-weight: bold;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.editorHeadLine4 {
		font-size: 16px;
	}
}

/*
editor用 : 見出し5

editorHeadLine5

Markup:
<h5 class="editorHeadLine5">見出し5</h5>

Styleguide 6.2.5
============================================================*/
.editorHeadLine5 {
	position: relative;
	display: block;
	margin: 0 0 22px;
	padding: 0 10px 0 20px;
	color: #333;
	padding-bottom: 3px;
	font-size: 14px;
	font-weight: bold;
	border-bottom: 1px dotted $mainColor09;
	&:before {
		display: inline-block;
		position: absolute;
		top: 0px;
		left: 2px;
		content: "\f138";
		font-family: FontAwesome;
		color: $mainColor04;
		font-size: inherit;
	}
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
リード文

editorLead

Markup:
<p class="editorLead">テキストテキストテキストテキストテキストテキスト</p>

Styleguide 6.3
============================================================*/
.editorLead {
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
定義リスト

editorDl

Markup:
<dl class="editorDl">
	<dt>項目項目項目項目項目項目項目項目 <br>項目項目項目項目項目項目項目項目</dt>
	<dd>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</dd>
</dl>

Styleguide 6.3
============================================================*/
.editorDl {
	> dt {
		position: relative;
		font-weight: bold;
		padding-left: 1em;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			width: 5px;
			height: 100%;
			background-color: $mainColor04;
		}
	}
	> dd {
		margin-top: 5px;
		padding-left: 2em;
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.editorDl {
		> dd {
			padding-left: 1em;
		}
	}
}

