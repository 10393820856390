@import '_config';

@import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';

// @import '_plugins/_jquery.bxslider';
// @import '_plugins/_colorbox-type1';
@import '_plugins/_animate.min.scss';
@import '_plugins/_colorbox-type2';
@import '_plugins/_slider-pro.min';
@import '_plugins/_lg-transitions';
@import '_plugins/_lightgallery';
@import '_plugins/_remodal.scss';
@import '_plugins/_remodal-default-theme.scss';

@import '_default';
@import '_animation';
@import '_shared';
@import '_global';
@import '_module';
@import '_parts';
@import '_editor';
@import '_page';

@import '_js-modal-theme';
@import '_js-custom';