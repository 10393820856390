@charset "UTF-8";
@import '_config.scss';
/*
ページ用モジュール

特定ページ用のモジュール

Styleguide 5.
*/

/*
製品情報ページ

product_content

Markup:
{$modifiers}

Styleguide 5.1.1
============================================================*/
.product_content {
	// background-color: #ddd;
	.notesList {
		li {
			margin: 0 15px;
			display: inline-block;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.product_content {
		img {
			max-width: 100%;
			height: auto;
		}
	}
}

/*
製品情報

pageID-products

Markup:
{$modifiers}

Styleguide 5.1.1
============================================================*/
.pageID {
	&-products {
	}
}
@media screen and (max-width: $breakpoint-sp) {
}

/*
会社案内

pageID-company

Markup:
{$modifiers}

Styleguide 5.2.1
============================================================*/
.pageID {
	&-companyCorporate {
		.headLine3 {
			font-weight: bold;
		}
		.sign {
			font-size: 13px;
			strong {
				font-size: 17px;
				font-weight: normal;
			}
		}
	}
	&-companyCsr {
		.boxList {
			&_title {
				margin-bottom: 0;
				padding-bottom: 0;
				font-size: 14px;
				font-weight: normal;
				border-bottom: none;
			}
			&_txt {
				margin-top: 5px;
			}
		}
	}
}
.company {
	&_date {
		text-align: right;
		padding-bottom: 5px;
		margin-bottom: 5px;
		border-bottom: 1px solid $mainColor09;
	}
	&Lead {
		margin: 20px 0;
		padding: 10px 0;
		text-align: center;
		&_title {
			font-size: 30px;
			font-weight: bold;
		}
		&_subTitle {
			display: inline-block;
			margin-top: 5px;
			padding-top: 10px;
			width: 80%;
			font-size: 14px;
			border-top: 2px solid $mainColor04;
			word-wrap: break-word;
			letter-spacing: 0.05em;
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.company {
		&Lead {
			&_title {
				font-size: 20px;
			}
			&_subTitle {
				width: 100%;
				font-size: 14px;
			}
		}
	}
}

/*
サポート

pageID-support

Markup:
{$modifiers}

Styleguide 5.3.1
============================================================*/
.pageID {
	&-supportSdsDl {
		.searchBox {
			select {
				width: 23em;
			}
			input {
				width: 22em;
			}
			.btnGroup {
				margin-top: 27px;
				.btn {
					padding: 13px 30px;
					width: 314px;
				}
			}
		}
	}
	&-supportCatalogDl {
		.section-borderTop {
			margin-top: 20px;
		}
		.boxList {
			&_title {
				margin-bottom: 0;
				span {
					padding: 0;
					&:before {
						display: none;
					}
				}
			}
			&_txt {
				margin-top: 15px;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.pageID {
		&-supportSdsDl {
			.searchBox {
				select ,
				input {
					width: 100%;
				}
				.btnGroup {
					margin-top: 27px;
					.btn {
						padding: 13px 30px;
						width: 100%;
					}
				}
			}
		}
	}
}

/*
お問い合わせ

pageID-form

Markup:
{$modifiers}

Styleguide 5.5.1
============================================================*/
.pageID {
	&-form {
		.section {
			margin-top: 72px;
			&:first-child {
				margin-top: 0;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.pageID {
		&-form {
			.section {
				margin-top: 42px;
			}
		}
	}
}